//
// Panels
// --------------------------------------------------
// Base class
.panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 30px;
    margin-top: 30px;
    //    padding-top: 15px;
  }
}

.panel-content {
  margin-bottom: 35px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 45px;
  }
}

.panel-group {
  .panel {
  padding-bottom: 0 !important;

    +.panel {
      margin-bottom: 0 !important;
    }
  }
}

// General content panel bottom margin
.gen-content {
  margin-bottom: 35px;
  margin-top: 45px;

  &:last-child {
    margin-bottom: 45px;
  }

  &__panel {
    margin-top: 25px;
    padding-bottom: 15px;

    @media (min-width: $screen-sm-min) {
      margin-top: 15px;
      padding-bottom: 15px;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 35px;
      padding-bottom: 30px;
    }

    @media (min-width: $screen-lg-min) {
      margin-top: 30px;
      //    padding-bottom: 45px;
    }
  }

  &--booking-table {
    margin-bottom: 45px;
  }

  .section-header {
    margin-top: 10px;

    @media (min-width: $screen-sm-min) {
      margin-top: 20px;
    }
  }
}

// Optional heading
.panel-heading {
  border-radius: 0;
}

// Use as a containing or parent div
.panel-colour {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
  margin-top: 30px;

  &--primary {
    background-color: $brand-primary;
  }

  &--white {
    background-color: $white;
  }
}

// Used together with panel-wide where panel has a background-color
// Panel break
.panel-wide {
  @include clearfix();
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &:last-child {
    margin-top: 0;
  }

  @media (min-width: $screen-sm-min) {
    padding-bottom: 35px;
  }

  @media (min-width: $screen-md-min) {
    margin-top: 30px;
    padding-top: 30px;
  }

  &__clearing {
    @include font-karla-regular();
    @extend .panel-wide;
    text-align: center;
    color: $white;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 0;
    background-color: $uow-magenta;
    position: fixed;
    top: 0;
    z-index: 1001;
    height: 30px;

    a {
      @include font-karla-bold();
      color: $white;
      border-bottom: 1px solid $white;

      &:hover,
      &:focus {
        color: $brand-secondary;
        border-bottom: none;
      }
    }
  }

  &__bg {
    &--white {
      background-color: $white;

      &-clearing {
        @extend .panel-wide__clearing;
        background-color: $white;
        color: $white;
      }
    }

    &--grey-light {
      background-color: $grey-light;
    }

    &--grey-lighter {
      background-color: $grey-lighter;
    }

    &--primary {
      background-color: $brand-primary;
      color: $white;

      a {
        color: $white;
        border-bottom: 1px solid $white;

        &:hover,
        &:focus,
        &:active {
          border-bottom: none;
        }
      }

      a.btn {
        border-bottom: none;
      }
    }

    &--primary-light {
      background-color: $brand-primary-light;

      a {

        &:hover,
        &:focus,
        &:active {
          color: $grey-dark;
          border-bottom: none;
        }
      }
    }

    &--secondary {
      background-color: $brand-secondary;

      a {

        &:hover,
        &:focus,
        &:active {
          color: $grey-dark;
          border-bottom: none;
        }
      }
    }

    &--secondary-dark {
      background-color: $brand-secondary-dark;
      color: $white;

      a {
        color: $grey-dark;
        border-bottom: 1px solid $white;

        &:hover,
        &:focus,
        &:active {
          border-bottom: none;
        }
      }
    }

    &--magenta {
      background-color: $uow-magenta-masthead;
      color: $white;

      a {
        color: $white;
        border-bottom: 1px solid $white;

        &:hover,
        &:focus,
        &:active {
          border-bottom: none;
        }
      }

      a.btn-primary {
        border-bottom: none;
      }

      a.btn-neutral,
      a.btn-secondary {
        color: $grey-dark !important;
        border-bottom: none;

        &:hover,
        &:focus,
        &:active {
          border-bottom: none;
        }
      }

      a.btn-secondary {

        &:hover,
        &:focus,
        &:active {
          color: $brand-secondary !important;
        }
      }
    }

    &--polygon {
      background: url('../images/polygon-bg.png');
    }
  }

  .section-header {

    //    margin-top: 15px !important;
    @media (min-width: $screen-md-min) {
      margin-top: 30px !important;
    }
  }

  .gen-content__panel {
    margin-top: 0;
    padding-bottom: 45px;

    @media (min-width: $screen-md-min) {
      margin-bottom: 10px;
    }

    @media (min-width: $screen-lg-min) {
      margin-bottom: 20px;
    }
  }

  .paragraph--type--content-row {
    @media (min-width: $screen-md-min) {
      padding-top: 15px;
    }
  }

  //Where h2 is within a content column
  h2 {
    margin-top: 15px;
  }

  img {
    padding-bottom: 30px;

    @media (min-width: $screen-md-min) {
      padding-bottom: 30px;
    }
  }
}

// Your westminster
.panel-your-westminster {
  background-color: $panel-your-westminster-bg;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  position: relative;
  margin-bottom: 30px !important;

  @media (min-width: $screen-sm-min) {
    padding-right: 170px;
  }

  &__header {
    @extend .pull-left;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }

    h2 {
      @extend .text-uppercase;
      color: $panel-your-westminster-title-color;
      margin-top: 0;

      @media (min-width: $screen-sm-min) {
        margin-bottom: 0;
      }
    }

    p {
      color: $white;

      @media (min-width: $screen-sm-min) {
        margin-bottom: 0;
      }
    }
  }

  &__btn {
    @extend .pull-right;

    @media (min-width: $screen-sm-min) {
      @include transform(translateY(-50%));
      float: none;
      position: absolute;
      right: 30px;
      top: 50%;
    }
  }
}

// Enquiries
.panel-enquiries {
  @extend .text-center;
  background-color: $panel-enquiries-bg;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-bottom: -7px !important;

  @media (min-width: $screen-sm-min) {
    margin-bottom: -6px !important;
  }

  h2,
  h3,
  h4 {
    @extend .text-uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  a {
    word-wrap: break-word;
  }
}

// Seperator
.panel-seperator {
  height: 1px;
  position: relative;
  width: 100%;

  &:before {
    background-color: $panel-seperator-color;
    content: "";
    display: inline-block;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  &--dark {
    &:before {
      background-color: $panel-seperator-dark-color;
    }
  }

  &--light {
    &:before {
      background-color: $panel-seperator-light-color;
    }
  }
}

// Full width seperator with background image or background colour
.panel-seperator-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
  padding-top: 40px;

  @media (min-width: $screen-sm-min) {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  &--white {
    background-color: $panel-seperator-white-bg;
  }

  &--light {
    background-color: $panel-seperator-light-bg;
  }
}

// Statistic
.panel-statistic {
  &__wrapper {
    background-color: $panel-statistics-bg;
    border-radius: 50%;
    margin-left: 5%;
    padding-top: 90%;
    position: relative;
    width: 90%;

    @media (min-width: $screen-sm-min) {
      margin-left: 0;
      padding-top: 100%;
      width: 100%;
    }

    @media (min-width: $screen-lg-min) {
      margin-left: 5%;
      padding-top: 90%;
      width: 90%;
    }
  }

  &__stat {
    @extend .text-center;
    @extend .vertical-center;
    left: 15%;
    position: absolute;
    width: 70%;

    &-value {
      @include font-futura-bold;
      @include font-size(7);
      display: inline-block;
      width: 100%;

      @media (min-width: $screen-sm-min) {
        @include font-size(4);
      }

      @media (min-width: $screen-md-min) {
        @include font-size(7);
      }
    }

    &-description {
      @include font-karla-bold;
      @include font-size(1.4);
      @extend .text-uppercase;
      display: inline-block;
      line-height: 110%;
      width: 100%;

      @media (min-width: $screen-sm-min) {
        @include font-size(1.2);
      }

      @media (min-width: $screen-md-min) {
        @include font-size(1.6);
      }
    }
  }

  &--inverted {
    .panel-statistic__wrapper {
      background-color: $panel-statistics-inverted-bg;
      color: $panel-statistics-inverted-color;
    }
  }

  &--secondary {
    .panel-statistic__wrapper {
      background-color: $panel-statistics-secondary-bg;
    }
  }

  &--secondary-inverted {
    .panel-statistic__wrapper {
      background-color: $panel-statistics-secondary-inverted-bg;
      color: $panel-statistics-secondary-inverted-color;
    }
  }
}

// Further reading
.panel-further-reading {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;

  //    padding-left: 15px;
  //    padding-right: 15px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 20px;
    margin-top: 20px;
    //        padding: 0 30px;
  }

  .btn {
    width: 100%;
  }
}

// Google map
.panel-googlemap {

  // margin-bottom: 60px;
  &__map {
    height: 450px;
    width: 100%;
  }

  &__info {
    text-align: center;
    margin-top: 15px;

    span {
      @include font-size(2);
    }
  }
}

// Faceted search (uses accordion too)
.panel-faceted-search {
  margin-top: 30px !important;

  @media (min-width: $screen-sm-min) {
    margin-top: 60px !important;
  }

  &__options {
    display: inline-block;
    width: 100%;
  }

  &__option {
    display: inline-block;
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 30px;
    }

    &-text {
      @include font-size(1.3);
      @extend .pull-left;
      width: 85%;

      @media (min-width: $screen-md-min) {
        @include font-size(1.6);
      }
    }

    &-input {
      @extend .pull-right;

      @media (min-width: $screen-md-min) {
        position: relative;
        top: 3px;
      }
    }
  }
}

// A-Z Filter
.panel-atoz-filter {
  @extend .text-center;
  background-color: $panel-atoz-filter-bg;
  margin-top: 0;
  padding: 15px;
  z-index: 100;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }

  &__items {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: 80%;
    }

    @media (min-width: $screen-md-min) {
      width: 100%;
    }
  }

  &__item {
    display: inline-block;
    margin: 5px 2px;

    &:before {
      display: none;
    }
  }

  &__link {
    @include font-size(1.4);
    @extend .text-center;
    border-bottom: none;
    border-radius: 50%;
    color: $panel-atoz-filter-link-color;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-decoration: none;
    width: 24px;

    @media (min-width: $screen-sm-min) {
      @include font-size(1.6);
      height: 26px;
      line-height: 26px;
      width: 26px;
    }

    &:hover,
    &:focus,
    &--active {
      background-color: $panel-atoz-filter-link-bg;
      color: $panel-atoz-filter-link-color;
    }
  }
}

// Dropdown filter
.panel-dropdown-filter {
  @extend .text-center;
  background-color: $panel-dropdown-filter-bg;
  margin-top: 0;
  padding: 15px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }

  @media (min-width: $screen-md-min) {
    margin-top: 0;
  }

  &__item {
    padding-bottom: 20px;

    &:last-of-type {
      padding-bottom: 0;
      padding-right: 0;
    }

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      padding-bottom: 0;
      padding-right: 30px;
    }

    @media (min-width: $screen-md-min) {
      padding-right: 20px;
    }

    @media (min-width: $screen-lg-min) {
      padding-right: 40px;
    }
  }

  &__label {
    @include font-karla-bold;
    @include font-size(1.4);
    @extend .text-uppercase;
    display: block;

    @media (min-width: $screen-sm-min) {
      text-align: left;
      // @include text-left;
    }

    @media (min-width: $screen-md-min) {
      text-align: center;
      //@include text-center;
      display: inline-block;
      margin-right: 10px;
      padding-top: 7px;
    }
  }
}

// Unistats
.panel-unistats {
  @extend .text-center;
  width: 100%;
  margin-bottom: 30px !important;

  &__horz {
    @extend .hidden-xs;
  }

  &__vert {
    @media (min-width: $screen-xs-max) {
      display: none;
    }
  }
}

// Reason
.panel-reason {
  @extend .text-center;
  margin-bottom: 10px !important;

  @media (min-width: $screen-md-min) {
    margin-bottom: 25px !important;
  }

  &__heading {
    margin-top: 15px;
    @include font-karla-bold();
    @include font-size(2.1);
    line-height: 120%;

    @media (min-width: $screen-sm-min) {
      @include font-size(2.1);
    }

    @media (min-width: $screen-lg-min) {
      @include font-size(2.6);
    }
  }

  &__icon {
    // @include font-size(8);
    color: $panel-reason-number-color;
    display: inline-block;
    line-height: 100%;
    vertical-align: middle;
    min-height: 150px;

    img {
      display: inline-block;
    }
  }

  &__number {
    @include font-size(8);
    color: $panel-reason-number-color;
    display: inline-block;
    line-height: 100%;
    vertical-align: bottom;

    @media (min-width: $screen-sm-min) {
      @include font-size(7);
    }

    @media (min-width: $screen-md-min) {
      @include font-size(9);
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  &__logo {
    color: $panel-reason-number-color;
    display: inline-block;
    line-height: 100%;
    vertical-align: bottom;
    padding-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      @include font-size(7);
      min-height: 60px;
    }

    @media (min-width: $screen-md-min) {
      @include font-size(9);
      min-height: 120px;
    }

    img {
      display: inline-block;
    }
  }

  &__step-number {
    @include font-size(2);
    @include font-karla-bold();
    @extend .ellipse,
    .ellipse__colour--secondary;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    padding: 5px;
  }
}

a.panel-reason {
  &__heading {
    color: $brand-primary;
  }
}

//For Accommodation landing page
.panel-accommodation {
  margin-top: 20px;
  margin-bottom: 60px;
  @include make-xs-column(12);
  @include make-sm-column(6);
  //  @include make-md-column(6);
  //  @include make-lg-column(4);
  position: relative;

  //  @media (min-width: $screen-lg-min) {
  //    //  //Offset every other row - needs revisiting if text changes are made
  //      &:nth-of-type(4) {
  //      //    @include make-md-column-offset(2);
  //      //    float: left;
  //          margin-left: -16%;
  //      }
  //  }
  &__details {
    margin-top: 10px;

    .btn {
      display: block;
      padding-left: 0;
      color: $grey-dark;
      border-bottom: none;
      float: right;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}

// Colour block
.colour-block {
  text-align: center;
  padding: 30px 20px 30px 20px;
  margin-top: 30px;

  h3 {
    font-size: 4rem;
  }

  p {
    font-size: 1.4rem;
  }

  a {
    @include font-karla-bold();
    border-bottom: solid 1px;

    &:hover,
    &:focus {
      border-bottom: none;
    }
  }

  &__turquoise {
    background-color: $brand-primary;
    color: $white;

    a {
      color: $white;
    }
  }

  &__yellow {
    background-color: $brand-secondary;
    color: $grey-dark;

    a {
      color: $grey-dark
    }
  }
}

// Icon feature on  Current students homepage
.icon-feature-panel {
  @include clearfix();
  margin-bottom: 30px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 60px;
  }
}

.panel-features {
  @extend .no-gutter;
  @include clearfix();
  background-color: $brand-secondary;
  color: $grey-dark;
  text-align: center;
  width: 100%;

  @media (max-width: $screen-sm-max) {
    margin-top: 0px;
  }

  @media (min-width: $screen-sm-min) {
    padding-top: 15px;
  }

  .svg {
    padding-top: 10px;
    vertical-align: middle;
    filter: invert(15%) sepia(1) saturate(800) hue-rotate(90deg) brightness(8%) contrast(75%);
    height: 100px;
    width: auto;

    @media (max-width: $screen-xxs-max) {
      padding-top: 15px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 5px;
      height: 100px;
    }

    @media (min-width: $screen-sm-min) {
      height: 60px;
    }

    @media (min-width: $screen-md-min) {
      height: 80px;
    }
  }

  .feature-title {
    @include font-karla-bold();
    @include font-size(1.6);
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 15px;
    margin-bottom: 10px;

    @media (max-width: $screen-xxs-max) {
      margin-top: 0px;
      padding: 0;
    }

    @media (min-width: $screen-md-min) {
      @include font-size(1.8);
    }
  }
}

a.panel-features {

  &:hover,
  &:active,
  &:focus {
    background-color: $grey-dark;
    color: $brand-secondary;

    //  @include transition(background-color 0.7s, color 0.7s);
    .svg {
      filter: invert(100%) sepia(2) saturate(9999%) brightness(90%) hue-rotate(-10deg);
    }
  }
}

.user-logged-in {
  .panel-dropdown {
    margin-top: -120px;
  }
}

// Dropdown panel with button
.panel-dropdown {
  // text-align: center;

  .btn-cs-show,
  .btn-secondary-show {
    @include font-size(1.6);
    width: 100%;

    @media (min-width: $screen-xs-min) {
      @include font-size(2);
      width: auto;
    }
  }

  &__nav-show {
    text-align: left !important;

    .btn-nav-show {
      @include font-size(1.6);
      text-transform: none;
      text-align: left;

      &--news {
        width: 100% !important;

        // @media (min-width: $screen-sm-min) {
        //   width: 250px !important;
        // }
      }

      &--research {
        width: 100% !important;

        @media (min-width: $screen-sm-min) {
          width: 300px !important;
        }

        @media (min-width: $screen-md-min) {
          width: 450px !important;
        }
      }
    }
  }

  .open {
    .dropdown-menu {
      position: absolute;
    }

    .text {
      @extend .well;
      padding: 15px;
      width: 100%;
      margin-top: 0 !important;
      border-bottom: 6px solid $brand-secondary !important;

      @media (min-width: $screen-xs-min) {
        margin-top: 10px !important;
      }

      &__cs {
        border-bottom: 6px solid $uow-magenta !important;
      }

      &__newslist {
        float: none !important;
        margin-top: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        width: 100%;

        // @media (min-width: $screen-sm-min) {
        //   width: 250px;
        // }

        &--research {
          width: 100%;
          max-height: 645px;
          overflow: auto;

          @media (min-width: $screen-sm-min) {
            width: 300px;
          }

          @media (min-width: $screen-md-min) {
            width: 450px !important;
          }
        }

        .search-filter {
          position: relative;
          margin-left: 0;
          margin-bottom: 10px;

          span {
            @include font-size(1.6);
            position: absolute;
            top: 5px;
            left: 0;
          }

          input {
            @include form-control-focus;
            @include font-size(1.6);
            background-color: transparent;
            padding-left: 45px;
            padding-bottom: 20px;
            border: none;
            border-bottom: 1px solid $grey-dark;
            box-shadow: none;
            margin-top: 5px;
            position: relative;
            top: 1px;
            -webkit-appearance: none;
            width: 95%;
          }
        }

        ul.nav {
          padding-left: 0;
          line-height: 150%;
          margin-top: 0;

          li {
            &:before {
              border-width: 0;
            }

            a {
              text-decoration: none;
              border-bottom: none;
              padding: 5px 0 5px 0;

              &:hover {
                background-color: $brand-secondary;
                color: $grey-dark;
                margin-left: -30px;
                margin-right: -30px;
                padding-left: 30px;
                padding-right: 30px;
              }
            }
          }

          .nav a.is-active {
            color: $brand-primary !important;
          }
        }
      }
    }
  }
}

//LIBRARY TIMES
.library-opening-hours {
  .content {
    margin-top: 10px;
  }
}

.panel-library-times {
  background-color: $white;
  margin-top: -2px;
  padding-top: 0;

  &__item {
    @extend .col-xs-6,
    .col-md-3;
    @extend .text-center;
    @include transition(background-color 0.7s,
      color 0.7s);

    &:last-child {
      border-right: 2px solid $grey-light;

      @media (min-width: $screen-sm-min) {
        border-right: none;
      }
    }

    h3 {
      text-transform: uppercase;
    }

    p {
      margin-bottom: 5px;
      white-space: normal;

      @media (min-width: $screen-sm-min) {
        margin-bottom: 20px;
      }
    }

    &:hover,
    &:focus,
    &.focus {
      background-color: $brand-secondary;

      a {
        color: $brand-primary-dark;
      }
    }
  }

  &--pager {
    position: relative;
    margin: 0 auto;
    width: 100%;
    background-color: $white;
    height: 54px;

    ul {
      position: relative;
      padding-top: 12px;
      padding-bottom: 0;
      margin-bottom: 10px;
      margin: 0 0;

      @media (min-width: $screen-xs-min) {
        padding-top: 18px;
      }

      .label-prev {
        @include font-karla-regular();
        @include font-size(1.4);
        position: absolute;
        top: -25px;
        left: 0px;
        float: left;
        border: none;
        color: $grey-dark;
        text-align: left;

        @media (min-width: $screen-xs-min) {
          @include font-size(1.6);
        }
      }

      .label-next {
        @extend .label-prev;
        right: 0;
        float: right;
        text-align: right;

        @media (min-width: $screen-xs-min) {
          @include font-size(1.6);
        }
      }

      li {
        a {
          line-height: 36px;
          border: none;
          width: 100%;
        }
      }
    }

    &-text {
      @include font-karla-regular();
      @include font-size(1.4);
      margin: 0 auto;
      width: 55%;

      @media (min-width: $screen-xs-min) {
        @include font-size(1.4);
        width: 75%;
      }

      @media (min-width: $screen-sm-min) {
        @include font-size(1.6);
      }

      @media (min-width: $screen-lg-min) {
        @include font-size(2);
      }

      span {
        @include font-karla-bold();
      }

      .first-day {
        @include font-karla-regular();
        @extend .hidden-xs;
      }
    }
  }

  &__day {
    @extend .panel-library-times__item;
    @extend .col-xs-12,
    .col-sm-1;
    position: relative;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    border-bottom: 1px solid $grey-light;

    @media (min-width: $screen-sm-min) {
      border-right: 2px solid $grey-light;
    }

    &:hover,
    &:focus,
    &.focus {
      background-color: $white;
    }

    @media (min-width: $screen-sm-min) {
      width: 14.28%;
      border-bottom: none;
      min-height: 120px;
    }

    &:last-child {
      border-right: none;
    }

    h4 {
      margin-top: 5px;
      margin-bottom: 5px;

      @media (min-width: $screen-sm-min) {
        margin-top: 25px;
        margin-bottom: 10px;
      }

      @media (min-width: $screen-lg-min) {
        margin-top: 35px;
      }
    }
  }
}