// Tabs
// -------------------------
// Give the tabs something to sit on
.nav-tabs {
    border-bottom: none;
    margin-top: 30px;

    >li {
        // Reset default styling
        margin-bottom: 0;
        padding-left: 0;
        margin-top: 0;

        &:before {
            display: none;
        }

        // Actual tabs (as links)
        >a {
            @include font-karla-bold();
            @include font-size(1.4);
            @extend .text-uppercase;
            background-color: $nav-tabs-link-bg;
            border: none;
            border-radius: 0;
            color: $nav-tabs-link-color;
            margin-right: 0;
            line-height: 125%;
            padding-bottom: 15px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 15px;
            text-decoration: none;

            @media (min-width: $screen-sm-min) {
                @include font-size(1.3);
            }

            @media (min-width: $screen-md-min) {
                @include font-size(1.6);
            }

            &:hover {
                background-color: $nav-tabs-link-hover-bg;
            }
        }

        // Active state, and its :hover to override normal :hover
        &.active>a {

            &,
            &:hover,
            &:focus {
                border: none;
                color: $nav-tabs-active-link-hover-color;
            }
        }
    }

    // pulling this in mainly for less shorthand
    &.nav-justified {
        @extend .nav-justified;
        @extend .nav-tabs-justified;
    }
}

//Restyled nav-tabs -  Used in Entry-requireemnts tabs styling
.split-nav-tabs {
    li {
        border-bottom: solid 4px $brand-secondary;
        width: 50%;
    }
}

//Restyled nav-tabs for Student Hub homepage
.centred-nav-tabs {
    li {
        &:first-child {
            margin-left: 30%;
        }
    }

    p {
        margin-top: 15px;
    }
}

// Tab content
.tab-content {
    background-color: $nav-tabs-content-bg;
    margin-bottom: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (min-width: $screen-md-min) {
        padding-bottom: 30px;
        padding-top: 30px;
    }
}

// Side navigation
.nav-side {
    @extend .hidden-xs;
    border-right: solid 1px $nav-page-border-color;
    border-top: solid 1px $nav-page-border-color;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    margin-left: -30px;

    &__li {
        // Reset default styling
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-left: 0;
        width: 100%;

        &:before {
            display: none;
        }

        a {
            @include font-karla-bold();
            @include font-size(1.2);
            @extend .text-uppercase;
            border-bottom: none;
            display: inline-block;
            line-height: 110%;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 7px;
            text-decoration: none;
            color: $text-color;

            @media (min-width: $screen-md-min) {
                @include font-size(1.4);
            }

            &:hover,
            &:focus {
                background-color: $nav-page-link-hover-bg;
                color: $nav-page-link-hover-color;
            }
        }

        &.active {
            >a {
                background-color: $nav-page-link-hover-bg;
            }
        }
    }

    // Nested list
    &__nested {
        padding-left: 20px;

        li {
            margin-bottom: 0;
            margin-top: 5px;

            &:first-of-type {
                margin-top: 10px;
            }

            a {
                @include font-karla-regular();
            }
        }
    }
}

// We use JS to dynamically create a dropdown on mobile for the side nav so we need to hide it for all other sizes
.nav-side-mobile {
    @extend .hidden-sm;
    @extend .hidden-md;
    @extend .hidden-lg;
}

// Anchor navigation
.nav-anchor {
    @extend .pull-left;
    height: 34px;
    position: relative;
    width: 100%;
    z-index: 250;

    @media (min-width: $screen-md-min) {
        height: 48px;
    }

    &-centered {
        height: 34px;
        position: relative;
        width: auto;
        z-index: 250;

        @media (min-width: $screen-md-min) {
            height: 48px;
        }
    }

    &__wrapper {
        -webkit-transform: translate3d(0px, 0px, 0px);
        width: 100%;
        text-align: center;
    }

    &__wrapper-centered {
        -webkit-transform: translate3d(0px, 0px, 0px);
        //    width: 100%;
        text-align: center;
    }

    .container {
        background-color: $nav-anchor-bg;
        height: 34px;
        padding-left: 0;
        padding-right: 0;

        @media (min-width: $screen-md-min) {
            height: 48px;
        }
    }

    &__links {
        @extend .hidden-sm;
        @extend .hidden-xs;
        @extend .pull-left;
        display: inline-block;
        // Reset default styling
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }

    &__links-centered {
        @extend .hidden-sm;
        @extend .hidden-xs;
        background-color: $nav-anchor-bg;
        display: inline-block;
        margin: 0;
        padding-right: 40px;
        //    // Reset default styling
        //    margin-bottom: 0;
        //    margin-top: 0;
        //    padding-left: 0;
    }

    &__link-item {
        @extend .pull-left;
        // Reset default styling
        margin-bottom: 0;
        margin-top: 0;

        &:before {
            display: none;
        }

        a {
            @include font-karla-bold();
            @include font-size(1.3);
            @extend .text-uppercase;
            border-bottom: none;
            display: inline-block;
            line-height: 48px;
            padding: 0 15px;
            text-decoration: none;

            //color: $grey-dark;
            @media (min-width: $screen-lg-min) {
                @include font-size(1.4);
                padding: 0 15px;
            }

            &:hover,
            &:focus,
            &.active {
                background-color: $nav-anchor-hover-bg;
                color: $grey-dark;
            }
        }
    }

    &__links-mobile {
        @extend .hidden-md;
        @extend .hidden-lg;
        @extend .pull-left;

        //    width: 55%;
        //    top: -34px;
        //    z-index: 260;
        @media (min-width: $screen-sm-min) {
            width: 80%;
        }

        .selectboxit {
            width: 100%;
        }
    }

    &__ucas {
        @extend .pull-right;
        @extend .text-right;
        display: inline-block;
        position: relative;
        float: right;

        &-link {
            @include font-karla-bold();
            @include font-size(1.2);
            @extend .text-center;
            @extend .text-uppercase;
            background-color: $nav-anchor-ucas-link-bg;
            color: $nav-anchor-ucas-link-color;
            display: inline-block;
            line-height: 34px;
            padding-left: 20px;
            padding-right: 30px;
            position: relative;
            width: 100%;

            @media (min-width: $screen-sm-min) {
                @include font-size(1.3);
            }

            @media (min-width: $screen-md-min) {
                line-height: 48px;
                width: auto;
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(1.4);
                padding-left: 30px;
                padding-right: 40px;
            }

            &:after {
                @include triangle-right($nav-anchor-ucas-link-color, 10px, 5px);
                content: "";
                display: inline-block;
                margin-top: -5px;
                position: absolute;
                right: 15px;
                top: 50%;

                @media (min-width: $screen-md-min) {
                    right: 15px;
                }

                @media (min-width: $screen-lg-min) {
                    right: 20px;
                }
            }

            &:hover,
            &:focus {
                background-color: $nav-anchor-ucas-link-hover-bg;
                color: $nav-anchor-ucas-link-color;
            }
        }

        &-info {
            @extend .text-center;
            @include transition(opacity 0.5s, visibility 0.5s);
            background-color: $nav-anchor-ucas-info-bg;
            bottom: 34px;
            padding-top: 5px;
            position: absolute;
            right: 0;
            width: 100%;

            @media (min-width: $screen-sm-min) {
                background-color: transparent;
                bottom: auto;
                padding-top: 0;
                top: 44px;
            }

            @media (min-width: $screen-md-min) {
                top: 58px;
                width: 75%;
            }

            p {
                @extend .text-uppercase;
                color: $nav-anchor-ucas-info-mobile-text-color;
                line-height: 14px;
                margin-bottom: 5px !important;

                @media (min-width: $screen-sm-min) {
                    color: inherit;
                    line-height: 22px;
                }

                a {
                    border-bottom-color: $nav-anchor-ucas-info-mobile-text-color;

                    &:hover {
                        cursor: pointer;
                    }

                    @media (min-width: $screen-sm-min) {
                        border-bottom-color: inherit;
                        color: inherit;
                    }
                }
            }
        }
    }

    &--fixed {
        .nav-anchor__wrapper {
            top: 0;
            position: fixed;

            @media (min-width: $screen-sm-min) {
                top: 57px;
            }

            @media (min-width: $screen-md-min) {
                top: 73px;
            }

            @media (min-width: $screen-lg-min) {
                top: 83px;
            }
        }

        .container {
            @media (min-width: $screen-md-min) {
                border-top: 1px solid $grey-dark;
                height: 49px
            }
        }

        .nav-anchor__ucas-info {
            opacity: 0;
            visibility: hidden;
        }
    }

    // When top notification banner is enabled
    &--fixed-banner {
        .nav-anchor__wrapper {
            top: 30px;
            position: fixed;

            @media (min-width: $screen-sm-min) {
                top: 87px;
            }

            @media (min-width: $screen-md-min) {
                top: 93px;
            }

            @media (min-width: $screen-lg-min) {
                top: 108px;
            }
        }

        .container {
            @media (min-width: $screen-md-min) {
                border-top: 1px solid $grey-dark;
                height: 49px
            }
        }

        .nav-anchor__ucas-info {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.news-events-nav {
    margin-top: 30px;

    @media (min-width: $screen-sm-min) {
        display: inline-block;
    }
}

.news-events-nav-cs {
    margin-top: 30px;

    @media (min-width: $screen-sm-min) {
        display: inline-block;
        margin-top: 0;
    }
}

.content-nav {
    //  margin-bottom: 45px;
    margin-top: 20px;

    @media (min-width: $screen-sm-min) {
        display: inline-block;
        margin-bottom: 35px;
        margin-right: 20px;
    }

    @media (min-width: $screen-md-min) {
        margin-right: 30px;
        margin-bottom: 60px;
        margin-top: 45px;
    }
}

.nav-footer {
    padding: 10px 0;
    margin-top: 20px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: inline;
        float: left;

        &+& {
            margin-left: 277px;
        }
    }

    @media (min-width: $screen-md-min) {
        display: inline;
        float: left;
        padding: 40px 0;
        margin-top: 0;

        &+& {
            margin-left: 15px;
        }
    }

    @media (min-width: $screen-lg-min) {
        &+& {
            margin-left: 60px;
        }
    }
}