//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn {
	@include font-karla-bold();
	@include font-size(1.4);
	@extend .text-uppercase;
	text-align: left;
	border: none;
	border-radius: 0;
	font-weight: normal;
	line-height: 125%;
	max-width: 100%;
	padding-bottom: 15px;
	padding-left: 15px;
	padding-right: 30px;
	padding-top: 15px;
	position: relative;
	text-decoration: none;
	white-space: normal;
	margin-bottom: 10px;

	@media (min-width: $screen-sm-min) {
		@include font-size(1.5);
		padding-bottom: 15px;
		padding-left: 15px;
		padding-right: 30px;
		padding-top: 15px;
	}

	@media (min-width: $screen-md-min) {
		@include transition(background-color 0.7s, color 0.7s);
		@include font-size(1.6);
		margin-bottom: 0px;
	}
}

//Button link - plain text used in Related Content panels
.btn-link {
	color: $grey-dark;
	padding-left: 0px;
	position: relative;
	padding-bottom: 0;

	@media (min-width: $screen-md-min) {
		@include transition(background-color 0s, color 0s);
	}

	&:after {
		@include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 10px, 62%);
		//Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
	}

	&:hover {
		text-decoration: none;

		&:after {
			@include after-arrow($btn-primary-bg, 9px, 9px, 3px, rotate(45deg), 10px, 62%);
		}

		//Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
	}
}

// Primary button
.btn-primary {
	@include box-shadow(3px 3px 0px 0px $btn-primary-shadow-color);
	background-color: $btn-primary-bg;
	color: $btn-primary-color;

	&:after {
		@include after-arrow($white, 9px, 9px, 3px, rotate(45deg), 10px, 42%);

		//Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
		@media (min-width: $screen-sm-min) {
			@include after-arrow($white, 9px, 9px, 3px, rotate(45deg), 10px, 42%);
			//Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
		}
	}

	// Hover
	&:hover,
	&:focus,
	&.focus {
		@include box-shadow(3px 3px 0px 0px $brand-primary);
		background-color: $brand-primary-dark;
		color: $btn-primary-color;

		&:after {
			border-left-color: $brand-primary;
		}
	}

	// Active
	&:active,
	&.active {
		@include box-shadow(3px 3px 0px 0px $btn-primary-active-shadow-color);
		background-color: $btn-primary-active-bg !important;

		&:after {
			border-left-color: $btn-primary-active-color;
		}
	}

	// Disabled
	&.disabled {
		@include box-shadow(3px 3px 0px 0px $btn-primary-disabled-shadow-color);
		background-color: $btn-primary-disabled-bg;
		color: $btn-primary-disabled-color;

		&:after {
			border-left-color: $btn-primary-disabled-color;
		}
	}
}

// Primary button magenta (students)
.btn-primary-magenta {
	@include box-shadow(3px 3px 0px 0px $uow-magenta-dark);
    background-color: $uow-magenta;
    color: $btn-primary-color;
    &:after {
        @include after-arrow($white, 9px, 9px, 2px, rotate(45deg), 10px, 42%);
        //Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
        @media (min-width: $screen-sm-min) {
            @include after-arrow($white, 9px, 9px, 3px, rotate(45deg), 10px, 42%);
            //Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
        }
    }
    // Hover
    &:hover,
    &:focus,
    &.focus {
        @include box-shadow(3px 3px 0px 0px $uow-magenta);
        background-color: $grey-dark;
        color: $white;
        &:after {
            border-left-color: $uow-magenta-masthead;
        }
    }

	// Active
	&:active,
	&.active {
		@include box-shadow(3px 3px 0px 0px $uow-magenta);
		background-color: $uow-magenta-light !important;

		//	color: $uow-magenta !important;
		&:after {
			border-left-color: $uow-magenta;
		}
	}

	// Disabled
	&.disabled {
		@include box-shadow(3px 3px 0px 0px $btn-primary-disabled-shadow-color);
		background-color: $btn-primary-disabled-bg;
		color: $btn-primary-disabled-color;

		&:after {
			border-left-color: $btn-primary-disabled-color;
		}
	}
}

// Secondary button
.btn-secondary {
	@include box-shadow(3px 3px 0px 0px $btn-secondary-shadow-color);
	background-color: $btn-secondary-bg;
	color: $btn-secondary-color !important;

	&:after {
		@include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 10px, 42%);

		//Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
		@media (min-width: $screen-sm-min) {
			@include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 10px, 42%);
			//Arguments - $color, $height, $width, $border-width, $rotation, $left, $top
		}
	}

	// Hover
	&:hover,
	&:focus,
	&.focus {
		@include box-shadow(3px 3px 0px 0px $btn-secondary-hover-shadow-color);
		background-color: $grey-dark;
		color: $brand-secondary !important;

		&:after {
			border-color: $brand-secondary;
		}
	}

	// Active
	&:active,
	&.active {
		@include box-shadow(3px 3px 0px 0px $grey);
		background-color: $grey-dark;
		color: $brand-secondary;

		&:after {
			border-color: $brand-secondary;
		}
	}

	// Disabled
	&.disabled {
		@include box-shadow(3px 3px 0px 0px $btn-secondary-disabled-shadow-color);
		background-color: $btn-secondary-disabled-bg;
		color: $btn-secondary-disabled-color;

		&:after {
			border-left-color: $btn-secondary-disabled-color;
		}
	}
}

// Neutral button
.btn-neutral {
	@include box-shadow(3px 3px 0px 0px $btn-primary-shadow-color);
	background-color: $white;
	color: $grey-dark;

	&:after {
		@include triangle-right($grey-dark, 10px, 5px);
		@include after-arrow($grey-dark, 9px, 9px, 2px, rotate(45deg), 10px, 42%);

		//Arguments - $color, $heightwidth, $border-width, $rotation, $left, $top
		@media (min-width: $screen-sm-min) {
			@include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 10px, 42%);
			//Arguments - $color, $height $width, $border-width, $rotation, $left, $top
		}
	}

	// Hover
	&:hover,
	&:focus,
	&.focus {
		@include box-shadow(3px 3px 0px 0px $grey);
		background-color: $white;
		color: $grey-dark;

		&:after {
			border-left-color: $btn-secondary-color;
		}
	}

	// Active
	&:active,
	&.active {
		@include box-shadow(3px 3px 0px 0px $grey);
		background-color: $grey-light !important;
		color: $grey !important;

		&:after {
			border-left-color: $grey-dark;
		}
	}

	// Disabled
	&.disabled {
		@include box-shadow(3px 3px 0px 0px $btn-primary-disabled-shadow-color);
		background-color: $btn-primary-disabled-bg;
		color: $btn-primary-disabled-color;

		&:after {
			border-left-color: $btn-primary-disabled-color;
		}
	}
}

//Used for loadMore jquery
.btn-show {
	@extend .btn-neutral;
	color: $grey-dark;
	text-align: center;
	background-color: $white;

	&:after {
		@include after-arrow($grey-dark, 9px, 9px, 4px, rotate(135deg), 10px, 60%);
	}

	//Arguments - $color, $heightwidth, $border-width, $rotation, $right, $top
	&:hover {
		&:after {
			top: 68%;
			transition: all 0.3s;
		}
	}
}

.btn-secondary-show {
	@extend .btn-show;
	@include box-shadow(3px 3px 0px 0px $btn-secondary-shadow-color);
	background-color: $btn-secondary-bg;
	color: $grey-dark;
	padding-left: 35px;
	padding-right: 45px;

	&:after {
		@include after-arrow($grey-dark, 9px, 9px, 4px, rotate(135deg), 15px, 60%);
	}

	&:hover {
		background-color: $brand-secondary;

		&:after {
			@include after-arrow($grey-dark, 9px, 9px, 4px, rotate(135deg), 15px, 60%);
			top: 68%;
			transition: all 0.3s;
		}
	}
}

.btn-cs-show {
	@extend .btn-show;
	color: $white;
	background-color: $uow-magenta;
	box-shadow: $uow-magenta;
	padding-left: 35px;
	padding-right: 45px;
	&:after {
		@include after-arrow($white, 9px, 9px, 4px, rotate(135deg), 15px, 60%);
	}
	// Hover
    &:hover,
    &:focus,
    &.focus {
        @include box-shadow(3px 3px 0px 0px $uow-magenta);
        background-color: $grey-dark;
        color: $white;
        &:after {
            border-left-color: $uow-magenta-masthead;
			@include after-arrow($white, 9px, 9px, 4px, rotate(135deg), 15px, 60%);
			top: 68%;
			transition: all 0.3s;
        }
    }
}

//Unopened state
.btn-nav-show {
	@extend .btn-cs-show;
	@include box-shadow(3px 3px 0px 0px $btn-secondary-shadow-color);
	@extend .btn-show;
	color: $grey-dark;
	background-color: $brand-secondary;
	padding-left: 35px;
	padding-right: 45px;
	z-index: 200;

	&:after {
		@include after-arrow($grey-dark, 9px, 9px, 4px, rotate(135deg), 15px, 50%);
		//Arguments - $color, $height $width, $border-width, $rotation, $left, $top
	}

	&:hover,
	&:focus,
	&:visited {
		@include box-shadow(3px 3px 0px 0px $grey);
		color: $brand-secondary;
		background-color: $grey-dark;

		&:after {
			@include after-arrow($brand-secondary, 9px, 9px, 4px, rotate(135deg), 15px, 53%);
			top: 65%;
			transition: top 0.3s;
		}
	}
}

.open {
	.btn-cs-show {
		color: $white;
		background-color: $uow-magenta;
		cursor: pointer;

		&:hover {
			&:after {
				@include after-arrow($white, 9px, 9px, 4px, rotate(-45deg), 20px, 60%);
				top: 52%;
				transition: all 0.3s;
			}
		}

		&:after {
			@include after-arrow($white, 9px, 9px, 4px, rotate(-45deg), 20px, 60%);
		}
	}

	.btn-nav-show {
		@include font-size(1.6);
		color: $grey-dark;
		background-color: $brand-secondary;
		cursor: pointer;
		margin-bottom: 0;

		&:after,
		&:hover::after {
			@include after-arrow(transparent, 0, 0, 4px, rotate(0), 20px, 35%);
			@include font-size(1.5);
			content: "\e605";
			color: $grey-dark;
			font-family: "icomoon";
			right: 25px !important;
			line-height: 1;
			position: absolute;
			top: 12px;
		}
	}

	.btn-secondary-show {
		color: $grey-dark;
		background-color: $brand-secondary;
		cursor: pointer;

		&:hover {
			&:after {
				@include after-arrow($grey-dark, 9px, 9px, 4px, rotate(-45deg), 20px, 60%);
				top: 52%;
				transition: all 0.3s;
			}
		}

		&:after {
			@include after-arrow($grey-dark, 9px, 9px, 4px, rotate(-45deg), 20px, 60%);
		}
	}
}

// Hidden class that is exposed on click of .btn-nav-show
.cover {
	background-position: center center;
	background-size: cover;
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	width: 100%;
	background: $grey-dark;
	opacity: .7;
	z-index: 200;
}

// Small button
.btn-sm {
	@include font-size(1.3);
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 30px;
	padding-top: 10px;
}

// Icon button
.btn-icon {
	padding-right: 55px;

	@media (min-width: $screen-sm-min) {
		padding-right: 55px;
	}

	&:after {
		display: none;
	}

	span {
		@include font-size(3);
		@include transform(translateY(-50%));
		position: absolute;
		right: 10px;
		top: 50%;
	}

	&.btn-sm {
		padding-right: 35px;

		@media (min-width: $screen-sm-min) {
			padding-right: 40px;
		}

		span {
			@include font-size(1.5);

			@media (min-width: $screen-md-min) {
				@include font-size(1.8);
			}
		}
	}
}

// Live chat button z-index overrides
#myBtn {
	z-index: 20 !important;
}
