//
// Variables
// --------------------------------------------------
//== Media queries breakpoints
//
// Extra-extra small screen / phone 360px
//** Deprecated `$screen-xxs` - custom breakpoint
$screen-xxs: 360px !default;
//** Deprecated `$screen-xxs-min` - custom breakpoint
$screen-xxs-min : $screen-xxs !default;
//** Deprecated `$screen-xxs-phone` - custom breakpoint
$screen-xxs-phone : $screen-xxs-min !default;
// iPhone 6,7,8 screen
//** Deprecated `$screen-iphone` - custom breakpoint
$screen-iphone: 375px !default;
//** Deprecated `$screen-xxs-min` - custom breakpoint
$screen-iphone-min: $screen-iphone !default;
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min : $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;
// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min : $screen-md !default;
// So media queries don't overlap when required, provide a maximum
//**  New variable for screens smaller than `$screen-phone` 480px
$screen-xxs-max : ($screen-xs-min - 1) !default;
// Media query breakpoints
$grid-gutter-width: 60px;
$container-tablet: (690px + $grid-gutter-width);
$container-desktop: (910px + $grid-gutter-width);
$container-large-desktop : (1110px + $grid-gutter-width);
$grid-float-breakpoint : $screen-md-min;
// Colors
$grey-dark: #33312d;
$grey: #848383;
$grey-light: #dfe0db;
$grey-lighter: #eae9e4;
$white: #fff;
$red: #b3302e;
$brand-primary: #108480; // original colour #14a6a1; too light for accessibility
$brand-primary-dark: #00413d;
$brand-primary-med: #14a6a1;
$brand-primary-light: #83cdc8;
$brand-secondary: #ffdf00;
$brand-secondary-dark: #9d8300;
//UOW Blue
$uow-blue: #4b92db;
$uow-blue-light: #a5c9ed;
$uow-blue-dark: #25496d;
//UOW magenta
$uow-magenta: #d10074;
$uow-magenta-masthead: #AA0C62;
$uow-magenta-light: #FF008E;
$uow-magenta-lighter: #F8D9EA;
$uow-magenta-med: #B90769;
$uow-magenta-dark: #51002D;
//UOW brand colors
$uow-burgundy : #6a1a41;
$uow-grey : #7d9aaa;
$uow-turqoise : #00b2a9;
$uow-orange : #d47b22;
$uow-magenta : #d10074;
$uow-yellow : #e4d700;
$uow-red : #f7403a;
$uow-purple : #8c6cd0;
$uow-blue : #4b92db;
$uow-green : #34b233;
// UoW charts
$uow-turqoise-light : #00CCBE;
$uow-turqoise-med : #008C83;
$uow-turqoise-dark : #004D47;
$uow-green-med : #58CC63;
$uow-sky-blue-light : #4B93DB;
$uow-sky-blue-med : #35689C;
$uow-sky-blue-dark : #1F3E5C;
$uow-sky-blue : #271AF5;
// Typography
$blockquote-icon-color : $grey-light;
$blockquote-small-color : $grey-dark;
$font-family-sans-serif : "KarlaRegular";
$heading-underline-color : $brand-secondary;
$icon-font-path: "../fonts/";
$ul-bullet-color: $brand-primary;
$ul-child-bullet-color : $grey;
// Page header
$page-header-border-color : $grey-dark;
// Scaffolding
$body-bg: #f3f4ef;
$text-color: $grey-dark;
$link-color: $text-color;
$link-hover-color: $brand-primary;
// Buttons
$btn-primary-active-color: $brand-primary;
$btn-primary-active-bg: $brand-primary-light;
$btn-primary-active-shadow-color: $brand-primary;
$btn-primary-bg: $brand-primary;
$btn-primary-color: $white;
$btn-primary-disabled-bg: $grey-light;
$btn-primary-disabled-color: $grey;
$btn-primary-disabled-shadow-color: $grey;
$btn-primary-hover-color: $white;
$btn-primary-hover-bg: $brand-primary-light;
$btn-primary-hover-shadow-color: $brand-primary;
$btn-primary-shadow-color: $brand-primary-dark;
$btn-secondary-active-color: $brand-secondary;
$btn-secondary-active-bg: $grey-dark;
$btn-secondary-active-shadow-color: $grey;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-color: $grey-dark;
$btn-secondary-disabled-bg: $grey-light;
$btn-secondary-disabled-color: $grey;
$btn-secondary-disabled-shadow-color: $grey;
$btn-secondary-hover-color: $brand-secondary;
$btn-secondary-hover-bg: $grey-dark;
$btn-secondary-hover-shadow-color: $grey;
$btn-secondary-shadow-color: $brand-secondary-dark;
// Tabs
$nav-tabs-active-link-hover-bg: $white;
$nav-tabs-active-link-hover-color: $grey-dark;
$nav-tabs-content-bg: $white;
$nav-tabs-link-bg: $grey-light;
$nav-tabs-link-color: $grey-dark;
$nav-tabs-link-hover-bg: $brand-secondary;
// Breadcrumbs
$breadcrumb-active-color: $grey-dark;
$breadcrumb-bg: rgba(255, 255, 255, 0.7);
$breadcrumb-color: $grey-dark;
$breadcrumb-link-color: $grey-dark;
$breadcrumb-link-hover-color: $brand-primary;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-padding-vertical: 10px;
// Pagination
$pagination-active-color: $grey-dark;
$pagination-active-bg: $brand-secondary;
$pagination-bg: transparent;
$pagination-color: $grey;
$pagination-disabled-bg: transparent;
$pagination-disabled-color: $grey-light;
$pagination-hover-bg: transparent;
// Tables
$table-bg: $white;
$table-bg-hover: $body-bg;
$table-border-color: $body-bg;
$table-cell-padding: 15px;
$table-dark-head-bg: $grey-dark;
$table-dark-head-color: $brand-secondary;
$table-head-bg: $grey-light;
$table-head-color: $grey-dark;
$table-course-listing-border-color: $grey-light;
$table-course-listing-header-border-color : $grey;
$table-course-listing-header-color: $brand-primary;
// Panels
$panel-atoz-filter-bg: $white;
$panel-atoz-filter-link-bg: $brand-secondary;
$panel-atoz-filter-link-color: $grey-dark;
$panel-bg: transparent;
$panel-body-padding: 20px;
$panel-content-border-color: $brand-secondary;
$panel-dropdown-filter-bg: $white;
$panel-enquiries-bg: $brand-secondary;
$panel-event-date-bg: $brand-primary;
$panel-event-date-color: $white;
$panel-event-hover-bg: $white;
$panel-event-info-icon-color: $brand-primary;
$panel-event-tag-bg: $brand-secondary;
$panel-event-time-bg: $grey-dark;
$panel-event-time-color: $white;
$panel-group-header-bg: $grey-light;
$panel-group-header-color: $grey-dark;
$panel-group-bg: $white;
$panel-group-header-active-bg: $grey-dark;
$panel-group-header-active-color: $brand-secondary;
$panel-group-header-hover-bg: $brand-secondary;
$panel-image-caption-bg: $grey-light;
$panel-image-caption-color: $grey-dark;
$panel-image-gallery-border-color: $grey-light;
$panel-image-gallery-overlay-bg: $grey-dark;
$panel-image-gallery-pager-color: $grey-dark;
$panel-image-gallery-pager-active-color : $brand-secondary;
$panel-image-gallery-pagination-bg: $brand-secondary;
$panel-image-gallery-pagination-color: $grey-dark;
$panel-keyword-search-bg: $white;
$panel-keyword-search-border-color: $grey-dark;
$panel-news-date-color: $grey;
$panel-news-tag-bg: $brand-secondary;
$panel-reason-number-color: $brand-primary;
$panel-related-course-hover-bg: $white;
$panel-related-course-info-icon-color: $brand-primary;
$panel-related-course-subject-color: $grey;
$panel-related-course-title-level-color : $grey;
$panel-seperator-color: $grey;
$panel-seperator-dark-color: $grey-dark;
$panel-seperator-white-bg: $white;
$panel-seperator-light-bg: $grey-lighter;
$panel-seperator-light-color: $grey-light;
$panel-statistics-bg: $grey-lighter;
$panel-statistics-inverted-bg: $grey-dark;
$panel-statistics-inverted-color: $grey-lighter;
$panel-statistics-secondary-bg: $brand-secondary;
$panel-statistics-secondary-inverted-bg : $grey-dark;
$panel-statistics-secondary-inverted-color : $brand-secondary;
$panel-your-westminster-bg: $brand-primary;
$panel-your-westminster-title-color: $white;
$panel-video-border-color: $grey-light;
// Form states and alerts
$state-danger-text: $red;
$state-success-text: $uow-green;
// Navbar
$navbar-main-collapse-bg: $grey-light;
$navbar-main-header-bg: $white;
$navbar-main-main-bg: $white;
$navbar-main-main-dropdown-bg: $grey-dark;
$navbar-main-main-dropdown-link-color: $white;
$navbar-main-main-dropdown-link-hover-color : $brand-secondary;
$navbar-main-main-link-active-bg: $brand-secondary;
$navbar-main-main-link-border-color: $grey-light;
$navbar-main-main-link-color: $grey-dark;
$navbar-main-main-link-hover-bg: $grey-dark;
$navbar-main-main-link-hover-color: $white;
$navbar-main-search-bg: $white;
$navbar-main-top-left-bg: $brand-primary;
$navbar-main-top-left-link-color: $white;
$navbar-main-top-link-active-bg: $brand-secondary;
$navbar-main-top-link-color: $grey-dark;
$navbar-main-top-link-hover-bg: $grey-dark;
$navbar-main-top-link-hover-color: $body-bg;
$navbar-search-bg: $white;
$navbar-search-border-color: $grey-dark;
// Masthead
$masthead-bg: $brand-primary;
$masthead-course-header-color: $white;
$masthead-generic-header-color: $grey-dark;
$masthead-index-header-color: $white;
$masthead-feature-generic-info-bg: $white;
$masthead-feature-generic-title-bg: rgba(51, 49, 45, 0.8);
$masthead-feature-open-day-book-color: $grey-dark;
$masthead-feature-open-day-date-bg: $grey-dark;
$masthead-feature-open-day-date-color: $white;
$masthead-feature-open-day-info-bg: $white;
$masthead-feature-open-day-link-bg: $brand-secondary;
$masthead-feature-open-day-link-color: $grey-dark;
$masthead-feature-open-day-link-hover-bg : $grey-dark;
$masthead-feature-open-day-link-hover-color : $white;
$masthead-feature-open-day-title-bg: rgba(51, 49, 45, 0.8);
$masthead-overlay-bg: $grey-dark;
$masthead-feature-prospectus-bg: $white;
$masthead-feature-prospectus-color: $grey-dark;
$masthead-feature-prospectus-hover-bg: $brand-secondary;
// Courses
$course-entry-requirements-content-bg: $white;
$course-entry-requirements-content-border-color : $grey-dark;
$course-entry-requirements-content-heading-color : $brand-primary;
$course-entry-requirements-points-bg: $grey-dark;
$course-entry-requirements-points-color: $white;
$course-entry-requirements-points-value-color: $brand-secondary;
$course-leader-title-link-color: $brand-primary;
$course-overview-icon-color: $brand-primary;
// Wells
$well-bg: $white;
$well-gray-bg: $grey-lighter;
// Forms
$form-help-color: $grey;
$input-bg: $grey-lighter;
$input-border-focus: $grey;
$input-color: $grey-dark;
$input-color-placeholder: $grey;
$legend-color: $grey-dark;
// SelectBoxIt
$selectboxit-primary-arrow-color: $grey-dark;
$selectboxit-primary-btn-active-bg: $grey-dark;
$selectboxit-primary-btn-active-color: $brand-secondary;
$selectboxit-primary-btn-bg: $grey-lighter;
$selectboxit-primary-btn-color: $grey-dark;
$selectboxit-primary-option-active-bg: $brand-secondary;
$selectboxit-primary-option-color: $grey-dark;
$selectboxit-primary-option-disabled-color : $grey;
$selectboxit-primary-options-bg: $grey-lighter;
$selectboxit-secondary-arrow-color: $grey-dark;
$selectboxit-secondary-btn-active-bg: $grey-dark;
$selectboxit-secondary-btn-active-color : $brand-secondary;
$selectboxit-secondary-btn-bg: $brand-secondary;
$selectboxit-secondary-btn-color: $grey-dark;
$selectboxit-secondary-option-active-bg : $brand-secondary;
$selectboxit-secondary-option-color: $grey-dark;
$selectboxit-secondary-option-disabled-color : $grey;
$selectboxit-secondary-options-bg: $grey-lighter;
// Modals
$modal-header-border-color: $brand-primary;
$modal-header-close-color: $grey-dark;
// Page navigation
$nav-page-border-color: $grey-light;
$nav-page-link-hover-bg: $brand-secondary;
$nav-page-link-hover-color: $grey-dark;
// Subjects
$subject-listing-bg: $grey-lighter;
$subject-listing-hover-bg: $brand-secondary;
// Anchor navigation
$nav-anchor-bg: $grey-light;
$nav-anchor-hover-bg: $brand-secondary;
$nav-anchor-ucas-info-bg: rgba(51, 49, 45, 0.8);
$nav-anchor-ucas-info-mobile-text-color : $white;
$nav-anchor-ucas-link-bg: #e7131a; //#ed1c24;
$nav-anchor-ucas-link-color: $white;
$nav-anchor-ucas-link-hover-bg: $grey-dark;
// Profiles
$featured-profile-title-link-color: $brand-primary;
$profile-name-color: $brand-primary;
// Footer
$footer-bg : $grey-dark;
$footer-content-link-color: $white;
$footer-content-section-link-color: $brand-secondary;
$footer-social-color: $brand-secondary;
$footer-terms-color: $white;