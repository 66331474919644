/* -----------------------------------
 * SASS for event panels and features
 */

// -------------------------
.panel-search {
  margin-top: 15px;
  margin-bottom: 10px;

  @media (min-width: $screen-md-min) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.path-search .panel-search,
.path-course-search .panel-search,
.path-about-us .panel-search {
  margin-top: 45px;
}

.tab-pane {
  .panel-search {
    margin-bottom: 0;
  }
}

#hub-collapse,
#library-collapse {
  .panel-body {
    padding-bottom: 0;
  }
}

.panel-search-border {
  &--primary {
    border-bottom: 6px solid $brand-primary;
    padding-top: 0;
    padding-bottom: 0;
  }

  &--magenta {
    border-bottom: 6px solid $uow-magenta;
    padding-top: 0;
    padding-bottom: 0;
  }

  &--yellow {
    border-bottom: 6px solid $brand-secondary;
  }
}

// Keyword search
.panel-keyword-search {
  @extend .text-center;
  background-color: $panel-keyword-search-bg;
  margin-top: 0;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    padding-top: 5px !important;
  }

  @media (min-width: $screen-md-min) {
    padding-bottom: 30px;
  }

  form {
    width: 100%;

    label,
    .panel-keyword-search__label {
      @include font-karla-bold;
      @include font-size(1.4);
      @extend .text-uppercase;
      margin-right: 10px;

      @media (min-width: $screen-sm-min) {
        width: auto;
      }

      i {
        @include font-size(2.2);
        margin-right: 5px;
        position: relative;
        top: 4px;
      }
    }

    [class^="icon-"] {
      margin-right: 0;
    }

    //Override Bootstrap form styles
    .form-group {
      margin-right: 0;
    }

    //Override Bootstrap form styles
    .input-group {
      width: 100%;

      @media (min-width: $screen-sm-min) {
        width: auto !important;
        margin-right: 0;
      }

      .input-group-addon {
        display: none;
      }
    }

    input {
      @include font-karla-bold;
      @include font-size(2.3);
      @include form-control-focus;
      background-color: $white;
      border: none;
      border-bottom: 1px solid $grey-dark;
      border-radius: 0;
      box-shadow: none;
      margin-top: 10px;
      margin-right: 20px;
      position: relative;
      top: 1px;
      -webkit-appearance: none;
      width: 100%;

      // Add thicker line for accessibility (ensure form controls change)
      &:focus {
        border-bottom: 4px solid $brand-secondary !important;
      }

      @media (min-width: $screen-sm-min) {
        @include font-size(2);
        margin-top: 0;
        width: 270px !important;
      }

      @media (min-width: $screen-md-min) {
        @include font-size(2.3);
        width: 200px !important;
      }

      @media (min-width: $screen-lg-min) {
        width: 330px !important;
      }

      // Disable box shadow on focus
      &:focus {
        border-bottom-color: inherit;
        box-shadow: none !important;
      }

      //placeholder styling
      &::placeholder {
        @include font-size(1.4);
        line-height: 20px;
      }
    }

    button {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  p {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__extra-info {
    @extend .panel-keyword-search;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      padding-top: 30px !important;
      padding-bottom: 15px !important;
    }
  }

  &__student {
    padding-bottom: 30px !important;
  }

  &__fields {
    display: inline-block;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 10px;
    }
  }

  &__browse {
    @include clearfix();
    position: relative;
    padding-top: 10px;
    padding-left: 15px;

    @media (min-width: $screen-sm-min) {
      display: inherit;
      margin-left: -30px;
      padding-left: 30px;
    }

    @media (min-width: $screen-md-min) {
      text-align: left;
      padding-left: 35px;
      border-left: 3px solid $grey-light;
      margin-bottom: 20px;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 45px;
    }

    &--links {
      @include clearfix();
      @include font-size(1.6);
      display: block;
      text-align: left;
      max-width: 250px;

      span {
        text-transform: uppercase;
      }

      ul {
        margin-top: 5px;
        margin-bottom: 15px;
        padding-left: 0;

        li {
          &:before {
            border: none;
          }
        }
      }
    }
  }
}

.library-search {
  form {
    input {
      width: 100% !important;

      @media (min-width: $screen-md-min) {
        width: 180px !important;
        margin-right: 20px;
      }

      @media (min-width: $screen-lg-min) {
        width: 300px !important;
      }
    }
  }
}

//Autocomplete ul and li styling for Course Search bar
ul.ui-autocomplete {

  li.ui-menu-item-wrapper,
  li.ui-menu-item,
  li.ui-state-focus {
    &:before {
      display: none;
    }

    a {
      border: none;
      color: $grey-dark !important;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {
        background-color: $brand-secondary !important;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-secondary !important;
    }
  }

  li.ui-state-focus {
    background-color: $brand-secondary !important;
    color: $grey-dark !important;

    a {
      border: none;
      color: $grey-dark !important;

      &:hover,
      &:focus,
      &:active {
        background-color: $brand-secondary !important;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-secondary;
      color: $grey-dark !important;
    }
  }
}

// Faceted search (uses accordion too)
.panel-faceted-search {
  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }

  &__options {
    display: inline-block;
    width: 100%;
  }

  &__option {
    display: inline-block;
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 30px;
    }

    &-text {
      @include font-size(1.3);
      @extend .pull-left;
      width: 85%;

      @media (min-width: $screen-md-min) {
        @include font-size(1.6);
      }
    }

    &-input {
      @extend .pull-right;

      @media (min-width: $screen-md-min) {
        position: relative;
        top: 3px;
      }
    }
  }
}

//Styles that Drupal 8 Facets module uses - old style
.facets-widget-checkbox {
  ul.item-list__checkbox {
    padding-left: 0;

    li.facet-item {
      position: relative;
      margin-top: 30px;

      &:before {
        display: none;
      }

      //Class of span added after input checkbox
      .greybox {
        float: right;
        // display: inline-block;
        // *display: inline;
        vertical-align: top;
        width: 20px;
        height: 20px;
        border: 2px solid $grey-dark;
        background-color: $white;
        cursor: pointer;
      }

      //Hide the input checkbox
      input.facets-checkbox {
        position: absolute;
        right: 0;
        opacity: 0;
        cursor: pointer;
      }

      //On hover change position of background image to green bordered grey box
      &:hover input~.greybox {
        background-color: $brand-secondary;
      }

      //When checkbox is checked change background image position to show tick
      input:checked~.greybox {
        background: url(../images/icheck.png) -40px 0 no-repeat;
      }
    }
  }
}

// A-Z Filter used on Your country page
.panel-atoz-filter {
  @extend .text-center;
  background-color: $panel-atoz-filter-bg;
  margin-top: 0;
  padding: 15px;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }

  &__items {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: 80%;
    }

    @media (min-width: $screen-md-min) {
      width: 100%;
    }
  }

  &__item {
    display: inline-block;

    &:before {
      display: none;
    }
  }

  &__link {
    a {
      @include font-size(1.4);
      @extend .text-center;
      border-bottom: none;
      color: $panel-atoz-filter-link-color;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      width: 26px;
      border-radius: 50%;
      border-bottom: none;

      &:hover,
      &:focus,
      &--active,
      .is-active {
        background-color: $panel-atoz-filter-link-bg;
        color: $panel-atoz-filter-link-color;
        border-radius: 50%;
      }

      @media (min-width: $screen-sm-min) {
        @include font-size(1.6);
      }
    }
  }
}

.search-results {
  @include font-karla-bold();
  @include font-size(1.4);
  color: $grey-dark;
  text-align: center;
  margin-top: -15px;

  @media (min-width: $screen-sm-min) {
    text-align: left;
    margin-top: -10px;
  }
}