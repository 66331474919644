/* -----------------------------------
 * SASS Useful Mixins
 */


// Web font mixins
// -------------------------
@mixin font-futura-book() {
  font-family: "Futura LT W01 Book";
}

@mixin font-futura-bold() {
  font-family: "Futura W01 Bold";
}

@mixin font-futura-heavy-oblique() {
  font-family: "FuturaW01-HeavyOblique";
}

@mixin font-karla-regular() {
  font-family: "KarlaRegular";
}

@mixin font-karla-bold() {
  font-family: "KarlaBold";
}

/* REM Font size with Pixel fallback */
@mixin font-size($sizeValue) {
  $remValue: $sizeValue;
  $pxValue: ($sizeValue * 10);
  font-size: #{$pxValue}px;
  font-size: #{$remValue}rem;
}

/* Triangle Right */
@mixin triangle-right($color, $height, $width) {
  border-color: transparent transparent transparent $color;
  border-style: solid;
  border-width: ($height/2) 0 ($height/2) ($width);
  height: 0;
  -moz-transform: scaleY(.9999);
  -moz-transform-origin: top;
  width: 0;
}

/* Triangle Left */
@mixin triangle-left($color, $height, $width) {
  border-color: transparent $color transparent transparent;
  border-style: solid;
  border-width: ($height/2) ($width) ($height/2) 0;
  height: 0;
  -moz-transform: scaleY(.9999);
  -moz-transform-origin: top;
  width: 0;
}

/* Triangle Top */
@mixin triangle-top($color, $height, $width) {
  border-color: transparent transparent $color transparent;
  border-style: solid;
  border-width: 0 ($width/2) $height ($width/2);
  height: 0;
  -moz-transform: scaleY(.9999);
  -moz-transform-origin: top;
  width: 0;
}

/* Triangle Bottom */
@mixin triangle-bottom($color, $height, $width) {
  border-color: $color transparent transparent transparent;
  border-style: solid;
  border-width: $height ($width/2) 0 ($width/2);
  height: 0;
  -moz-transform: scaleY(.9999);
  -moz-transform-origin: top;
  width: 0;
}

/*Arrow-Chevron */
/*Rotation top -45deg*/
/*Rotation right 45deg*/
/*Rotation bottom 135deg*/
/* right in button bw 2px 2px h10px w10px */
@mixin after-arrow($color, $height, $width, $border-width, $transform, $right, $top) {
  content: '';
  border-style: solid;
  border-color: $color;
  border-width: $border-width $border-width 0 0;
  transform: $transform;
  transform-origin: top;
  height: $height;
  width: $width;
  display: inline-block;
  right: $right;
  position: absolute;
  top: $top;
  vertical-align: middle;
}

/* Icon using svg font */
@mixin icon($content, $color, $fontfamily, $fontsize) {
  &:before {
    content: $content;
    color: $color;
    font-family: $fontfamily;
    font-size: $fontsize;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* Gradient */
@mixin gradient($color: #F5F5F5, $start: #EEE, $stop: #FFF) {
  background: $color;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, $start), color-stop(1, $stop));
  background: -ms-linear-gradient(bottom, $start, $stop);
  background: -moz-linear-gradient(center bottom, $start 0%, $stop 100%);
  background: -o-linear-gradient($stop, $start);
  filter: e("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", $stop, $start);
}

/* Border Radius */
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  border-top-left-radius: $topleft;
  @include background-clip(padding-box);
}

@mixin background-clip($argument: padding-box) {
  -moz-background-clip: $argument;
  -webkit-background-clip: $argument;
  background-clip: $argument;
}

/* Opacity */
@mixin opacity($opacity: 0.5) {
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  -webkit-opacity: $opacity;
  opacity: $opacity;
  $opperc: $opacity * 100;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opperc})";
  filter: "alpha(opacity=#{$opperc})";
}

/* Transition Duration */
@mixin transition-duration($duration: 0.2s) {
  -moz-transition-duration: $duration;
  -webkit-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}

/* Transition */
@mixin transition($arguments...) {
  -webkit-transition: $arguments;
  -moz-transition: $arguments;
  -o-transition: $arguments;
  -ms-transition: $arguments;
  transition: $arguments;
}

/* Transform */
@mixin transform($arguments...) {
  -webkit-transform: $arguments;
  -moz-transform: $arguments;
  -o-transform: $arguments;
  -ms-transform: $arguments;
  transform: $arguments;
}

/* Transform */
@mixin transform-origin($arguments...) {
  -webkit-transform-origin: $arguments;
  -moz-transform-origin: $arguments;
  -o-transform-origin: $arguments;
  -ms-transform-origin: $arguments;
  transform-origin: $arguments;
}

/* Rotation */
@mixin rotation($deg:5deg) {
  @include transform(rotate($deg));
}

/* Scale */
@mixin scale($ratio:1.5) {
  @include transform(scale($ratio));
}

/* BoxShadow */
@mixin box-shadow($arguments...) {
  -webkit-box-shadow: $arguments;
  -moz-box-shadow: $arguments;
  box-shadow: $arguments;
}

/* Image overlay - used in Masthead images */
@mixin image-overlay($backgrounad-color: $masthead-overlay-bg, $height: 100%, $opacity: 0.4) {
  &:before {
    @include opacity($opacity);
    background-color: $backgrounad-color;
    content: "";
    height: $height;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

/* No padding */
@mixin no-padding() {
  padding-left: 0;
  padding-right: 0;
}

/* Force overly long spans of text to truncate, e.g.:
// @include truncate(100%);
// Where $truncation-boundary is a united measurement.
// Use for Related pages panels?
*/

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin focus() {
  // Not WebKit-specific. 
  // Bootstrap mixin removes outline from Firefox apparently.
  outline: 5px auto blue;
  outline-offset: -2px;
}
