    //
    // All Related Content Panels
    // --------------------------------------------------
    .related-pages-container {
      margin-bottom: 30px;

      @media (min-width: $screen-md-min) {
        margin-top: 30px;
        margin-bottom: 35px;
      }
    }

    // Related course
    .panel-related-course {
      position: relative;

      @media (min-width: $screen-md-min) {
        display: inline-block;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
      }

      &:hover,
      &:focus {
        @media (min-width: $screen-md-min) {
          background-color: $white;
        }
      }

      &__subject {
        @include font-size(1.6);
        color: $panel-related-course-subject-color;
        line-height: 100%;

        &:hover,
        &:focus {
          color: $panel-related-course-subject-color;
        }
      }

      &__title {
        margin-bottom: 10px;
        margin-top: 0;

        a {
          border-bottom: 1px solid $text-color;
          display: inline;

          &:hover,
          &:focus {
            border-bottom: none;
          }
        }

        span {
          @include font-karla-bold();
          @include font-size(1.4);
          @extend .text-uppercase;
          color: $grey-dark;
          display: block;
          line-height: 120%;
          margin-top: 5px;
        }
      }

      &__image {
        @extend .hidden-xs;
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;

        img {
          width: 100%;
        }

        a {
          display: inline-block;
          width: 100%;
        }
      }

      &__info {
        @extend .text-uppercase;
        margin-top: 20px;
        padding-left: 50px;
        position: relative;
        width: 100%;

        @media (min-width: $screen-sm-min) {
          float: left !important;
          text-align: center;
          padding-left: 0;
          width: 50%;
        }

        [class^="icon-"],
        [class*=" icon-"] {
          @include font-size(3.0);
          color: $panel-related-course-info-icon-color;
          left: 0;
          position: absolute;
          top: 7px;

          @media (min-width: $screen-sm-min) {
            @include font-size(2.6);
            display: inline-block;
            left: 0;
            position: relative;
            top: 0;
            width: 100%;
          }
        }

        &-title {
          @include font-size(1.2);
          display: inline-block;
          line-height: 140%;
          width: 100%;
        }

        &-value {
          @include font-karla-bold();
          @include font-size(1.5);
          display: inline-block;
          width: 100%;
        }
      }
    }

    // Related content without image
    .panel-related-content-simple {
      background-color: $white;
      border-top: solid 5px $brand-secondary;
      border-bottom: solid 5px $brand-secondary;
      padding: 10px;
      padding-right: 15px;
      margin-top: 10px;
      margin-bottom: 10px;

      &:after {
        @include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 37px, 42px);
        //Arguments - $color, $heightwidth, $border-width, $transform, $right, $top
      }

      &:hover {
        &:after {
          @include after-arrow($brand-primary, 9px, 9px, 3px, rotate(45deg), 37px, 42px);
        }
      }

      @media (min-width: $screen-md-min) {
        &:after {
          @include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 15px, 315px);
          //Arguments - $color, $heightwidth, $border-width, $transform, $right, $top
        }

        &:hover {
          &:after {
            @include after-arrow($brand-primary, 9px, 9px, 3px, rotate(45deg), 15px, 315px);
          }
        }
      }

      @media (min-width: $screen-md-min) {
        border-bottom: none;
        padding: 15px;
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
        min-height: 310px;
      }

      a {
        @include clearfix();
        display: inline-block;
        width: 100%;
        min-height: inherit;
        top: 0;
        left: 0;
      }

      &__title {
        margin-top: 10px;
      }

      &__summary {
        @extend .hidden-xs;
        @extend .hidden-sm;
        overflow-y: hidden;

        @media (min-width: $screen-md-min) {
          position: absolute;
          top: 115px;
          padding-right: 15px;
        }
      }

      &__go {
        @extend .hidden-xs;
        @extend .hidden-sm;
        @extend .text-right;
        @include font-karla-bold();
        @include font-size(1.6);
        display: inline-block;
        margin-top: 10px;
        width: 100%;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
          position: absolute;
          bottom: 10px;
          right: 30px;
        }
      }
    }

    // Related content with image
    .panel-related-content {
      &__title {
        margin-top: 0;

        @media (min-width: $screen-lg-min) {
          // min-height: 80px;
          overflow-y: hidden;
        }

        a {
          border-bottom: 1px solid $text-color;
          display: inline;

          &:hover,
          &:focus {
            border-bottom: none;
          }
        }
      }

      &__image {
        @extend .hidden-xs;
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;

        img {
          width: 100%;
        }

        a {
          display: inline-block;
          width: 100%;
        }
      }

      &__summary {
        margin-bottom: 10px;
        overflow-y: hidden;

        @media (min-width: $screen-sm-min) {
          display: block;
          height: 77px;
          text-overflow: ellipsis;
        }
      }

      &__btn {
        @extend .text-right;
        display: inline-block;
        margin-top: 10px;
        width: 100%;
      }
    }