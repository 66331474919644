//
// Carousel
// --------------------------------------------------


// Wrapper for the slide container and indicators
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;

  > .item {
    display: none;
    position: relative;
    @include transition(.6s ease-in-out left);

    &:before {
        @include gradient(transparent, $grey-dark, transparent);
        content: "";
        height: 100%;
        position: absolute;
        left: 0;
        opacity: 0.3;
        top: 0;
        width: 100%;
        z-index: 10;
    }


    // Account for jankitude on images
    > img,
    > a > img {
      @include img-responsive;
      line-height: 1;
      display: block;
    }

    // WebKit CSS3 transforms for supported devices
    @media all and (transform-3d), (-webkit-transform-3d) {
      @include transition-transform(0.6s ease-in-out);
      @include backface-visibility(hidden);
      @include perspective(1000px);

      &.next,
      &.active.right {
        @include translate3d(100%, 0, 0);
        left: 0;
      }
      &.prev,
      &.active.left {
        @include translate3d(-100%, 0, 0);
        left: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        @include translate3d(0, 0, 0);
        left: 0;
      }
    }
  }

  > .active,
  > .next,
  > .prev {
    display: block;
  }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }
  > .prev {
    left: -100%;
  }
  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }
  > .active.right {
    left: 100%;
  }

}

// Left/right controls for nav
// ---------------------------

.carousel-control {
  @include text-hide;
  background-color: $panel-image-gallery-pagination-bg;
  cursor: pointer;
  height: 50px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 200;
  opacity: 1;

  &:hover,
  &focus {
    opacity: 1;
  }

  @media (min-width: $screen-md-min) {
      height: 120px;
      margin-top: -60px;
  }

  &:before {
      content: "";
      position: absolute;
      top: 19px;

      @media (min-width: $screen-md-min) {
          top: 54px;
      }
  }
  // We can't have this transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.

  // No gradients for backgrounds
  &.left {
    background-color: $panel-image-gallery-pagination-bg;
    background-image: none;
    left: 0;
    @include box-shadow(3px 3px 0px 0px $grey-dark);

    &:before {
        @include triangle-left($panel-image-gallery-pagination-color, 13px, 6px);
        left: 21px;
    }
  }
  &.right {
    left: auto;
    right: 0;
    background-image: none;
    @include box-shadow(-3px 3px 0px 0px $grey-dark);

    &:before {
        @include triangle-right($panel-image-gallery-pagination-color, 13px, 6px);
        right: 21px;
    }
  }
}

// Optional indicator pips

.carousel-indicators {
  @extend .text-center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index: 200;
  margin-left: 0;
  left: 0;
  margin-bottom: 2px;

  li {
    @include text-hide;
    border-radius: 50%;
    border: 2px solid $brand-secondary;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    width: 20px;
  }
  .active {
  //  margin: 0;
    width:  20px;
    height: 20px;
    background-color: $panel-image-gallery-pager-active-color;
  }
}

// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 45px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 5px;
  color: $carousel-caption-color;
  text-align: center;
  text-shadow: none;
  & .btn {
    text-shadow: none; // No shadow for button elements in carousel-caption
  }
}


// Scale up controls for tablets and up
@media screen and (min-width: $screen-sm-min) {

  // Show and left align the captions
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 70px;
    position: absolute;
  }

  // Move up the indicators
  .carousel-indicators {
    bottom: 5px;
  }
}
