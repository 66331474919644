.masthead {
    width: 100%;
}

// Index masthead - added 60px bottom margin to accomodate General Content panels on pages with mastheads
.masthead-index {
    @include image-overlay($masthead-overlay-bg, 100%, 0.4); // Arguments - bgcolor, height, opacity
    background-color: $masthead-bg;
    background-position: center center;
    background-size: cover;
    padding-bottom: 70px;
    position: relative;
    //    margin-bottom: 30px;
    margin-top: -20px;

    @media (min-width: $screen-sm-max) {
        margin-top: -20px;
    }

    .row {
        position: relative;
    }

    .breadcrumb {
        left: 0;
        position: absolute;
        top: 52px;

        @media (min-width: $screen-md-min) {
            top: 105px;
        }

        @media (min-width: $screen-lg-min) {
            top: 120px;
        }
    }

    &__header {
        display: inline-block;
        color: $masthead-index-header-color;
        padding-top: 120px;
        position: relative;
        width: 100%;
        z-index: 20;

        @media (min-width: $screen-sm-min) {
            padding-top: 215px;
        }

        h1,
        h3 {
            @include make-xs-column(12);
            @include make-sm-column(10);
            @include make-sm-column-offset(1);
            @extend .text-uppercase;
            margin-bottom: 20px;
            margin-top: 0;
            clear: both;
        }

        h2 {
            clear: both;
        }

        p {
            @include make-xs-column(12);
            @include make-sm-column(10);
            @include make-sm-column-offset(1);
            margin-bottom: 0;

            a {
                color: $masthead-index-header-color;
            }
        }
    }

    //Left aligned w/right hand feature
    &__left-w-feat {
        header {
            h1 {
                @include make-xs-column(12);
                @include make-sm-column(8);
                padding-left: 15px !important;
            }

            p {
                @include make-xs-column(12);
                @include make-sm-column(8);
                padding-left: 15px !important;
            }
        }
    }

    //Right hand feature
    &__feature-wrapper {
        @extend .text-center;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            margin-top: 80px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 0;
            position: absolute;
            right: 30px;
            top: 215px;
            width: auto;
        }

        @media (min-width: $screen-lg-min) {
            top: 240px;
        }
    }

    //Right CTA
    &__cta {
        float: right;
        width: 100%;
    }

    // Slim version
    &--slim {
        padding-bottom: 30px;

        .masthead-index__header {
            padding-top: 90px;

            @media (min-width: $screen-sm-min) {
                padding-top: 175px;
            }
        }
    }

    // Center aligned version
    &--center {
        @extend .text-center;

        //CLEARING TEXT
        .clearing {
            @include font-size(2.6);
            color: $brand-secondary;

            a {
                color: inherit;
                border-bottom: 0;
            }
        }

        .btn {
            z-index: 10;
            margin-top: 30px;
            margin-right: 30px;
            margin-left: 30px;
        }

        //Notification area
        &__notification {
            @extend .text-center;
            width: 100%;
            position: relative;
            margin-top: 30px;
            padding-top: 15px;
            padding-bottom: 5px;
            z-index: 200;
            background-color: $brand-secondary;

            @media (min-width: $screen-md-min) {
                width: 60%;
                left: 20%;
            }

            p {
                @include make-sm-column-offset(0);
                @include font-karla-bold();
                @include font-size(1.8);

                a {
                    color: $grey-dark;
                }
            }
        }
    }
}

// Masthead used on the homepage
.masthead-homepage {
    @extend .masthead-index;
    margin-bottom: 45px;
    padding-bottom: 30px;

    &__header {
        @extend .masthead-index__header;
        text-align: center;

        h1 {
            @extend .h1--small;
            margin-bottom: 35px;
        }

        p {
            @extend .lead;
        }
    }

    .btn {
        z-index: 10;
        margin-top: 35px;
        margin-right: 15px;
        margin-left: 15px;
        display: inline-block;
    }
}

// Research group landing pages masthead without bottom margin to
//accomodate sticky nav
.masthead-research {
    @extend .masthead-index;
    margin-bottom: 0;
}

// Course masthead
.masthead-course {
    @include image-overlay();
    background-color: $masthead-bg;
    background-position: center center;
    background-size: cover;
    padding-bottom: 70px;
    position: relative;

    @media (min-width: $screen-sm-max) {
        margin-top: -5px;
    }

    .row {
        position: relative;
    }

    .breadcrumb {
        left: 0;
        position: absolute;
        top: 52px;
        z-index: 30;

        @media (min-width: $screen-md-min) {
            top: 105px;
        }

        @media (min-width: $screen-lg-min) {
            top: 120px;
        }
    }

    &__header {
        @extend .text-center;
        color: $masthead-course-header-color;
        display: inline-block;
        padding-top: 110px;
        position: relative;
        width: 100%;
        z-index: 20;

        @media (min-width: $screen-sm-min) {
            padding-top: 330px;
        }

        h1 {
            @include make-xs-column(12);
            @extend .text-uppercase;
            margin-bottom: 10px;
            margin-top: 0;

            span {
                @include font-futura-book();
                @extend .text-capitalize;
                display: inline-block;
            }
        }

        p {
            @include make-sm-column-offset(2);
            margin-bottom: 0 !important;

            a {
                border-bottom-color: $masthead-course-header-color;
                color: $masthead-course-header-color;
            }
        }

    }

    &__feature-wrapper {
        @extend .text-center;
        margin-top: 20px;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            margin-top: 80px;
        }

        >div {
            @media (min-width: $screen-sm-min) {
                margin-left: 10px;
                margin-right: 10px;
                vertical-align: top;
            }
        }
    }

    //Notification area for clearing
    &__notification {
        @extend .text-center;
        position: relative;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        z-index: 200;
        background-color: $brand-secondary;

        @media (min-width: $screen-sm-min) {
            margin-top: 30px;
            width: 60%;
            left: 20%;
        }

        p {
            @include font-karla-bold();
            @include font-size(1.8);

            &:last-child {
                margin-bottom: 0 !important;
            }

            a {
                color: $grey-dark;
            }
        }
    }
}

.masthead-course-subjects {
    @include font-size(1.6);
    display: inline-block;

    ul {
        display: inline;
        padding-left: 0;

        li {
            display: inline;

            &:before {
                content: none;
            }

            a {
                color: $white;
                border-bottom: 1px solid $white;

                &:hover,
                &:focus,
                &:active {
                    color: $white;
                    border-bottom: none;
                }
            }
        }
    }

}

// Generic masthead
.masthead-generic {
    .row {
        position: relative;
    }

    .breadcrumb {
        left: 0;
        position: absolute;
        top: 52px;

        @media (min-width: $screen-md-min) {
            top: 105px;
        }

        @media (min-width: $screen-lg-min) {
            top: 120px;
        }
    }

    &__header {
        color: $masthead-generic-header-color;
        padding-bottom: 30px;
        padding-top: 110px;

        @media (min-width: $screen-md-min) {
            padding-top: 230px;
        }

        h1 {
            @include make-md-column(9);
            margin-bottom: 20px;
            margin-top: 0;
            padding-left: 15px !important;
        }

        p {
            @include make-md-column(9);
            margin-bottom: 0;
            padding-left: 15px !important;

            a {
                color: $masthead-generic-header-color;
            }
        }
    }
}

// News article & event masthead
.masthead-news-event {
    .row {
        position: relative;
    }

    .breadcrumb {
        left: 0;
        position: absolute;
        top: 52px;

        @media (min-width: $screen-md-min) {
            top: 105px;
        }

        @media (min-width: $screen-lg-min) {
            top: 120px;
        }
    }

    &__header {
        color: $masthead-generic-header-color;
        padding-bottom: 30px;
        padding-top: 60px;

        @media (min-width: $screen-sm-min) {
            padding-top: 60px;
        }

        @media (min-width: $screen-md-min) {
            padding-top: 110px;
        }

        h1 {
            @include make-xs-column(12);
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;
            margin-top: 0;

            @media (min-width: $screen-sm-min) {
                @include make-sm-column(10);
                margin-bottom: 20px;
            }
        }

        &-details {
            @extend .pull-right;
            width: 100%;
            border-bottom: 6px solid $brand-secondary;
            margin-bottom: 30px;
            padding-left: 15px;
            padding-right: 15px;

            @media (min-width: $screen-sm-min) {
                padding-right: 0;
            }
        }
    }

    &-date {
        @include font-size(1.6);
        float: right;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    &-category {
        @extend .text-uppercase;
        @include font-karla-bold();
        float: right;
        background-color: $brand-secondary;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

// View prospectus feature link
.masthead-feature-prospectus {
    display: inline-block;
    max-width: 145px;
    position: relative;
    z-index: 200;

    @media (min-width: $screen-sm-min) {
        max-width: 185px;
    }

    &__link {
        @include font-karla-bold();
        @include font-size(1.2);
        @extend .text-left;
        @extend .text-uppercase;
        background-color: $masthead-feature-prospectus-bg;
        color: $masthead-feature-prospectus-color;
        display: inline-block;
        line-height: 120%;
        padding-bottom: 10px;
        padding-left: 55px;
        padding-right: 15px;
        padding-top: 10px;
        position: relative;
        text-decoration: none;

        @media (min-width: $screen-sm-min) {
            @include font-size(1.4);
            padding-bottom: 15px;
            padding-left: 75px;
            padding-right: 20px;
            padding-top: 15px;
        }

        &:hover,
        &:focus {
            background: $masthead-feature-prospectus-hover-bg;
            color: $masthead-feature-prospectus-color;
        }

        span {
            @include font-size(4);
            position: absolute;
            top: 5px;
            left: 10px;

            @media (min-width: $screen-sm-min) {
                @include font-size(5);
            }
        }
    }
}

// Next open day feature
.masthead-feature-open-day {
    @extend .text-left;
    display: inline-block;
    margin-top: 35px;
    position: relative;
    z-index: 200;
    width: 300px;

    @media (min-width: $screen-sm-min) {
        margin-top: 0;
    }

    &__title {
        @include font-karla-bold();
        @include font-size(1.2);
        @extend .text-uppercase;
        background-color: $masthead-feature-open-day-title-bg;
        color: white;
        line-height: 100%;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        position: absolute;
        top: -32px;
    }

    &__info {
        background-color: $masthead-feature-open-day-info-bg;
        padding: 15px;
    }

    &__summary {
        @include font-karla-bold();
        @include font-size(1.2);
        line-height: 120%;
        margin-bottom: 10px;
        margin-top: 0;
    }

    &__date {
        @include font-size(1.3);
        @extend .text-uppercase;
        background-color: $masthead-feature-open-day-date-bg;
        color: $masthead-feature-open-day-date-color;
        display: inline-block;
        line-height: 100%;
        margin-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        min-width: 90px;
        text-align: center;
    }

    &__book {
        @include font-karla-bold();
        @include font-size(1.3);
        @extend .text-uppercase;
        color: $masthead-feature-open-day-book-color;
        padding-right: 15px;
        position: relative;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $masthead-feature-open-day-book-color;
        }

        &:before {
            @include transform(translateY(-50%));
            @include triangle-right(black, 10px, 5px);
            content: "";
            position: absolute;
            right: 0;
            top: 20%;
        }
    }

    &__link {
        @include font-karla-bold();
        @include font-size(1.2);
        @extend .pull-left;
        @extend .text-center;
        @extend .text-uppercase;
        background-color: $masthead-feature-open-day-link-bg;
        color: $masthead-feature-open-day-link-color;
        line-height: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        text-decoration: none;
        width: 49.8%;

        &:nth-of-type(2) {
            @extend .pull-right;
        }

        &:hover,
        &:focus {
            background: $masthead-feature-open-day-link-hover-bg;
            color: $masthead-feature-open-day-link-hover-color;
        }
    }
}

// Generic feature
.masthead-feature-generic {
    @extend .text-left;
    display: inline-block;
    margin-top: 62px;
    max-width: 290px;
    position: relative;
    z-index: 200;

    @media (min-width: $screen-sm-min) {
        margin-top: 0;
    }

    &__title {
        @include font-karla-bold();
        @include font-size(1.2);
        @extend .text-uppercase;
        background-color: $masthead-feature-generic-title-bg;
        color: white;
        line-height: 100%;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        position: absolute;
        top: -32px;
    }

    &__info {
        background-color: $masthead-feature-generic-info-bg;
        padding: 15px 15px 25px 15px;
    }

    &__summary {
        @include font-karla-bold();
        @include font-size(1.6);
        line-height: 130%;
        margin-bottom: 0;
        margin-top: 0;
    }

    &__link {
        bottom: -30px;
        position: absolute;
        right: 15px;

        @media (min-width: $screen-sm-min) {
            bottom: -16px;
        }
    }
}

//Student Hub masthead
.masthead-index-cs {
    @extend .masthead-index;
    background-color: $uow-magenta-masthead;
    margin-top: 15px; //was -5px;

    @media (min-width: $screen-md-min) {
        margin-top: 115px;
    }

    @media (min-width: $screen-lg-min) {
        padding-bottom: 180px;
        margin-bottom: 30px;
    }

    &:before {
        background-color: transparent;
        content: "";
        height: 100%;
        position: absolute;
        left: 0;
        opacity: 0.4;
        top: 0;
        width: 100%;
        z-index: 10;
    }

    .row {
        position: relative;
    }

    .breadcrumb {
        left: 0;
        position: absolute;
        top: 52px;

        @media (min-width: $screen-md-min) {
            top: 105px;
        }

        @media (min-width: $screen-lg-min) {
            top: 120px;
        }
    }

    &__header {
        @extend .masthead-index__header;
        @extend .text-center;
        position: relative;
        margin-bottom: 30px;
        padding-top: 80px; //For white clearing banner, this changes to 120px

        // Below is extra padding needed for white clearing banner on student hub
        // @media (min-width: $screen-sm-min) {
        //     padding-top: 80px;
        // }
        @media (min-width: $screen-lg-min) {
            padding-top: 180px;
        }

        h1 {
            // @include make-sm-column(12);
            float: none !important;
        }

        p {
            @include make-xs-column(12);
            margin-top: 30px;
            margin-bottom: 0;

            a {
                border-bottom: 1px solid $white;
            }
        }
    }

    &__notification {
        @include font-karla-bold();
        @extend .panel-wide;
        padding-top: 8px;
        padding-bottom: 20px;
        display: inline-block;
        background-color: $brand-secondary;
        z-index: 200;

        &--alert {
            vertical-align: middle;
            display: inline-block;
            padding-left: 0;
            padding-top: 10px;
            float: right;

            @media (min-width: $screen-xs-min) {
                padding-left: 5px;
                padding-top: 10px;
                padding-right: 10px;
                position: relative;
                top: 0;
                left: 0;
                float: right;
            }

            @media (min-width: $screen-sm-min) {
                padding-left: 0;
            }

            @media (min-width: $screen-lg-min) {
                padding-top: 20px;
            }

            span {
                @include font-size(3.5);
                @extend .text-uppercase;
                color: $brand-secondary;
                float: left;
                background-color: $grey-dark;
                border-radius: 50%;
                padding: 5px 25px;
                margin: 0 auto;

                @media (min-width: $screen-sm-min) {
                    @include font-size(4);
                    padding: 1px 24px;
                }
            }
        }

        &--title {
            @include font-size(1.7);
            @extend .text-uppercase;
            display: inline-block;
            padding-top: 5px;
            padding-left: 15px;
            padding-right: 0;

            @media (min-width: $screen-xs-min) {
                padding-left: 0;
                padding-right: 5px;
            }

            @media (min-width: $screen-sm-min) {
                @include font-size(2.2);
                padding-top: 5px;
            }

            @media (min-width: $screen-lg-min) {
                padding-left: 0;
                padding-top: 15px;
            }
        }

        &--text {
            @extend .masthead-index-cs__notification--title;
            text-transform: none;

            p {
                @include font-size(1.7);
            }
        }
    }
}

.masthead-buttons {
    @include clearfix();
    margin: 0 auto;
    text-align: center;
}

// Views page masthead
.masthead-views {
    @include image-overlay();
    background-color: $masthead-bg;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    margin-top: -30px;
    margin-bottom: 60px;
    display: block;

    @media (min-width: $screen-sm-min) {
        margin-top: -100px;
        clip-path: inset(0 0 60px 0);
        margin-bottom: -60px;
    }

    @media (min-width: $screen-md-min) {
        clip-path: inset(0 0 100px 0);
        margin-bottom: -100px;
    }

    @media (min-width: $screen-lg-min) {
        clip-path: inset(0 0 270px 0);
        margin-bottom: -270px;
    }

    &__contain {
        position: absolute;
        top: 55px;
        width: 100%;

        @media (min-width: $screen-md-min) {
            top: 50px;
        }
    }

    &__header {
        @extend .text-center;
        color: $masthead-course-header-color;
        display: inline-block;
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        z-index: 20;

        @media (min-width: $screen-xs-min) {
            top: 80px;
        }

        @media (min-width: $screen-sm-min) {
            top: 80px;
        }

        @media (min-width: $screen-md-min) {
            top: 150px;
        }

        @media (min-width: $screen-md-min) {
            top: 230px;
        }

        h1 {
            @include font-size(1.8);
            @include make-xs-column(12);
            @extend .text-uppercase;
            margin-bottom: 20px;
            margin-top: 0;
            z-index: 300;

            @media (min-width: $screen-xs-min) {
                @include font-size(2.8);
            }

            @media (min-width: $screen-sm-min) {
                @include font-size(4.8);
            }

            @media (min-width: $screen-md-min) {
                @include font-size(6);
            }

            span {
                @include font-futura-book();
                @extend .text-capitalize;
                display: inline-block;
            }
        }

        p {
            @include make-xs-column(12);
            margin-bottom: 0;
            margin-top: -15px;

            a {
                border-bottom-color: $masthead-course-header-color;
                color: $masthead-course-header-color;
            }
        }
    }

    &__feature-wrap {
        position: relative;
        top: 100px;
        right: 30px;
        @extend .text-center;
        width: 100%;
        display: none;

        @media (min-width: $screen-xs-min) {
            top: 170px;
        }

        @media (min-width: $screen-sm-min) {
            display: block;
            top: 75px;
            left: -235px;
            z-index: 100;
        }

        @media (min-width: $screen-md-min) {
            right: 0px;
            top: -95px;
        }

        >div {
            @media (min-width: $screen-sm-min) {
                margin-left: 10px;
                margin-right: 10px;
                vertical-align: top;
            }
        }
    }
}

.masthead-vacancies {
    @extend .masthead-generic;

    &__header {
        color: $masthead-generic-header-color;
        padding-bottom: 30px;
        padding-top: 150px;

        h1 {
            @include make-md-column(12);
            margin-bottom: 15px;
        }
    }
}
