//
// Cookie compliance pop up
// --------------------------------------------------

// EU Cookie Compliance overrides

.eu-cookie-compliance-message {
    h2 {
        @include font-size(1.8);
        font-weight: normal;
    }

    p {
        @include font-size(1.6);
        font-weight: normal;
    }
}

.eu-cookie-compliance-buttons {
    padding-top: 20px;
    @media (min-width: $screen-sm-min) {
        margin-top: 10px;
        padding-top: 0;
    }
}

.eu-cookie-compliance-more-button {
    @include font-size(1.6);
    text-decoration: none;
    border-bottom: 1px solid $white;
}

.eu-cookie-compliance-secondary-button {
    @include font-size(1.6);
    margin-top: -10px;
    margin-left: 20px;
    border-bottom: 1px solid $white;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $screen-sm-min) {
        margin-top: 0px;
    }
}