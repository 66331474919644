/* -----------------------------------
 * SASS for social media
 */
// -------------------------

// Social media sticky bar

.social-bar {
  @include font-karla-bold();
  background-color: $brand-secondary;
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  float: right;
  z-index: 300;

  @media (min-width: $screen-sm-min) {
    position: fixed;
    top: 45%;
    right: 0;
    transform: translateY(-50%);
    display: block;
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;

    li {
      margin-top: 0;
      margin-bottom: 0;
      display: inline;

      &:before {
        display: none;
      }

      a {
        @include font-size(2.5);
        color: $grey-dark;
        display: inline-block;
        text-align: center;
        // padding: 8px;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: all 0.3s ease;
        border-bottom: none;

        @media (min-width: $screen-sm-min) {
          display: block;
        }

        [class^="icon-"] {
          @include font-size(2.5);
          color: $grey-dark;
          left: 0;
          position: relative;
          top: 0;
          vertical-align: top;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          padding-bottom: 5px;

          // Hover
          &:hover,
          &:focus,
          &.focus {
            background-color: $grey-dark;
            color: $brand-secondary !important;
          }

          @media (min-width: $screen-sm-min) {
            @include font-size(3.5);
          }
        }
      }
    }
  }

  &__header {
    @include font-karla-bold();
    @include font-size(1.5);
    display: none;
    padding-top: 10px;
    padding-bottom: 15px;

    @media (min-width: $screen-sm-min) {
      @include font-size(2.5);
      text-align: center;
      border-bottom: 3px solid $brand-secondary;
    }

    &-title {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}


//SOCIAL MEDIA CHANNELS ICON LIST
.social-list {
  @extend .text-center;
  display: block;
  margin: auto;
  top: 50%;
  left: 50%;

  a {
    color: $grey-dark;
  }

  &__social-set {
    padding: 15px 30px 15px 30px;
    display: inline-block;


    [class^="icon-"] {
      @include font-size(2.5);
      color: $grey-dark;
      display: inline-block;
      left: 0;
      position: relative;
      top: 0;
      vertical-align: top;

      &:hover {
        color: $brand-secondary;
      }

      @media (min-width: $screen-sm-min) {
        @include font-size(4);
        left: auto;
        top: auto;
      }

      @media (min-width: $screen-md-min) {
        @include font-size(5);
      }
    }

    &--label {
      color: $grey-dark;

      &:hover {
        color: $brand-primary;
      }

    }
  }
}

//Share button on Events page details pane
.pop-up {
  cursor: pointer;
}

.popup-share {
  background: #fff;
  border: 1px solid #d5d6d6;
  padding: 0;
  margin: 0;

  a[class^="icon-"],
  a[class*=" icon-"] {
    display: block;
    text-decoration: none;
    padding: 4px 10px;

    &:hover {
      background: $brand-secondary;
      text-decoration: none;
      color: $grey-dark;
    }

    span {
      margin-left: 5px;
    }
  }

  .icon-email {
    @extend .icon-envelope;

    span {
      margin-left: 5px;
    }
  }
}
