//
// Navbar
// --------------------------------------------------
.user-logged-in {

    .navbar-main {
        top: 100px;
    }

    .navbar-main-top {
        top: 130px;
    }

    .navbar-main--condensed {
        @media (min-width: $screen-md-min) {
            top: 70px;
        }
    }

    .navbar-main--condensed-top-banner {
        @media (min-width: $screen-md-min) {
            top: 100px;
        }
    }
}

#block-clearingnotificationblock {
    .contextual {
        top: 0;
        right: 80%;
    }
}

// Reset default styling
.navbar-nav {
    li {
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;

        &:before {
            display: none;
        }

        a {
            border-bottom: none;
        }
    }
}

.navbar-holder {
    @include clearfix();
    position: relative;
    display: block;

    @media (min-width: $screen-sm-min) {
        border: 1px dotted $white;
    }
}

#navbar {
    @include clearfix();
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
}

// Main westminster nav
.navbar-main {
    @include transition(top .7s);
    border: none;
    left: 0;
    margin-bottom: 0;
    top: 0;
    width: 100%;
    z-index: 300;

    @media (min-width: $screen-sm-min) {
        position: fixed;
    }

    // When top notification banner is enabled
    &-top {
        top: 0;

        @media (min-width: $screen-sm-min) {
            top: 30px;
        }
    }

    &--condensed {
        @media (min-width: $screen-md-min) {
            @include transition(top 1s);
            top: -30px;
        }
    }

    //For top banner js
    &--condensed-top-banner {
        @media (min-width: $screen-md-min) {
            @include transition(top 1s);
            top: -10px;
        }
    }

    //For clearing banner js
    &--condensed-clearing {
        @media (min-width: $screen-md-min) {
            top: 0px;
        }
    }

    .container {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        width: 100%;

        @media (min-width: $screen-md-min) {
            width: $container-desktop;
        }

        @media (min-width: $screen-lg-min) {
            width: $container-large-desktop;
        }
    }

    .navbar-header {
        background-color: $navbar-main-header-bg;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-md-min) {
            background-color: transparent;
            width: 162px;
        }

        @media (min-width: $screen-lg-min) {
            width: 195px;
        }

        // Show a small logo on mobile/tablet
        .navbar-brand {
            @include text-hide;
            background-image: url('/themes/uow_theme/images/navbar-westminster-logo.svg');
            background-position: 10px 15px;
            background-repeat: no-repeat;
            background-size: 130px;
            height: 57px;
            margin-left: 0 !important;
            padding: 0;
            width: 190px;

            @media (min-width: $screen-md-min) {
                background-color: $navbar-main-header-bg;
                background-image: url('../images/navbar-westminster-logo.svg');
                background-position: center center;
                background-size: 80%;
                height: 73px;
                margin-top: 30px;
                padding: 0;
                width: 162px;
            }

            @media (min-width: $screen-lg-min) {
                height: 88px;
                margin-top: 30px;
                width: 195px;
            }

            &:focus {
                @include focus();
            }
        }

        .navbar-toggle {
            border-radius: 0;
            border: none;
            height: 30px;
            margin: 5px 10px 0 0;
            padding: 0;
            position: relative;
            width: 30px;
            z-index: 500;

            .icon-burger {
                @include font-size(2.2);
                display: block;
                padding-left: 3px;
                padding-top: 2px;

                &:before {
                    content: "\e605";
                }
            }

            &.collapsed {
                .icon-burger {
                    @include font-size(3);
                    padding-left: 0;
                    padding-top: 0;

                    &:before {
                        content: "\e602";
                    }
                }
            }
        }
    }

    .navbar-collapse {
        background-color: $grey-dark;
        border: none;
        margin-left: 0px;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        margin-right: 0;

        @media (min-width: $screen-md-min) {
            background-color: transparent;
            margin-left: 180px;
            padding-bottom: 0;
        }

        // On mobile/tablet top-level we need to add a a bottom margin for the top-right nav to show
        .navbar-nav__top--right+.navbar-nav__mid {
            margin-bottom: 40px;

            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
            }
        }

        // On mobile/tablet 2nd-level we need to add a a bottom margin for the top-left & top-right nav to show
        .navbar-nav__top--left+.navbar-nav__top--right+.navbar-nav__mid {
            margin-bottom: 120px;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 80px;
            }

            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
            }
        }
    }

    .navbar-nav {
        margin: 0;

        a {
            @include font-karla-bold();
            @extend .text-uppercase;
            text-decoration: none;
        }

        >li {
            >a {
                padding-bottom: 0;
                padding-top: 0;
            }

            &:before {
                display: none;
            }
        }

        // Bootstrap nav doesn't have a top nav so this is custom
        &__top {
            margin-right: 0;

            a {
                @include font-size(1.1);

                @media (min-width: $screen-md-min) {
                    @include font-size(1.1);
                }

                @media (min-width: $screen-lg-min) {
                    @include font-size(1.2); //font-size: 12px
                    background-color: $white;
                    @include opacity(0.9);
                }
            }

            >li {
                display: inline-block;

                >a {
                    color: $white;
                    display: inline-block;
                    line-height: 40px;
                    padding-left: 10px;
                    padding-right: 10px;

                    @media (min-width: $screen-md-min) {
                        height: 30px;
                        line-height: 30px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    &:hover,
                    &:focus {
                        background-color: $grey-dark;

                        @media (min-width: $screen-md-min) {
                            background-color: $grey-dark;
                            color: $body-bg;
                        }
                    }
                }

                &.active {
                    >a {
                        background-color: $brand-secondary;
                        vertical-align: middle;
                        padding-top: 1px;
                        height: 100%;

                        @media (min-width: $screen-md-min) {
                            padding-top: 0;
                            padding-bottom: 0;
                        }

                        &:hover,
                        &:focus {
                            background-color: $grey-dark;
                            color: $body-bg;

                            @media (min-width: $screen-md-min) {
                                color: $body-bg;
                            }
                        }
                    }
                }
            }

            &--left {
                background-color: $brand-primary;
                left: 0;
                width: 100%;
                z-index: 100;
                margin-top: 30px;
                line-height: 30px;
                padding-right: 20px;

                @media (min-width: $screen-sm-min) {
                    height: auto;
                    line-height: 24px;
                }

                @media (min-width: $screen-md-min) {
                    @include opacity(0.8);
                    float: left !important;
                    background-color: $white;
                    text-align: left;
                    bottom: 0;
                    position: relative;
                    width: auto;
                    margin-top: 0;
                    padding-right: 0;
                }

                // On mobile/tablet need to make the default link colour white as we are using the teal background
                >li {
                    margin-right: 5px;

                    @media (min-width: $screen-md-min) {
                        margin-right: 0;
                    }

                    >a {
                        @include font-size(1.4);
                        color: $white;

                        @media (min-width: $screen-md-min) {
                            @include font-size(1.2);
                            color: $grey-dark;
                        }
                    }

                    &.active {
                        >a {
                            color: $grey-dark;
                        }
                    }
                }
            }

            &--right {
                top: 0;
                position: absolute;
                right: 30px;
                height: 25px;
                background-color: $grey-dark;
                width: 100%;
                text-align: right;
                z-index: 300;

                @media (min-width: $screen-md-min) {
                    float: right !important;
                    right: 0;
                    width: auto;
                    height: auto;
                    position: relative;
                    background-color: $white;
                    @include opacity(0.8);
                }

                // On mobile/tablet need to make the default link colour white as we are using the grey-dark background
                >li {
                    >a {
                        color: $white;

                        @media (min-width: $screen-md-min) {
                            color: $grey-dark;
                        }
                    }
                }
            }
        }

        &__mid {
            @include transition(visibility 0.7s,
                opacity 0.7s);
            background-color: $white;
            font-size: inherit;
            padding-bottom: 10px;
            position: relative;

            @media (min-width: $screen-md-min) {
                line-height: 9px;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
                width: 727px;
            }

            @media (min-width: $screen-lg-min) {
                line-height: 14px;
                width: 877px;
            }

            // To overwrite the bootstrap font sizes we need to specify here (without using !important)
            a {
                @include font-size(1.4);

                @media (min-width: $screen-md-min) {
                    @include font-size(1.0);
                }

                @media (min-width: $screen-lg-min) {
                    @include font-size(1.2);
                }
            }

            >li {
                border-bottom: 1px solid $navbar-main-main-link-border-color;
                margin-left: 20px;

                &:last-of-type {
                    border-bottom: none;
                }

                @media (min-width: $screen-md-min) {
                    border: none;
                    display: table-cell;
                    float: none;
                    margin-left: 0;
                    vertical-align: middle;
                }

                // Top level active state
                &.active {
                    >a {
                        position: relative;
                        background-color: $brand-secondary;
                        height: 100%;
                        padding-bottom: 5px;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-top: 5px;
                        width: auto;

                        @media (min-width: $screen-md-min) {
                            height: 55px;
                            padding-bottom: 10px;
                            padding-left: 15px;
                            padding-right: 15px;
                            padding-top: 10px;
                            width: 100%;
                        }

                        span {
                            position: relative;
                        }
                    }
                }

                // Turn bootstrap click dropdown nav into a hover dropdown nav on desktop only
                &.dropdown {
                    @media (min-width: $screen-md-min) {
                        position: static;
                    }

                    .dropdown-menu {
                        position: relative;

                        @media (min-width: $screen-md-min) {
                            display: none;
                            position: absolute;
                        }
                    }

                    &:hover {
                        @media (min-width: $screen-md-min) {
                            .dropdown-menu {
                                display: block;
                            }

                            >a,
                            >a:hover,
                            >a:focus {
                                background-color: $navbar-main-main-link-hover-bg;
                                color: $navbar-main-main-link-hover-color;

                                span {
                                    &:before {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }

                // Top level links
                >a {
                    background-color: transparent;
                    color: $navbar-main-main-link-color;
                    line-height: 14px;
                    padding: 10px 0;
                    position: relative;
                    z-index: 20;

                    @media (min-width: $screen-md-min) {
                        display: inline-block;
                        height: 73px;
                        line-height: 12px;
                        padding: 0 15px;
                    }

                    @media (min-width: $screen-lg-min) {
                        height: 88px;
                        line-height: 15px;
                        padding: 15px;
                        vertical-align: top;
                    }

                    &:hover,
                    &:focus {
                        background-color: transparent;

                        @media (min-width: $screen-md-min) {
                            background-color: $navbar-main-main-link-hover-bg;
                            color: $navbar-main-main-link-hover-color;
                        }

                        &:before {
                            @extend .hide;
                        }
                    }

                    // Span added so the top level links can be vertically aligned
                    span {
                        display: inline-block;
                        position: relative;

                        @media (min-width: $screen-md-min) {
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                // Dropdown toggle added outside the link so the sub nav can be opened on mobile (hidden on desktop)
                .dropdown-toggle {
                    @extend .hidden-md;
                    @extend .hidden-lg;
                    @include text-hide;
                    background-color: transparent;
                    border: none;
                    border-left: 1px solid $navbar-main-main-link-border-color;
                    line-height: 14px;
                    margin-top: 5px;
                    padding-bottom: 10px;
                    padding-top: 5px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 50px;
                    z-index: 100;

                    &:before {
                        @extend .text-center;
                        @include triangle-bottom($text-color,
                            5px,
                            10px);
                        content: "";
                        position: absolute;
                        right: 20px;
                        top: 10px;
                    }
                }

                // Open state
                &.open {
                    .dropdown-toggle {
                        &:before {
                            @include triangle-top($text-color,
                                5px,
                                10px);
                        }
                    }
                }
            }

            .dropdown-menu {
                background-color: $navbar-main-main-dropdown-bg;
                border: none;
                border-radius: 0;
                box-shadow: none;
                padding-bottom: 10px;
                padding-top: 0;

                @media (min-width: $screen-md-min) {
                    padding-bottom: 15px;
                    padding-top: 15px;
                    width: 100%;
                }

                .dropdown-list {
                    column-count: 2;
                    column-gap: 10px;
                    padding-left: 10px;

                    @media (min-width: $screen-md-min) {
                        float: left;
                        margin-left: 15px;
                        margin-right: 30px;
                        padding-left: 0;
                        max-height: 525px;
                        column-gap: 80px;
                    }

                    >li {
                        >a {
                            color: $navbar-main-main-dropdown-link-color;
                            display: inline-block;
                            line-height: 14px;
                            padding-bottom: 10px;
                            padding-top: 10px;

                            @media (min-width: $screen-md-min) {
                                color: $navbar-main-main-dropdown-link-color;
                                line-height: 17px;
                                padding-bottom: 5px;
                                padding-top: 5px;
                            }

                            @media (min-width: $screen-lg-min) {
                                line-height: 19px;
                            }

                            &:hover,
                            &:focus {
                                background-color: transparent;

                                @media (min-width: $screen-md-min) {
                                    color: $navbar-main-main-dropdown-link-hover-color;
                                }
                            }
                        }
                    }
                }

                // Featured navigation links (including image)
                .dropdown-featured {
                    @extend .hidden-xs;
                    @extend .hidden-sm;

                    @media (min-width: $screen-md-min) {
                        float: left;
                        margin-right: 15px;
                        padding-left: 0;
                        width: 260px;
                    }

                    @media (min-width: $screen-lg-min) {
                        width: 300px;
                    }

                    >li {
                        float: left;

                        @media (min-width: $screen-md-min) {
                            margin-right: 15px;
                            width: 115px;
                        }

                        @media (min-width: $screen-lg-min) {
                            width: 135px;
                        }

                        &:nth-of-type(n+3) {
                            @media (min-width: $screen-md-min) {
                                margin-top: 10px;
                            }
                        }

                        >a {
                            color: $navbar-main-main-dropdown-link-color;
                            display: inline-block;

                            @media (min-width: $screen-md-min) {
                                line-height: 17px;
                                padding-top: 5px;
                            }

                            @media (min-width: $screen-lg-min) {
                                line-height: 19px;
                            }

                            &:hover,
                            &:focus {
                                @media (min-width: $screen-md-min) {
                                    color: $navbar-main-main-dropdown-link-hover-color;
                                }
                            }

                            img {
                                border: solid 1px $navbar-main-main-dropdown-link-color;
                                margin-top: 5px;
                                width: 100%;
                            }
                        }
                    }
                }

                // Form select navigation
                .dropdown-select {
                    @extend .hidden-xs;
                    @extend .hidden-sm;

                    @media (min-width: $screen-md-min) {
                        margin-top: 27px;
                        float: left;
                    }

                    @media (min-width: $screen-lg-min) {
                        margin-top: 29px;
                    }
                }
            }
        }

        &__cs {
            @media (min-width: $screen-md-min) {
                top: 30px;
                position: absolute;
                left: 0;
            }
        }
    }

    .search-container {
        position: absolute;
        right: 50px;
        top: 10px;
        height: 45px;

        @media (min-width: $screen-sm-min) {
            width: 560px;
        }

        @media (min-width: $screen-md-min) {
            background-color: $white;
            top: 0;
            margin-top: 30px;
            right: 0;
            width: 808px;
            height: 73px;
        }

        @media (min-width: $screen-lg-min) {
            width: 975px;
            height: 88px;
        }
    }

    // Main site and current students search
    .searchbox,
    .cs-searchbox {
        @include transition(width 0.7s);
        position: relative;
        min-width: 54px;
        width: 0%;
        float: right;
        overflow: hidden;
        z-index: 1000;
        height: 45px;

        @media (min-width: $screen-md-min) {
            min-width: 90px;
            height: 73px;
        }

        @media (min-width: $screen-lg-min) {
            height: 88px;
        }

        &__input {
            @include font-karla-regular();
            @include font-size(1.3);
            top: 0px;
            right: 0;
            background-color: $white;
            border: none;
            border-radius: 0;
            box-shadow: none;
            height: 40px;
            position: relative;
            width: 70%;
            padding-left: 5px;
            border-bottom: 1px solid $grey-dark;
            -webkit-appearance: none;

            &:focus {
                border-color: $white;
                border-bottom: 4px solid $brand-secondary !important;
                outline: 1px solid $white;
                outline-offset: 1px;
            }

            @media (min-width: $screen-xxs-min) {
                right: 2px;
                width: 76%;
            }

            @media (min-width: $screen-xs-min) {
                right: -10px;
                width: 73%;
            }

            @media (min-width: $screen-sm-min) {
                @include font-size(1.6);
                right: 0;
                width: 650px;
                width: 85%;
            }

            @media (min-width: $screen-md-min) {
                @include font-size(3.0);
                right: 0px;
                height: 45px;
                margin-top: 14px;
                width: 695px;
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(4);
                height: 60px;
                margin-top: 14px;
                width: 860px;
            }
        }

        &--close {
            @include font-size(1.5);
            position: relative;
            top: 10px;
            outline: none;
            float: right;
            right: 80px;

            &:focus {
                // Bespoke mixin
                @include focus();
            }

            @media (min-width: $screen-xxs-min) {
                right: 85px;
            }

            @media (min-width: $screen-xs-min) {
                right: 80px;
            }

            @media (min-width: $screen-sm-min) {
                @include font-size(1.8);
                position: absolute;
                top: 10px;
                right: 85px;
            }

            @media (min-width: $screen-md-min) {
                @include font-size(2.4);
                top: 20px;
                right: 115px;
            }

            @media (min-width: $screen-lg-min) {
                top: 30px;
                right: 115px;
            }
        }

        &--extend {
            @include clearfix();
            position: relative;
            display: block;
        }

        &__icon {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            border: 0;
            outline: 0;
            line-height: 45px;
            text-align: center;
            cursor: pointer;
            color: $grey-dark;
            background: $white;
            z-index: 1001;
            width: 55px;
            height: 50px;

            &:focus {
                @include focus();
            }

            @media (min-width: $screen-iphone) {
                top: -15px;
                width: 60px;
                height: 60px;
                padding-top: 8px;
            }

            @media (min-width: $screen-md-min) {
                top: 0;
                height: 73px;
                width: 90px;
                padding-top: 8px;
            }

            @media (min-width: $screen-lg-min) {
                padding-top: 15px;
                padding-bottom: 5px;
                height: 88px !important;
                padding-left: 20px;
                padding-right: 20px;
                right: 0px;
            }
        }

        .icon-search {
            @include font-size(2.2);
            position: absolute;
            top: -5px;
            right: 15px;
            color: $grey-dark;

            &:focus {
                @include focus();
            }

            @media (min-width: $screen-iphone) {
                top: 10px;
                right: 20px;
            }

            @media (min-width: $screen-md-min) {
                @include font-size(2.5);
                right: 30px;
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(3.5);
                top: 15px;
            }
        }

        &__text {
            @include font-size(1.1);
            @include font-karla-bold;
            @extend .text-uppercase;
            display: block;
            margin-top: 5px;
            height: 30px;

            @media (min-width: $screen-iphone) {
                margin-top: 20px;
            }

            @media (min-width: $screen-sm-min) {
                margin-top: 15px;
            }

            @media (min-width: $screen-md-min) {
                @include font-size(1.2);
                margin-top: 8px;
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(1.2);
                margin-top: 30px;
            }
        }

        &--submit {
            @extend .searchbox__icon;
            background-color: $brand-secondary;
            z-index: 900;

            &:focus {
                @include focus();
            }
        }

        &--open {
            background-color: white;
            width: 300px;
            right: -40px;

            @media (min-width: $screen-xxs-min) {
                width: 340px;
            }

            @media (min-width: $screen-iphone-min) {
                width: 355px;
            }

            @media (min-width: $screen-xs-min) {
                width: 320px;
            }

            @media (min-width: $screen-sm-min) {
                width: 100%;
                right: 0;
                height: 45px;
            }

            @media (min-width: $screen-md-min) {
                width: 100%;
                height: 73px;
            }

            @media (min-width: $screen-lg-min) {
                width: 100%;
                height: 88px;
            }
        }
    }
}

.current-students-nav {

    //  display: none;
    //  border: 1px dashed red;
    @media (min-width: $screen-md-min) {
        display: inline-block;
        @include clearfix();
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-color: $white;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 118px;
        z-index: 250;
    }

    @media (min-width: $screen-lg-min) {
        //    margin-top: -30px;
    }
}

// Navbar for Vacancies site
.nav-vacancies {
    @include clearfix();
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    position: fixed;
    background-color: $white;
    background-image: url('../images/navbar-westminster-logo.svg');
    background-position: 10px 25px;
    background-repeat: no-repeat;
    background-size: 130px;
    height: 88px;
    vertical-align: middle;
    z-index: 300;

    @media (min-width: $screen-sm-min) {
        width: 750px;
    }

    @media (min-width: $screen-md-min) {
        width: 970px;

        background-color: $navbar-main-header-bg;
        background-image: url('../images/navbar-westminster-logo.svg');
        background-position: 10px 25px;
        background-repeat: no-repeat;
        background-size: 15%;
    }

    @media (min-width: $screen-lg-min) {
        width: 1170px;
    }

    .navbar-brand {
        @include text-hide();
        height: 88px;
        width: 130px;
        //  padding: 10px 15px;

        @media (min-width: $screen-md-min) {
            padding: 10px 30px;
            margin-right: 60px;
        }
    }

    ul {
        display: inline;

        li {
            @include font-futura-bold();
            @include font-size(2.5);
            padding-top: 10px;
            text-transform: uppercase;
            margin-left: 145px;
            line-height: 1;

            @media (min-width: $screen-md-min) {
                @include font-size(3.5);
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(4);
            }

            &:before {
                border-color: transparent;
                border: none;
            }

        }
    }
}