/* -----------------------------------
 * SASS for footer styles
 */

// -------------------------
#block-footerright-2 {
    @media (min-width: $screen-md-min) {
        float: right;
    }
}

.footer {
    background-color: $footer-bg;
    margin-top: -1px;
    padding: 0 0 20px 0;
    width: 100%;

    @media (min-width: $screen-sm-min) {
        padding: 40px 0;
    }

    &__buttons {
        @include make-xs-column(12);
        @extend .text-center;
        position: relative;

        @media (min-width: $screen-sm-min) {
            text-align: right;
        }

        a {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 20px;

            @media (min-width: $screen-sm-min) {
                margin-left: 20px;
                margin-right: 0;
                margin-top: 0;
            }
        }
    }

    &__right {
        @include clearfix();
        padding: 0;
        clear: both;
        margin-bottom: 60px;

        @media (min-width: $screen-md-min) {
            margin-left: 30px;
            padding: 30px 0;
            float: left;
            display: block;
            clear: none;
        }

        @media (min-width: $screen-lg-min) {
            padding-top: 30px;
            margin-left: 90px;
            float: left;
            display: inline;
            margin-bottom: 0;
        }
    }

    &__content {
        margin-top: 30px;

        &+& {
            margin-left: 0;
        }

        @media (min-width: $screen-sm-min) {
            display: inline;
            float: left;
            margin-top: 5px;

            &+& {
                margin-left: 183px;
            }
        }

        @media (min-width: $screen-md-min) {
            &+& {
                margin-left: 45px;
            }
        }

        @media (min-width: $screen-lg-min) {
            &+& {
                margin-left: 90px;
            }
        }

        &-links {
            // Reset default styling
            margin-bottom: 0;
            margin-top: 10px;
            padding-left: 0;

            &-link {
                &--section {
                    @include font-size(1.3);
                    margin-bottom: 10px;
                    color: $brand-secondary;
                    @extend .text-uppercase;

                    @media (min-width: $screen-sm-min) {
                        @include font-size(1.4);
                    }

                    &:before {
                        border: none;
                    }
                }
            }

            &-item {
                // Reset default styling
                margin-bottom: 2px 0;

                &:before {
                    display: none;
                }

                a {
                    @include font-karla-bold;
                    @include font-size(1.4);
                    color: $footer-content-link-color;
                    border-bottom: none;
                    line-height: 120%;
                }
            }
        }

        &-text {
            @include font-size(1.4);
            color: $grey-light;

            a {
                @include font-karla-bold;
                color: $footer-content-link-color;

                &:hover,
                &:focus {
                    border-bottom: none;
                    text-decoration: underline;
                }
            }
        }
    }

    &__social {
        @extend .text-center;
        width: 100%;
        margin-top: 15px;
        clear: both;

        @media (min-width: $screen-sm-min) {
            text-align: right;
            margin-top: 0;
            padding-top: 0;
            margin-left: -30px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 0;
            padding-top: 0;
            float: right;
            margin-bottom: 30px;
        }

        &-title {
            @extend .text-uppercase;
            color: $footer-social-color;
            display: inline-block;
            margin-bottom: 10px;
            position: relative;
            top: -4px;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 0;
                top: -8px;
            }
        }

        &-links {
            display: inline-block;
            // Reset default styling
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 0;

            &-item {
                display: inline-block;
                // Reset default styling
                margin: 0 10px;

                @media (min-width: $screen-sm-min) {
                    margin-left: 20px;
                    margin-right: 0;
                }

                &:before {
                    display: none;
                }
            }

            &-link {
                @include font-size(2);
                color: $footer-social-color;

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                @media (min-width: $screen-sm-min) {
                    @include font-size(3);
                }
            }
        }
    }

    &__terms {
        margin-top: 30px;
        clear: both;

        @media (min-width: $screen-sm-min) {
            margin-top: 50px;
            //    margin-left: -30px;
        }

        &-info {
            @include font-size(1.2);
            @include make-xs-column(12);
            @extend .text-center;
            color: $footer-terms-color;
            line-height: 20px;

            @media (min-width: $screen-sm-min) {
                @include font-size(1.3);
                text-align: left;
                padding-left: 0;
            }

            @media (min-width: $screen-md-min) {
                @include make-lg-column(7);
                padding-left: 0;
            }
        }

        &-links {
            @extend .text-center;
            // Reset default styling
            margin-bottom: 0;
            padding-left: 0;
            padding-top: 20px;
            clear: both;

            @media (min-width: $screen-sm-min) {
                text-align: right;
            }

            @media (min-width: $screen-md-min) {
                margin-top: 20px;
            }

            @media (min-width: $screen-lg-min) {
                clear: none;
                margin-top: 0;
                padding-top: 0;
            }

            &-item {
                display: inline-block;
                // Reset default styling
                margin-bottom: 0;
                margin-left: 20px;
                margin-top: 0;

                &:before {
                    display: none;
                }
            }

            &-link {
                @include font-karla-bold;
                @include font-size(1.2);
                color: $footer-terms-color;

                @media (min-width: $screen-sm-min) {
                    @include font-size(1.3);
                }
            }
        }
    }
}

.footer-cs {
    background-color: $uow-magenta-med;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;

    @media (min-width: $screen-xs-min) {
        padding-top: 45px;
    }

    @media (min-width: $screen-sm-min) {
        margin-top: -40px;
        margin-bottom: 30px;
        padding-bottom: 15px;
    }

    @media (min-width: $screen-lg-min) {
        padding-top: 60px;
    }

    &__content {
        @include make-xs-column(12);
        @include make-sm-column(6);
        @include make-md-column(3);
        margin-bottom: 30px;

        @media (min-width: $screen-sm-min) {
            &:nth-child(2) {
                margin-bottom: 60px;
            }
        }

        &-links {
            // Reset default styling
            margin-bottom: 0;
            margin-top: 10px;
            padding-left: 0;

            &-link {
                &--section {
                    @include font-karla-bold();
                    @include font-size(1.3);
                    margin-bottom: 10px;
                    color: $white;
                    @extend .text-uppercase;

                    @media (min-width: $screen-sm-min) {
                        @include font-size(1.4);
                    }

                    &:before {
                        border: none;
                    }
                }
            }

            &-item {
                // Reset default styling
                margin-bottom: 2px 0;

                &:before {
                    display: none;
                }

                a {
                    @include font-karla-bold();
                    @include font-size(1.4);
                    color: $white;
                    border-bottom: none;
                    line-height: 120%;
                }
            }
        }

        &-text {
            @include font-size(1.3);
            color: $uow-magenta-lighter;

            @media (min-width: $screen-md-min) {
                @include font-size(1.4);
            }

            a {
                @include font-karla-bold();
                color: $footer-content-link-color;

                &:hover,
                &:focus {
                    background-color: $grey-dark;
                    text-decoration: underline;
                }
            }
        }
    }

    &__social {
        @extend .text-center;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 30px;
        clear: both;

        @media (min-width: $screen-sm-min) {
            text-align: right;
            margin-top: 50px;
            margin-left: -30px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: -20px;
            padding-top: 0;
            float: right;
        }

        &-title {
            @extend .text-uppercase;
            color: $footer-social-color;
            display: inline-block;
            margin-bottom: 10px;
            position: relative;
            top: -4px;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 0;
                top: -8px;
            }
        }

        &-links {
            display: inline-block;
            // Reset default styling
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 0;

            &-item {
                display: inline-block;
                // Reset default styling
                margin: 0 10px;

                @media (min-width: $screen-sm-min) {
                    margin-left: 20px;
                    margin-right: 0;
                }

                &:before {
                    display: none;
                }
            }

            &-link {
                @include font-size(2);
                color: $footer-social-color;

                @media (min-width: $screen-sm-min) {
                    @include font-size(3);
                }
            }
        }
    }

    &__terms {
        margin-top: 30px;
        clear: both;

        @media (min-width: $screen-sm-min) {
            margin-top: 50px;
            margin-left: -30px;
        }

        &-info {
            @include font-size(1.2);
            @include make-xs-column(12);
            @extend .text-center;
            color: $footer-terms-color;
            line-height: 20px;

            @media (min-width: $screen-sm-min) {
                @include font-size(1.3);
            }

            @media (min-width: $screen-md-min) {
                @include make-lg-column(7);
                text-align: left;
            }
        }

        &-links {
            @extend .text-center;
            // Reset default styling
            margin-bottom: 0;
            padding-left: 0;
            padding-top: 20px;
            clear: both;

            @media (min-width: $screen-sm-min) {
                text-align: right;
            }

            @media (min-width: $screen-md-min) {
                margin-top: 20px;
            }

            @media (min-width: $screen-lg-min) {
                clear: none;
                margin-top: 0;
                padding-top: 0;
            }

            &-item {
                display: inline-block;
                // Reset default styling
                margin-bottom: 0;
                margin-left: 20px;
                margin-top: 0;

                &:before {
                    display: none;
                }
            }

            &-link {
                @include font-karla-regular;
                @include font-size(1.2);
                color: $footer-terms-color;

                @media (min-width: $screen-sm-min) {
                    @include font-size(1.3);
                }
            }
        }
    }
}