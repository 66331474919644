//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
    @extend .center-block;
    border-radius: 0 !important;
    margin: 0;
    text-align: center;
    margin-top: 15px;

    @media (min-width: $screen-sm-min) {
        float: right !important;
    }

    >ul {
        padding-top: 15px;
        padding-left: 0px;
        padding-right: 0px;
        vertical-align: middle;

        @media (min-width: $screen-sm-min) {
            padding-top: 0;
        }

    }

    >li {
        @include font-size(1.4);
        display: inline-block;
        // Reset default styling
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;

        &:before {
            display: none;
        }

        >a,
        >span {
            color: $grey-dark !important;
            border: none;
            line-height: 100%;
            margin-left: 8px;
            padding-bottom: 3px;
            padding-left: 7px;
            padding-right: 7px;
            padding-top: 3px;
        }

        &:first-child {

            >a,
            >span {
                border-radius: 50%;
                text-decoration: none;
            }
        }

        &:last-child {

            >a,
            >span {
                border-radius: 0;
            }
        }
    }

    >li>a,
    >li>span {

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    >.active>a,
    >.active>span {

        &,
        &:hover,
        &:focus {
            border-radius: 50%;
            text-decoration: none;
        }
    }

    >.disabled {

        >span,
        >span:hover,
        >span:focus,
        >a,
        >a:hover,
        >a:focus {
            text-decoration: none;
        }
    }
}
