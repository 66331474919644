//
// Wells
// --------------------------------------------------

// Base class
.well {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 30px;
}

.well--gray {
    background-color: $well-gray-bg;
}

.well--white {
    background-color: $white;
}

.well--bordered {
    @include clearfix();
    border-top: 5px solid $brand-secondary;
    border-bottom: 5px solid $brand-secondary;
    padding: 15px;
}
