/* -----------------------------------
 * SASS for venues listings and node features
 */
// -------------------------

// News event-listing__title
.venues-listing {
  @extend .news-feature;
  @include clearfix();
  position: relative;
  height: auto;
  margin-bottom: 40px;

  @media (min-width: $screen-md-min) {
    height: 450px;
  }

  @media (min-width: $screen-lg-min) {
    height: 480px;
  }

  a {
    height: inherit;
    display: block;
  }

  &__image {
    display: block;
  }

  &__body {
    vertical-align: middle;
    border-top: 6px solid $brand-secondary;
  }

  &__title {
    @include make-md-column(12);
    padding-top: 25px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $screen-md-min) {
      padding-top: 10px;
    }

    h3 {
      margin-top: 5px;
    }

    h3.preview {
      margin-top: 5px;

      @media (min-width: $screen-md-min) {
        height: 60px;
      }
    }
  }

  &__location {
    @include font-size(1.4);
    @extend .text-uppercase;

    @media (min-width: $screen-md-min) {
      margin-top: 10px;
    }
  }

  &__category {
    @extend .news-feature__category;
    top: -40px;
    width: 75%;

    @media (min-width: $screen-xs-min) {
      top: -28px;
      width: 65%;
    }

    @media (min-width: $screen-sm-min) {
      top: -15px;
    }
  }

  .details-pane__result-set {
    margin-top: 10px;
    margin-right: 15px;

    [class^="icon-"] {
      @include font-size(2.8);
      margin-left: -5px;
    }
  }

  .details-pane__result-title {
    @include font-size (1.2);
  }

  .details-pane__result-value {
    @include font-size(1.6);
  }

  &__go {
    @extend .text-right;
    @include font-karla-bold();
    @include font-size(1.6);
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-right: 30px;
    width: 100%;
    text-transform: uppercase;

    @media (min-width: $screen-md-min) {
      position: absolute;
      bottom: 10px;
      right: 30px;
    }

    &:after {
      @include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 15px, 0px);
      //Arguments - $color, $heightwidth, $border-width, $transform, $right, $top
    }

    &:hover {
      &:after {
        @include after-arrow($brand-primary, 9px, 9px, 3px, rotate(45deg), 15px, 0);
      }
    }

    @media (min-width: $screen-md-min) {
      &:after {
        @include after-arrow($grey-dark, 9px, 9px, 3px, rotate(45deg), 15px, 0);
        //Arguments - $color, $heightwidth, $border-width, $transform, $right, $top
      }

      &:hover {
        &:after {
          @include after-arrow($brand-primary, 9px, 9px, 3px, rotate(45deg), 15px, 0);
        }
      }
    }
  }

  &__summer-accommodation {
    @extend .venues-listing;
    @include clearfix();
    height: auto;
    vertical-align: top;
    padding-bottom: 15px;

    a {
      height: inherit;
      display: block;
    }

    &--btn {
      padding-bottom: 15px;
      padding-bottom: 15px;
      float: right;

      a {

        &:hover,
        &:focus {
          border-bottom: none;
          color: $brand-primary;
        }
      }
    }
  }
}

a.venues-listing {
  height: auto;
}
