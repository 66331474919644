//
// Forms
// --------------------------------------------------
.user-login-form {
    margin-top: 80px;
}

// Normalize non-controls
//
// Restyle and baseline non-control form elements.
legend {
    @include font-karla-bold();
    @include font-size(2.1);
    border: none;
    line-height: 110%;
    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
        @include font-size(2.1);
    }

    @media (min-width: $screen-lg-min) {
        @include font-size(2.6);
    }
}

label {
    font-weight: normal;
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]
.form-control {
    @include font-size(1.4);
    border-color: $input-bg;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;

    // Placeholder text
    // Firefox
    &::-moz-placeholder {
        @include font-karla-regular();
    }

    &:-ms-input-placeholder {
        @include font-karla-regular();
    }

    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        @include font-karla-regular();
    }

    // Safari and Chrome
    // Disable box shadow on focus
    &:focus {
        box-shadow: none !important;
    }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.
.radio,
.checkbox {
    label {
        @include font-karla-regular();
        @include font-size(1.4);
        line-height: 145%;

        .icheckbox {
            margin-left: -20px;
            margin-right: 4px;
        }
    }
}

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.
.help-block {
    color: $form-help-color;
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.scss.
.form-inline {
    >.form-group {
        margin-right: 20px;

        >label {
            margin-right: 10px;
        }
    }
}

/*
 iPhone and iOS Form Input Zoom Fixes
 ------------------------------------
 iOS zooms on input but not always appropriate. Mainly for search.
 */

/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {

    select,
    textarea,
    input[type="search"],
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

    select,
    textarea,
    input[type="search"],
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

    select,
    textarea,
    input[type="search"],
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

    select,
    textarea,
    input[type="search"],
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}



/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: $gregfranko
 */


/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/


/* SelectBoxIt container */

.selectboxit-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
}


/* Styles that apply to all SelectBoxIt elements */

.selectboxit-container * {
    @include font-size(1.4);
    /* Prevents text selection */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    outline: none;
    white-space: nowrap;
    word-wrap: break-word;
}


/* Button */

.selectboxit-container .selectboxit {
    max-width: 265px !important;
    /* Width of the dropdown button */
    min-width: 160px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: block;
    position: relative;
}


/* Height and Vertical Alignment of Text */

// Dropdown too wide on open day pages
.selectboxit-container span,
.selectboxit-container .selectboxit-options a {
    @include font-karla-bold();
    /* Height of the drop down */
    line-height: 34px;
    /* Vertically positions the drop down text */
    display: block;
    text-transform: uppercase;
    padding-left: 10px;
    white-space: nowrap;
    word-wrap: break-word;
}


/* Focus pseudo selector */

.selectboxit-container .selectboxit:focus {
    outline: 0;
}


/* Disabled Mouse Interaction */

.selectboxit.selectboxit-disabled,
.selectboxit-options .selectboxit-disabled {
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: default;
}


/* Button Text */

.selectboxit-text {
    // text-indent: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.selectboxit .selectboxit-option-icon-container {
    display: none;
}


/* Options List */

.selectboxit-container .selectboxit-options {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 100%;
    /* Minimum Width of the dropdown list box options */
    *width: 100%;
    width: 310px;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    overflow-x: auto;
    overflow-y: auto;
    cursor: pointer;
    display: none;
    z-index: 9999999999999;
    text-align: left;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-left: -13px;

    @media (min-width: $screen-sm-min) {
        *width: 100% !important;
    }
}


/* Individual options */

.selectboxit-option {
    padding: 0;
    clear: both;
    height: auto;

    &:before {
        display: none;
    }
}

.selectboxit-option .selectboxit-option-anchor {
    border-bottom: none;
    padding: 0 15px;
    text-decoration: none;
}


/* Individual Option Hover Action */

.selectboxit-option .selectboxit-option-anchor:hover {
    text-decoration: none;
    line-height: auto;
}


/* Individual Option Optgroup Header */

.selectboxit-option,
.selectboxit-optgroup-header {
    margin: 0;
    list-style-type: none;
}


/* Drop Down optgroup headers */

.selectboxit-optgroup-header {
    @include font-karla-bold();
}


/* Drop Down optgroup header hover psuedo class */

.selectboxit-optgroup-header:hover {
    cursor: default;
}


/* Drop Down down arrow container */

.selectboxit-arrow-container {
    /* Positions the down arrow */
    width: 15px;
    position: absolute;
    right: 10px;
}


/* Drop Down down arrow */

.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
    /* Horizontally centers the down arrow */
    margin: 15px auto 0 auto;
    position: absolute;
    top: 50%;
    right: -5px;
    left: 0;
}


/* Drop Down down arrow for jQueryUI and jQuery Mobile */

.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
    top: 30%;
}


/* Drop Down individual option icon positioning */

.selectboxit-option-icon-container {
    float: left;
}

.selectboxit-container .selectboxit-option-icon {
    margin: 0;
    padding: 0;
    vertical-align: middle;
}


/* Drop Down individual option icon positioning */

.selectboxit-option-icon-url {
    width: 18px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    float: left;
}

.selectboxit-rendering {
    display: inline-block !important;
    *display: inline !important;
    zoom: 1 !important;
    visibility: visible !important;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}


/*
  Primary Theme
  -------------
*/

.selectboxit-container.primary {
    .selectboxit-btn {
        background-color: $selectboxit-primary-btn-bg;
        color: $selectboxit-primary-btn-color;
    }

    .selectboxit-btn.selectboxit-enabled:hover,
    .selectboxit-btn.selectboxit-enabled:focus,
    .selectboxit-btn.selectboxit-enabled:active {
        color: $selectboxit-primary-btn-active-color;
        background-color: $selectboxit-primary-btn-active-bg;

        .selectboxit-arrow {
            border-top-color: $selectboxit-primary-btn-active-color;
        }
    }

    .selectboxit-btn.selectboxit-enabled:hover,
    .selectboxit-btn.selectboxit-enabled:focus {
        color: $selectboxit-primary-btn-active-color;

        .selectboxit-arrow {
            border-top-color: $selectboxit-primary-btn-active-color;
        }
    }

    .selectboxit-arrow {
        border-top: 5px solid $selectboxit-primary-arrow-color;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        width: 0;
        height: 0;
    }

    .selectboxit-list {
        background-color: $selectboxit-primary-options-bg;
    }

    .selectboxit-list .selectboxit-option-anchor {
        color: $selectboxit-primary-option-color;
    }

    .selectboxit-list>.selectboxit-focus>.selectboxit-option-anchor {
        color: $selectboxit-primary-option-color;
        background-color: $selectboxit-primary-option-active-bg;
    }

    .selectboxit-list>.selectboxit-disabled>.selectboxit-option-anchor {
        color: $selectboxit-primary-option-disabled-color;
    }
}


/*
  Secondary Theme
  -------------
*/

.selectboxit-container.secondary {
    .selectboxit-btn {
        background-color: $selectboxit-secondary-btn-bg;
        color: $selectboxit-secondary-btn-color;
        height: auto;
    }

    .selectboxit-btn.selectboxit-enabled:hover,
    .selectboxit-btn.selectboxit-enabled:focus,
    .selectboxit-btn.selectboxit-enabled:active {
        color: $selectboxit-secondary-btn-active-color;
        background-color: $selectboxit-secondary-btn-active-bg;
        height: auto;

        .selectboxit-arrow {
            border-top-color: $selectboxit-secondary-btn-active-color;
        }
    }

    .selectboxit-btn.selectboxit-enabled:hover,
    .selectboxit-btn.selectboxit-enabled:focus {
        color: $selectboxit-secondary-btn-active-color;

        .selectboxit-arrow {
            border-top-color: $selectboxit-secondary-btn-active-color;
        }
    }

    .selectboxit-arrow {
        border-top: 5px solid $selectboxit-secondary-arrow-color;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        width: 0;
        height: 0;
    }

    .selectboxit-list {
        background-color: $selectboxit-secondary-options-bg;
    }

    .selectboxit-list .selectboxit-option-anchor {
        color: $selectboxit-secondary-option-color;
    }

    .selectboxit-list>.selectboxit-focus>.selectboxit-option-anchor {
        color: $selectboxit-secondary-option-color;
        background-color: $selectboxit-secondary-option-active-bg;
    }

    .selectboxit-list>.selectboxit-disabled>.selectboxit-option-anchor {
        color: $selectboxit-secondary-option-disabled-color;
    }
}


/* iCheck plugin Westminster skin
----------------------------------- */

.icheckbox,
.iradio {
    display: inline-block;
    *display: inline;
    vertical-align: top;
    width: 18px;
    height: 18px;
    background: url(/resources/images/icheck.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox {
    background-position: 0 0;
}

.icheckbox.hover {
    background-position: -20px 0;
}

.icheckbox.checked {
    background-position: -40px 0;
}

.icheckbox.disabled {
    background-position: -60px 0;
    cursor: default;
}

.icheckbox.checked.disabled {
    background-position: -80px 0;
}

.iradio {
    background-position: -100px 0;
    margin-left: -20px;
    margin-right: 4px;
}

.iradio.hover {
    background-position: -120px 0;
}

.iradio.checked {
    background-position: -140px 0;
}

.iradio.disabled {
    background-position: -160px 0;
    cursor: default;
}

.iradio.checked.disabled {
    background-position: -180px 0;
}