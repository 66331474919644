﻿/* -----------------------------------
 * SASS Useful Utilities
 */

// Body override top margin
body {
  margin-top: 0;
}

/* 100% Viewport Height */

@mixin vh-100 () {
  @media (min-width: $screen-sm-min) {
    height: 100vh;
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    height: 960px !important;
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    height: 704px !important;
  }
}

// Label used for block filters for Views - used in News filtering
.filter-label {
  @include font-size(1.8);
  margin-right: 30px;
}

// Clear div Element
.clear {
  clear: both;
}

.content {
  @include clearfix();
  margin-bottom: 10px;

  @media (min-width: $screen-lg-min) {
    margin-bottom: 20px;
  }
}

//Email class so that long email addresses wrap on mobile
.email {
  word-wrap: break-word;
}

// Intro text for General content panels and other
.intro-text {
  text-align: center;
  margin-bottom: 30px;
}


/* Centre element horizontally */

.center-el {
  text-align: center;
}


/* Remove bootstrap gutter */

.no-gutter {
  margin-left: 0;
  margin-right: 0;

  >[class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}


/* Remove bootstrap padding from cols */

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}


/* Remove bootstrap padding from cols and add a smaller padding */

.less-padding {
  padding: 15px !important;
}


/* Remove bootstrap padding from cols and add a very small padding */

.min-padding {
  padding: 5px !important;
}


/* Vertical center */

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

//Paragraph types to clear both so elements don't overlap
.paragraph {
  clear: both;
}


/* Multicolumn Modifiers inside of Multicolumn Element  */

.layout__6col,
.layout__5col {
  @include make-xs-column(2);

  @media (max-width: $screen-xs-max) {
    @include make-xs-column(12);
  }

  @media (min-width: $screen-lg-min) {

    //Extra padding added for 6 logos in logo panel
    .panel-reason>.panel-reason__logo {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.layout__4col {
  @include make-xs-column(3);

  @media (max-width: $screen-xs-max) {
    @include make-xs-column(12);
  }
}

.layout__3col {
  @include make-xs-column(4);

  @media (max-width: $screen-xs-max) {
    @include make-xs-column(12);
  }
}

.layout__2col {
  @include make-xs-column(6);

  @media (max-width: $screen-xs-max) {
    @include make-xs-column(12);
  }
}

.layout__1col {
  @include make-xs-column(12);
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-sm-min) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

//Call to action div with no colour
.cta-transparent {
  clear: both;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;

  .btn,
  .btn-primary,
  .btn-sm {
    width: 100%;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }
}

// Divs used in jquery loadmore function
.contentBox {
  margin-bottom: 5px;
}

.moreContent {
  display: none;
}

#all-items {
  display: none;
}

// Force Safari to keep intended styling for phone numbers
a[href^=tel] {
  text-decoration: none;
  color: inherit;
  border-bottom: none;
}

// The js files that need to be disabled when user opts out of tracking via Cookies
//themes/uow_theme/js/gtmLocal.js|GTMScript
//https://www.googletagmanager.com/ns.html?id=GTM-PRR2RJ
//EU cookie compliance pop-up overrides
.sliding-popup-bottom {
  padding-bottom: 15px;
  clear: both;

  @media (min-width: $screen-lg-min) {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.align-vertically {
  padding-top: 25px;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

// Skip to main content
a.skip-link.visually-hidden {
  &:focus {
    @include font-karla-bold();
    @include font-size(1);
    visibility: visible;
    position: absolute !important;
    background: white;
    padding: 8px 10px 10px 10px;
    top: 1px;
    left: 2px;
    text-transform: uppercase;
    color: $grey-dark;
    height: 30px;
    width: auto;
    z-index: 400;
  }
}

// Make large images in legacy News and Events nodes responsive
.page-node-type-news-article,
.page-node-type-event {
  img {
    @extend .img-responsive;
  }
}
