.media {
    &-logos {
        display: inline-block;

        img {
            width: auto;
            padding-left: 30px;
            padding-right: 30px;

            @media (max-width: $screen-xxs-max) {
                padding-left: 20px;
                padding-right: 20px;
            }

            @media (min-width: $screen-xs-min) {
                padding-left: 10px;
                padding-right: 10px;
            }

            @media (min-width: $screen-md-min) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

//Override Drupal embedded-entity and Bootstrap media img styles
article.embedded-entity {
    .media-youtube-video {
        display: block;
        width: 100%;
        z-index: 200;
    }
}

//Overrides for media styles
.media-body {
    @media (min-width: $screen-md-min) {
        padding: 0 20px;
    }
}

// Video
.panel-video {
    &__title {
        border-bottom: 8px solid $panel-video-border-color;
        margin-bottom: 20px;
        padding-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 25px;
            padding-bottom: 25px;
        }

        @media (min-width: $screen-md-min) {
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
    }

    &__content {
        margin-bottom: 0;
    }
}

// Gallery
.panel-gallery {
    position: relative;

    &__title {
        border-bottom: 8px solid $panel-image-gallery-border-color;
        margin-bottom: 20px;
        padding-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 25px;
            padding-bottom: 25px;
        }

        @media (min-width: $screen-md-min) {
            margin-bottom: 30px;
            padding-bottom: 30px;
        }
    }

    &__items {
        margin-bottom: 20px;
        position: relative;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 25px;
        }

        @media (min-width: $screen-md-min) {
            margin-bottom: 30px;
        }

        >div {
            width: 100%;

            a {
                &:before {
                    background-image: linear-gradient(transparent, $panel-image-gallery-overlay-bg);
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    opacity: 0.4;
                    top: 0;
                    width: 100%;
                    z-index: 10;
                }

                &:hover {
                    color: inherit;
                }
            }
        }

        img {
            width: 100%;
        }
    }

    &__prev,
    &__next {
        @include text-hide;
        background-color: $panel-image-gallery-pagination-bg;
        cursor: pointer;
        height: 55px;
        margin-top: -24px;
        position: absolute;
        top: 50%;
        width: 55px;
        z-index: 200;

        @media (min-width: 346px) and (max-width: 396px) {
            margin-top: -24px;
        }

        @media (min-width: 397px) and (max-width: 479px) {
            margin-top: -23px;
        }

        @media (min-width: $screen-xs-min) {
            margin-top: -18px;
        }

        @media (min-width: $screen-sm-min) {
            margin-top: -19px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 19px;
            border-color: transparent;
        }
    }

    &__prev {
        left: 0;

        &:after {
            @include after-arrow($grey-dark, 16px, 16px, 6px, rotate(225deg), 25px, 33px);
        }

        //Arguments - $color, $heightwidth, $border-width, $rotation, $right, $top
        &:hover {
            &:after {
                transition: all 0.3s;
            }
        }
    }

    &__next {
        right: 0;

        &:after {
            @include after-arrow($grey-dark, 16px, 16px, 6px, rotate(45deg), 15px, 23px);
        }

        //Arguments - $color, $heightwidth, $border-width, $rotation, $right, $top
        &:hover {
            &:after {
                transition: all 0.3s;
            }
        }
    }

    &__caption {
        @extend .text-center;
        @extend .vertical-center;
        color: white;
        left: 15%;
        position: absolute;
        width: 70%;
        z-index: 20;

        @media (min-width: $screen-sm-min) {
            left: 0;
            width: 100%;
        }

        p {
            @extend .hidden-xs;
        }
    }

    &__content {
        margin-bottom: 0;
    }

    .cycle-pager {
        @extend .text-center;
        position: absolute;
        bottom: 10px;
        width: 100%;
        z-index: 200;

        span {
            @include text-hide;
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid $panel-image-gallery-pager-active-color;
            cursor: pointer;
            display: inline-block;
            height: 20px;
            margin: 0 5px;
            width: 20px;

            &:hover {
                background-color: $panel-image-gallery-pager-active-color;
            }

            &.cycle-pager-active {
                background-color: $panel-image-gallery-pager-active-color;
            }
        }
    }
}

// Image
.panel-image {
    img {
        display: inline-block;
        width: 100%;
    }

    div {
        @include font-size(1.2);
        background-color: $panel-image-caption-bg;
        color: $panel-image-caption-color;
        display: inline-block;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: auto;
        }

        a {
            border-bottom: 1px solid $panel-image-caption-color;
            color: $panel-image-caption-color;

            &:hover,
            &:focus {
                border-bottom: none;
                color: $panel-image-caption-color;
            }
        }
    }
}

// For Intersection observer images loading smoothly
@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in 1s ease;
}
