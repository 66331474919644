﻿/// <reference path="../bootstrap/bootstrap.scss" />
/// <reference path="variables.scss" />
//
// Typography
// --------------------------------------------------
// Headings
// -------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 10px;
    margin-top: 30px;
    word-wrap: break-word;
}

h1 {
    @include font-futura-bold();
    @include font-size(3);
    line-height: 110%;

    @media (min-width: $screen-sm-min) {
        @include font-size(5.5);
    }

    @media (min-width: $screen-lg-min) {
        @include font-size(7);
    }

    &.h1--small {
        @include font-size(2.8);

        @media (min-width: $screen-sm-min) {
            @include font-size(4.8);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(6);
        }
    }

    &.h1--xsmall {
        @include font-size(2.4);
        line-height: 120%;

        @media (min-width: $screen-sm-min) {
            @include font-size(3.8);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(5);
        }
    }

    &.h1--lite {
        @include font-futura-book();
    }

    &.h1--gigantic {
        @include font-futura-bold();
        @include font-size(14);
        color: $brand-secondary;
    }
}

h2 {
    @include font-futura-bold();
    @include font-size(2.4);
    line-height: 120%;

    @media (min-width: $screen-sm-min) {
        @include font-size(2.8);
    }

    @media (min-width: $screen-lg-min) {
        @include font-size(3);
    }

    &.h2--small {
        @include font-size(2.0);

        @media (min-width: $screen-sm-min) {
            @include font-size(2.4);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(2.6);
        }
    }

    &.h2--large {
        @include font-size(2.8);

        @media (min-width: $screen-sm-min) {
            @include font-size(3.2);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(4);
        }
    }

    &.h2--lite {
        @include font-futura-book();
    }

    &.h2--underline {
        border-bottom: 8px solid $heading-underline-color;
        margin-bottom: 20px;
        margin-top: 40px;
        padding-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 25px;
            padding-bottom: 25px;
        }
    }
}

h3 {
    @include font-karla-bold();
    @include font-size(2.1);
    line-height: 120%;

    @media (min-width: $screen-sm-min) {
        @include font-size(2.1);
    }

    @media (min-width: $screen-lg-min) {
        @include font-size(2.6);
    }

    &.h3--small {
        @include font-size(1.4);

        @media (min-width: $screen-sm-min) {
            @include font-size(1.5);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(1.6);
        }
    }

    &.h3--centered {
        text-align: center;
    }

    &.h3--related-content {
        @include font-size(1.8);

        @media (min-width: $screen-sm-min) {
            @include font-size(2);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(2.2);
        }
    }
}

h4 {
    @include font-karla-bold();
    @include font-size(1.6);
    line-height: 120%;

    &.h4--large-bold {
        @include font-size(1.8);

        @media (min-width: $screen-sm-min) {
            @include font-size(2);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(2.2);
        }
    }

    &.h4--small {
        @include font-size(1.4);
    }

    &.h4--bold {
        @include font-karla-bold();
    }
}

h5 {
    @include font-karla-bold();
    @include font-size(1.4);
    line-height: 120%;
}

h6 {
    @include font-karla-regular();
    @include font-size(1.2);
    line-height: 120%;
}

// Body text
// -------------------------
p {
    @include font-size(1.6);
    line-height: 150%;
    margin-bottom: 10px;
    word-wrap: break-word;

    strong {
        @include font-karla-bold();
        font-weight: normal;
    }

    em {
        font-style: italic;
    }

    &:last-child {
        margin-bottom: 15px !important;
    }
}

.lead {
    @include font-size(1.8);
    font-weight: normal;
    line-height: 140%;
    margin-bottom: 20px;
    word-wrap: break-word;

    @media (min-width: $screen-sm-min) {
        @include font-size(2.1);
    }

    @media (min-width: $screen-md-min) {
        @include font-size(2.3);
    }
}

// Used in publications on Staff profiles
i {
    // For icons page, specified a font-size of 2rem
    @include font-size(2);
    word-wrap: break-word;
}

// Emphasis & misc
// -------------------------
small,
.small {
    @include font-size(1.2);
    line-height: 30px;
    word-wrap: break-word;
}

.text-danger {
    @include font-karla-bold();
}

.text-success {
    @include font-karla-bold();
}

// Lists
// -------------------------
// Unordered and Ordered lists
ul,
ol {
    line-height: 125%;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 40px;
    word-wrap: break-word;

    @media (min-width: $screen-sm-min) {
        @include font-size(1.5);
    }

    @media (min-width: $screen-lg-min) {
        @include font-size(1.6);
    }

    li {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}

// Ordered lists
ol {
    @extend .text-left;
    word-wrap: break-word;

    ol {
        margin-bottom: 0;
        margin-top: 0;
    }
}

// Unordered lists
ul {
    li {
        list-style: none;
        position: relative;
        word-wrap: break-word;

        &:before {
            content: "";
            @include triangle-right($ul-bullet-color, 7px, 4px);
            display: inline-block;
            left: -20px;
            position: absolute;
            top: 4px;

            @media (min-width: $screen-sm-min) {
                top: 5px;
            }

            @media (min-width: $screen-lg-min) {
                top: 6px;
            }
        }

        ul {
            margin-bottom: 0;
            margin-top: 0;

            li {
                &:before {
                    content: "";
                    @include border-radius(50%, 50%, 50%, 50%);
                    background-color: $ul-child-bullet-color;
                    border: none;
                    height: 5px;
                    top: 6px;
                    width: 5px;
                    word-wrap: break-word;

                    @media (min-width: $screen-lg-min) {
                        top: 7px;
                    }
                }
            }
        }
    }
}

// Blockquotes
blockquote {
    border-left: none;
    margin-bottom: 30px;
    margin-top: 30px;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0;
    position: relative;
    word-wrap: break-word;

    &:before {
        @include font-size(6.5);
        content: "\e607";
        color: $blockquote-icon-color;
        font-family: "icomoon";
        left: 10px;
        line-height: 1;
        position: absolute;
        top: -35px;
    }

    p {
        @include font-futura-heavy-oblique();
        @include font-size(2.4);
        line-height: 125%;
        position: relative;
        z-index: 10;

        @media (min-width: $screen-sm-min) {
            @include font-size(2.8);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(3);
        }
    }

    footer {
        @include font-karla-bold();
        @extend .text-right;
        line-height: 125%;
        margin-top: 10px;

        &:before {
            display: none;
        }

        cite {
            font-style: normal;
        }
    }
}

// Opposite alignment of blockquote
.blockquote-reverse,
blockquote.pull-right {
    padding-right: 30px;
    padding-left: 30px;
    border-right: none;
    text-align: right;
    word-wrap: break-word;

    &:before {
        content: "\e60b";
        left: auto;
        right: 10px;
    }

    // Account for citation
    footer {
        @extend .text-left;

        &:after {
            display: none;
        }
    }
}

// Section Header
.section-header {
    @extend .text-center;
    margin-bottom: 15px;
    margin-top: 40px;
    padding-bottom: 5px;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 30px;
        margin-top: 60px;
        padding-bottom: 0;
    }

    h2 {
        text-transform: uppercase;
        margin-top: 0;
        word-wrap: break-word;
    }

    p {
        margin-bottom: 15px;
    }

    ul {
        li {
            list-style: none;
            position: relative;
            word-wrap: break-word;

            &:before {
                display: none;
            }
        }
    }
}

// Remove underline on all links by default
a {
    text-decoration: none;
    word-wrap: break-word;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

// Set content & list links to have a bottom border rather than underline
p,
ul,
ol,
// For links on legacy news and events
.page-node-type-news-article .content,
.page-node-type-event .content {
    a {
        @include font-karla-bold();
        border-bottom: 1px solid $text-color;
        word-wrap: break-word;

        &:hover,
        &:focus {
            border-bottom: none;
            color: $body-bg;
            background-color: $text-color;
            text-decoration: underline;
        }
    }
}

//Admin toolbar menu
.toolbar-menu-administration {
    ul {
        a {
            color: $grey-dark;

            &:hover,
            &:focus {
                border-bottom: none;
                background-color: $body-bg;
            }
        }
    }
}

// Make all images 100% width by default
img {
    width: 100%;
}

//Tags - used in scholarships and course search plus news
.tag-group {
    margin-bottom: 20px;
    margin-top: 20px;
}

.tag-icon {
    @include font-size(1.4);
    @extend .text-uppercase;
    background: url('../images/tag-icon.png') 100% 50% no-repeat;
    background-size: 18px auto;
    color: $brand-primary;
    line-height: 2.5em;
    max-width: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 25px;
    padding-top: 10px;
    text-align: center;

    &__course-search,
    &__news {
        @extend .text-uppercase;
        background-color: transparent;
        border: 3px solid $btn-secondary-bg;
        padding: 10px;
        display: inline-block;
        margin-bottom: 5px;
        word-wrap: break-word;

        // Hover
        &:hover,
        &:focus,
        &.focus {
            box-shadow: none;
            background-color: $white;
            color: $grey-dark;

            &:after {
                border-left-color: $btn-secondary-hover-color;
            }
        }
    }
}