// Top banner
// --------------------------------------------------

// .dialog-off-canvas-main-canvas {
//     position: relative;
// }

.top-banner {
    background-color: $uow-magenta;
    position: relative;
    display: block;
    height: 30px;
    z-index: 1000;
}
// 
.region-top-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .block {
        background-color: $uow-magenta;
        position: fixed;
        height: 30px;
        color: $white;
        width: 100%;
        text-align: center;

        p {
            @extend .container;
            @include font-size(1.4);
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 3px;

            a {
              color: $white;
              border-bottom: 1px solid $white;

              &:hover,
              &:focus {
                border-bottom: none;
              }
            }
        }       
    }
}