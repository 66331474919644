/* -----------------------------------
 * SASS for news panels and fewatures
 */

// -------------------------
//News highlight panel
.news-highlight {
  @include clearfix();
  background-color: $white;
  position: relative;
  margin-bottom: 15px;
  margin-top: 0px;
  border-top: 8px solid $brand-secondary;

  a {
    @include clearfix();
    display: inline-block;
    width: 100%;
    height: inherit;
  }

  &__category {
    @extend .event-feature__category;
    width: 65%;
    top: -35px;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }

  &__title {
    @include make-xs-column(12);
    padding-left: 15px;

    @media (min-width: $screen-xxs-min) {
      min-height: 210px;
    }

    @media (min-width: $screen-xs-min) {
      min-height: 160px;
    }

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(6);
      min-height: 1px;
      height: 175px;
      overflow: hidden;
    }

    @media (min-width: $screen-md-min) {
      height: 250px;
    }

    @media (min-width: $screen-lg-min) {
      height: 290px;
    }

    h2 {
      @include font-karla-bold();
      @include font-size(2.1);
      margin-top: 20px;

      @media (min-width: $screen-sm-min) {
        margin-top: 15px;
        height: 100px;
      }

      @media (min-width: $screen-md-min) {
        height: auto;
        margin-top: 30px;
      }

      @media (min-width: $screen-lg-min) {
        @include font-size(2.6);
      }
    }
  }

  &__date {
    margin-bottom: 10px;

    @media (min-width: $screen-xxs-min) {
      position: absolute;
      bottom: 15px;
      left: 15px;
    }

    @media (min-width: $screen-sm-min) {
      left: 30px;
      bottom: 5px;
    }
  }

  &__link {
    position: absolute;
    bottom: 15px;
    right: 15px;

    @media (min-width: $screen-sm-min) {
      right: 30px;
    }
  }

  &__image {
    @include make-xs-column(12);
    @include make-sm-column(6);
    padding-left: 0;
    padding-right: 0;
  }
}

//View News - for news landing page
.view-news-categories {
  margin-top: 30px;
}

// News featured
.news-feature {
  @extend .event-feature;
  margin-top: 0;

  &__category {
    @extend .event-feature__category;
    @include font-size(1.2);
    padding-top: 8px;
    padding-bottom: 8px;
    top: -33px;

    @media (min-width: $screen-sm-min) {
      top: -33px;
    }
  }

  a {
    @include clearfix();
    display: inline-block;
    width: 100%;
    height: inherit;
  }

  &__image {
    @extend .event-feature__image;
  }

  &__title {
    @extend .event-feature__title;
  }

  &__details {
    @include font-size(1.4);
    //  @extend .text-uppercase;
    position: absolute;
    left: 15px;
    bottom: 5px;

    @media (min-width: $screen-sm-min) {
      bottom: 15px;
    }

    @media (min-width: $screen-md-min) {
      @include font-size(1.6);
    }
  }
}

//Heading on News category list views pages
.news-list-title {
  @include font-karla-bold;
  @include font-size(2);
  text-align: center;
  color: $grey-dark;
  line-height: 34px;
  padding-bottom: 10px;
  margin-top: 15px;

  @media (min-width: $screen-sm-min) {
    margin-top: 25px;
  }

  @media (min-width: $screen-md-min) {
    @include font-size(2.8);
  }

  span {
    @include font-size(4);
    position: relative;
    top: -3px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

// News event-listing__title
.news-listing {
  @extend .news-feature;
  display: block;
  position: relative;
  height: auto;
  margin-bottom: 10px;
  border-top: 6px solid $brand-secondary;

  @media (min-width: $screen-md-min) {
    height: 320px;
    border-top: none;
    margin-bottom: 20px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 25px;
    height: 350px;
    margin-bottom: 30px;
  }

  a {
    @include clearfix();
    display: inline-block;
    width: 100%;
    height: inherit;
  }

  &__image {
    display: none;

    @media (min-width: $screen-md-min) {
      display: block;
      border-bottom: 6px solid $brand-secondary;
    }

    img {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__title {
    @include make-md-column(12);
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 35px;
    padding-top: 15px;

    @media (min-width: $screen-md-min) {
      padding-top: 5px;
    }

    h3 {
      @include font-size (1.6);
      line-height: 120%;
      margin-top: 5px;

      @media (min-width: $screen-md-min) {
        margin-top: 20px;
      }
    }
  }

  &__category {
    @extend .news-feature__category;
    top: -33px;

    @media (min-width: $screen-sm-min) {
      top: -33px;
    }
  }

  &__details {
    @extend .news-feature__details;
    @include font-size(1.4);
  }
}

//Featured news
.homepage-news-feature {
  display: table;
  position: relative;
  background-color: $grey-dark;
  color: $white;
  margin-bottom: 15px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
    height: 360px;
  }

  @media (min-width: $screen-md-min) {
    height: 380px;
  }

  @media (min-width: $screen-lg-min) {
    height: 480px;
  }

  a {
    color: $white;

    &:hover,
    &focus {
      border-bottom: 1px solid $white;
      color: $white;
    }
  }

  &__title {
    @include font-karla-bold();
    padding-left: 15px;
    padding-right: 15px;
    border-top: 6px solid $uow_blue;
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
      padding-bottom: 0;
      max-height: 125px;
      overflow: hidden;
    }

    @media (min-width: $screen-md-min) {
      padding-bottom: 0;
      max-height: 98px;
    }

    @media (min-width: $screen-lg-min) {
      padding-bottom: 0;
      max-height: 125px;
    }

    h3 {
      margin-top: 15px;
    }
  }

  &__details {
    @include font-size(1.4);
    @extend .text-uppercase;
    position: absolute;
    right: 15px;
    bottom: 15px;

    @media (min-width: $screen-md-min) {
      @include font-size(1.6);
    }
  }
}

.homepage-news-list {
  @include clearfix();
  position: relative;

  @media (min-width: $screen-sm-min) {
    margin-top: 30px;
  }
}

// Current Students News listing
.homepage-news-listing {
  @include clearfix();
  position: relative;
  background-color: $white;
  border-top: 6px solid $brand-secondary;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 130px;
  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    min-height: 110px;
    margin-bottom: 10px;
    padding-bottom: 15px;
  }

  @media (min-width: $screen-lg-min) {
    min-height: 130px;
  }

  &__body {
    padding-bottom: 10px;

    @media (min-width: $screen-sm-min) {
      max-height: 60px;
      overflow: hidden;
      margin-bottom: 5px;
    }

    @media (min-width: $screen-lg-min) {
      max-height: 90px;
    }
  }

  &__heading {
    margin-top: 0px;

    h4 {
      margin-top: 0px;
    }
  }

  &__details {
    @extend .text-uppercase;
    position: relative;
    right: 10px;
    bottom: 10px;
    max-width: 95%;
    text-align: right;
    float: right;
    padding-top: 15px;

    @media (min-width: $screen-xxs-min) {
      position: absolute;
      right: 10px;
      bottom: 5px;
    }

    @media (min-width: $screen-sm-min) {
      right: 10px;
      bottom: 5px;
    }
  }

  &__btn {
    float: right;

    @media (min-width: $screen-sm-min) {
      position: absolute;
      bottom: -25px;
      right: 0;
    }

    @media (min-width: $screen-md-min) {
      bottom: -35px;
    }

    @media (min-width: $screen-lg-min) {
      bottom: -45px;
    }
  }
}