/* -----------------------------------
 * SASS for event panels and features
 */

// -------------------------
//Event Featured
.event-feature {
  color: $grey-dark;
  position: relative;
  display: table;
  background-color: $white;
  margin-bottom: 15px;

  // margin-top: 30px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
    height: 380px;
  }

  @media (min-width: $screen-md-min) {
    height: 400px;
  }

  @media (min-width: $screen-lg-min) {
    height: 480px;
  }

  a {
    @include clearfix();
    display: inline-block;
    width: 100%;
    height: inherit;
  }

  &__image {
    border-bottom: 6px solid $brand-secondary;
    width: 100%;
  }

  &__category {
    @include font-karla-regular();
    @include font-size(1.4);
    background-color: $brand-secondary;
    text-align: center;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 120px;
    position: absolute;
    right: 0;
    top: -30px;

    @media (min-width: $screen-md-min) {
      top: -30px;
    }
  }

  &__date {
    @include font-karla-bold();
    @include font-size(1.8);
    background-color: $white;
    text-transform: uppercase;
    line-height: 1.2em;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    @media (min-width: $screen-md-min) {
      width: 120px;
    }

    span {
      @include font-karla-regular();
      @include font-size(1.6);
    }
  }

  &__title {
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
      padding-bottom: 0;
      overflow: hidden;
    }

    h3,
    h4 {
      margin-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }
  }
}

//Event listing
.homepage-event-list {
  @include clearfix();
  position: relative;
}

//View Events - for events landing page
//View News - for news landing page
.view-event-categories {
  margin-top: 30px;
}

.event-listing {
  @extend .event-feature;
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  border-top: 6px solid $brand-secondary;

  @media (min-width: $screen-md-min) {
    height: 320px;
    margin-bottom: 20px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 25px;
    height: 340px;
    margin-bottom: 30px;
  }

  a {
    @include clearfix();
    display: inline-block;
    width: 100%;
    height: inherit;
  }

  &__image {
    display: none;

    @media (min-width: $screen-md-min) {
      display: block;
    }

    img {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__date {
    @extend .event-feature__date;
    vertical-align: middle;
    top: 6px;
    width: 100px;

    @media (min-width: $screen-md-min) {
      width: 150px;
    }

    @media (min-width: $screen-md-min) {
      top: 0;
    }
  }

  &__title {
    @include make-xs-column(10);
    @include make-md-column(12);
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 60px;
    min-height: 100px;

    @media (min-width: $screen-xs-min) {
      padding-right: 40px;
    }

    @media (min-width: $screen-md-min) {
      border: none;
      padding-bottom: 0;
    }

    h3 {
      @include font-size (1.6);
      line-height: 120%;
      margin-top: 5px;

      @media (min-width: $screen-md-min) {
        margin-top: 20px;
      }
    }
  }

  &__category {
    @extend .event-feature__category;
    top: -24px;
  }
}

//Homepage event featured
.homepage-event-feature {
  @extend .event-feature;
  border-top: 6px solid $uow-blue;
  color: $white;
  background-color: $grey-dark;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
    height: 360px;
  }

  @media (min-width: $screen-md-min) {
    height: 380px;
  }

  @media (min-width: $screen-lg-min) {
    height: 480px;
  }

  a {
    color: $white;

    &:hover,
    &:focus {
      border-bottom: 1px solid $white;
      color: $white;
    }
  }

  &__image {
    width: 100%;
  }

  &__date {
    @extend .event-feature__date;
    @include font-karla-regular();
    background-color: $brand-secondary;
    color: $grey-dark;
    width: 100px;
    height: 100px;
    padding-top: 25px;

    span {
      @include font-size(1.8);
    }
  }

  &__title {
    background-color: $grey-dark;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
      padding-bottom: 0;
      max-height: 125px;
      overflow: hidden;
    }

    @media (min-width: $screen-md-min) {
      padding-bottom: 0;
      max-height: 98px;
    }

    @media (min-width: $screen-lg-min) {
      padding-bottom: 0;
      max-height: 125px;
    }

    h3 {
      margin-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }
  }

  &__category {
    @include font-size(1.4);
    text-transform: uppercase;
    position: absolute;
    right: 15px;
    bottom: 15px;

    @media (min-width: $screen-md-min) {
      @include font-size(1.6);
    }
  }
}

//Homepage Event listing
.homepage-event-listing {
  @extend .media;
  margin-top: 0px;
  margin-bottom: 5px;
  position: relative;
  background-color: $white;
  padding: 15px;
  min-height: 120px;
  margin-bottom: 15px;

  @media (min-width: $screen-sm-min) {
    min-height: 110px;
    margin-bottom: 10px;
  }

  @media (min-width: $screen-md-min) {
    margin-top: 30px;
  }

  @media (min-width: $screen-lg-min) {
    min-height: 130px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  &__left {
    @extend .media-left;
    padding-right: 0px;
    vertical-align: top !important;

    @media (min-width: $screen-sm-min) {
      vertical-align: middle !important;
    }
  }

  &__date {
    @extend .media-object;
    @include font-karla-bold();
    @include font-size(1.8);
    text-transform: uppercase;
    line-height: 1em;
    width: 70px;
    height: 70px;
    background-color: $brand-secondary;
    color: $grey-dark;
    text-align: center;
    padding-top: 15px;
    vertical-align: middle;

    span {
      @include font-size(1.4);
      @include font-karla-regular();
      text-align: center;
    }
  }

  &__body {
    @extend .media-body;
    position: relative;
  }

  &__heading {
    @extend .media-heading;
    margin-bottom: 0px;
    overflow: hidden;
    padding-bottom: 15px;
    padding-left: 10px;

    @media (min-width: $screen-sm-min) {
      max-height: 60px;
    }

    @media (min-width: $screen-lg-min) {
      max-height: 78px;
      margin-bottom: 10px;
    }
  }

  &__category {
    @include font-size(1.4);
    position: absolute;
    bottom: -5px;
    right: 2px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    z-index: 100;
  }

  &__btn {
    @extend .homepage-news-listing__btn;

    @media (min-width: $screen-lg-min) {
      bottom: -45px;
    }
  }
}

// Featured event component
.panel-event-featured {
  position: relative;

  &__title {
    margin-top: 0;
    overflow-y: hidden;

    @media (min-width: $screen-sm-min) {
      height: 54px;
    }

    @media (min-width: $screen-lg-min) {
      height: 64px;
    }

    a {
      border-bottom: 1px solid $text-color;
      display: inline;

      &:hover,
      &:focus {
        border-bottom: none;
      }
    }
  }

  &__image {
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    a {
      display: inline-block;
      width: 100%;
    }
  }

  &__type {
    @include font-size(1.4);
    @extend .text-uppercase;
    background-color: $panel-event-tag-bg;
    line-height: 100%;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    position: absolute;
    right: -5px;
    top: -10px;

    @media (min-width: $screen-sm-min) {
      top: -10px;
    }
  }

  &__duration {
    @extend .panel-event-featured__type;
    background-color: $brand-primary;
    color: white;
    top: 5px;

    @media (min-width: $screen-sm-min) {
      top: 20px;
    }
  }

  &__date {
    @include font-futura-bold();
    @include font-size(2);
    background-color: $panel-event-date-bg;
    color: $panel-event-date-color;
    float: left;
    line-height: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    @media (min-width: $screen-sm-min) {
      @include font-size(2.2);
      text-align: center;
      bottom: 0;
      float: none;
      left: 0;
      padding-left: 0;
      padding-right: 0;
      position: absolute;
      width: 170px;
    }

    @media (min-width: $screen-md-min) {
      @include font-size(3);
      width: 230px;
    }

    @media (min-width: $screen-lg-min) {
      @include font-size(4);
      width: 305px;
    }

    &__range {
      @extend .panel-event-featured__date;
      width: 100%;
      text-align: center;

      @media (min-width: $screen-xs-min) {
        @include font-size(2.2);
        bottom: 0;
        float: none;
        left: 0;
        padding-left: 0;
        padding-right: 0;
        position: absolute;
      }

      @media (min-width: $screen-sm-min) {
        @include font-size(2);
      }

      @media (min-width: $screen-md-min) {
        @include font-size(2.5);
      }

      @media (min-width: $screen-lg-min) {
        @include font-size(3.5);
      }
    }
  }

  &__time {
    @include font-size(1.4);
    background-color: $panel-event-time-bg;
    color: $panel-event-time-color;
    float: left;
    line-height: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    @media (min-width: $screen-sm-min) {
      @include font-size(1.5);
      bottom: 7px;
      float: none;
      left: 170px;
      position: absolute;
    }

    @media (min-width: $screen-md-min) {
      bottom: 15px;
      left: 230px;
    }

    @media (min-width: $screen-lg-min) {
      @include font-size(1.6);
      bottom: 24px;
      left: 305px;
    }
  }

  &__summary {
    margin-bottom: 0;
    overflow-y: hidden;

    @media (min-width: $screen-sm-min) {
      height: 66px;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 10px;
    }

    @media (min-width: $screen-lg-min) {
      height: 72px;
    }
  }
}

//Next Open Day events
.panel-next-open-day {
  @extend .panel-event-featured;
  padding: 15px;

  &:hover,
  &:focus {
    background-color: $white;
  }

  &__title {
    @extend .panel-event-featured__title;
    overflow-y: visible;

    @media (min-width: $screen-sm-min) {
      height: 54px;
    }

    @media (min-width: $screen-md-min) {
      height: auto;
    }
  }

  &__image {
    @extend .panel-event-featured__image;
  }

  &__type {
    @extend .panel-event-featured__type;
    z-index: 100;
  }

  &__date {
    @include font-futura-bold();
    @include font-size(2.5);
    background-color: $brand-primary;
    color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 280px;
    float: left;
    line-height: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: center;
  }

  &__time {
    @include font-size(1.4);
    position: absolute;
    bottom: 45px;
    left: 190px;
    background-color: $grey-dark;
    color: $white;
    padding: 10px;

    @media (min-width: $screen-sm-min) {
      bottom: 40px;
      left: 193px;
    }

    @media (min-width: $screen-md-min) {
      bottom: 24px;
      left: 280px;
    }
  }
}