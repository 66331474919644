// Featured profile
.featured-profile {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &__event {
    display: block;
    margin-top: 30px !important;
  }

  &__title {
    @include make-xs-column(12);
    @include make-sm-column(8);
    @include make-sm-column-offset(4);
    @include make-md-column(9);
    @include make-md-column-offset(3);
    @extend .text-center;
    @extend .text-uppercase;
    margin-top: 0;

    @media (min-width: $screen-sm-min) {
      text-align: left;
      margin-bottom: 30px;
    }
  }

  &__image-link {
    @include make-xs-column(12);
    @include make-sm-column(4);
    @include make-md-column(3);
    @extend .text-center;
    margin-top: 20px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }

    img {
      border-radius: 50%;
      display: inline-block;
      width: 65%;

      @media (min-width: $screen-sm-min) {
        width: 100%;
      }
    }
  }

  &__image-link-button {
    @extend .hidden-xs;
    margin-top: 30px;

    .btn {
      width: 70%;

      @media (min-width: $screen-sm-min) {
        width: 100%;
      }
    }
  }

  &__bio {
    @include make-xs-column(12);
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(8);
    }

    @media (min-width: $screen-md-min) {
      @include make-md-column(5);
    }

    a,
    a:hover,
    a:focus {
      color: $brand-primary-dark;
    }

    h3>div {
      margin-top: 30px;
      margin-right: 10px;
      display: inline-block;
      float: left;

      @media (min-width: $screen-sm-min) {
        margin-top: 0;
      }
    }

    h4 {
      clear: both;
      @extend .text-uppercase;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  &__ref {
    position: relative;
  }

  &__testimonial {
    @extend .featured-profile__bio;
    @include clearfix();
    margin-top: -110px;
  }

  &__intro {
    @extend .featured-profile__bio;
    @include clearfix();
    margin-left: -30px;
    margin-top: -110px;
  }

  &__text {
    @include make-xs-column(12);
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(8);
    }

    @media (min-width: $screen-md-min) {
      @include make-md-column(9);
    }
  }

  &__quote {
    @extend .hidden-xs;
    @extend .hidden-sm;
    @include make-sm-column(4);

    blockquote {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;

      &:before {
        left: -20px;
      }
    }
  }
}

//2 COURSE LEADERS SPLIT PANEL
.dbl-profile {
  @include make-xs-column(12);
  @include make-sm-column(6);
  margin-bottom: 60px;

  &__image-quote {
    @include make-xs-column(12);
    @include make-sm-column(6);

    img {
      @extend .hidden-xs;
      border-radius: 50%;
    }

    blockquote {
      @extend .hidden-xs;
      padding: 0px 10px 0px 10px;

      &:before {
        font-size: 4.5rem;
        top: -24px;
        left: 0px
      }

      p {
        font-size: 2rem;
      }
    }
  }

  &__content {
    @include make-xs-column(12);
    @include make-sm-column(6);
    padding-left: 20px;
    padding-right: 20px;

    h3 {
      font-size: 2rem;
      margin-top: 10px;
    }

    h4 {
      margin-top: 20px;
    }

    p {
      line-height: 130%;
    }
  }

  &__title-center {
    text-align: center;
    margin-bottom: 30px;
  }

  &__divider {
    @media (min-width: $screen-sm-min) {
      border-left: 1px solid $grey-light;
    }
  }
}

// Staff profile
.profile {
  @extend .text-center;
  margin-bottom: 30px !important;

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  &__image {
    display: inline-block;

    img {
      border-radius: 50%;
      padding: 0 30px;

      @media (min-width: $screen-md-min) {
        padding: 0 30px;
      }
    }
  }

  &__name {
    color: $brand-primary-dark;
    margin-bottom: 0;
    margin-right: 5px;
  }

  &__name--nolink {
    @extend .profile__name;
    color: $grey-dark;
  }

  &__summary,
  &__testimonial {
    margin-bottom: 20px;
    margin-top: 15px;
  }

  &__alumni {
    text-align: center;
    min-height: 440px;

    h4 {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}

h4.profile__alumni {
  width: 95%;
}

//Text only Course Team profile
.profile-text {
  @include make-xs-column(12);
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (min-width: $screen-md-min) {
    @include make-md-column(6);
  }

  &__container {
    margin-bottom: 30px;
  }

  a {
    list-style: none;
    position: relative;
    @include font-karla-bold();
    @include font-size(1.6);
    border-bottom: 1px solid $text-color;

    &:hover,
    &:focus {
      border-bottom: none;
    }

    &:before {
      content: "";
      @include triangle-right($ul-bullet-color, 7px, 4px);
      display: inline-block;
      left: -15px;
      position: absolute;
      top: 4px;

      @media (min-width: $screen-sm-min) {
        left: -20px;
        top: 5px;
      }

      @media (min-width: $screen-lg-min) {
        top: 6px;
      }
    }
  }
}

/// FULL PAGE PROFILEs
// Students
.student-profile,
.honorary-doc {
  @extend .featured-profile;

  &__details {
    @include make-xs-column(12);
    @include font-size(1.6);

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(4);
    }

    img {
      border-radius: 50%;
      display: block;
      margin: 0 auto;

      @media (min-width: $screen-xs-min) {
        @include make-xs-column(4);
        width: 50%;
        display: inline-block;
        margin-bottom: 30px;
      }

      @media (min-width: $screen-sm-min) {
        @include make-sm-column(12);
        @include no-padding();
        width: 90%;
        clear: both;
      }
    }

    &--right {
      @include font-karla-regular();
      @include make-xs-column(12);
      @include no-padding();

      @media (min-width: $screen-xs-min) {
        @include make-xs-column(6);
        @include no-padding();
        float: right;
      }

      @media (min-width: $screen-sm-min) {
        @include make-sm-column(12);
        @include no-padding();
        float: none;
        clear: both;
      }

      .role {
        margin-top: 15px;
        margin-bottom: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 3px solid $brand-secondary;
        border-bottom: 3px solid $brand-secondary;
      }

      span {
        @include font-karla-bold();
      }
    }
  }

  &__quote {
    padding-top: 15px;
    clear: both;

    blockquote {
      padding-left: 0;
      padding-right: 0;

      &:before {
        left: -20px;
      }
    }
  }

  &__bio {
    @include make-xs-column(12);
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(8);
    }

    .btn {
      margin-top: 15px;
    }
  }
}

/// Listing of all students
.student-listing {
  @extend .featured-profile;

  &__details {
    @include make-xs-column(12);
    position: relative;

    @media (min-width: $screen-sm-min) {
      @include make-xs-column(6);
      height: 470px;
      padding-top: 45px;
      border-bottom: 1px solid $grey-light;
    }

    @media (min-width: $screen-md-min) {
      height: 300px;
    }

    &--image {
      display: none;

      @media (min-width: $screen-sm-min) {
        @include make-xs-column(8);
        @include no-padding();
        display: block;

        img {
          border-radius: 50%;
          display: block;
          margin: 0 auto;
          width: 100%;
        }
      }

      @media (min-width: $screen-md-min) {
        @include make-sm-column(4);
        @include no-padding();
      }
    }

    &--role {
      @include make-xs-column(12);
      @include font-karla-bold();
      padding-bottom: 30px;
      border-bottom: 1px solid $grey-light;

      @media (min-width: $screen-sm-min) {
        @include make-xs-column(12);
        @include no-padding();
        padding-bottom: 0;
        border-bottom: none;
        height: 250px;

        .btn {
          position: absolute;
          bottom: 50px;
        }
      }

      @media (min-width: $screen-md-min) {
        @include make-sm-column(8);
        padding-right: 0;
        height: 220px;

        .btn {
          bottom: 0px;
        }
      }

      h3 {
        @include font-karla-bold();
        margin-bottom: 15px;

        a,
        a:hover,
        a:focus {
          color: $brand-primary-dark;
        }
      }

      p {
        margin-bottom: 0;
      }

      span {
        @include font-karla-regular();
      }
    }
  }
}

//Academic directory
.staff-listing {
  @extend .media;
  background-color: $white;
  padding-left: 0;
  padding-right: 0;

  &__photo {
    display: none;

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(3);
      padding-left: 15px;
      padding-right: 15px;
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
    }

    @media (min-width: $screen-md-min) {
      @include make-md-column(3);
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    img {
      display: none;
      border-radius: 50%;

      @media (min-width: $screen-sm-min) {
        display: inline-block;
        width: 70%;
      }

      @media (min-width: $screen-md-min) {
        width: 70%;
      }
    }
  }

  &__details {
    @include make-xs-column(12);
    padding-right: 15px;
    @include make-sm-column(8);
    padding-left: 15px;
    padding-right: 0;

    @media (min-width: $screen-md-min) {
      @include make-md-column(9);
      padding-left: 0;
    }
  }

  &__results-header {
    @extend .details-pane__results-header;
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;

    @media (min-width: $screen-sm-min) {
      padding-top: 20px;
    }

    @media (min-width: $screen-md-min) {
      padding-top: 30px;
    }
  }

  &__name {
    @extend .details-pane__results-title;
  }

  &__role {
    @extend .details-pane__results-type;
  }

  &__body {
    @include make-xs-column(12);
    padding-top: 5px;
    border-top: 5px solid $brand-secondary;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $screen-sm-min) {
      @include make-sm-column-offset(0);
      @include make-sm-column(12);
      padding-right: 15px;
      padding-top: 10px;
    }

    @media (min-width: $screen-md-min) {
      @include make-md-column(9);
      @include make-md-column-offset(3);
      margin-top: -60px;
      padding-left: 15px;
    }

    @media (min-width: $screen-lg-min) {
      margin-top: -75px;
    }
  }

  &__result-set {
    @extend .details-pane__result-set;
    padding-left: 30px;
    padding-right: 0;
    display: block;

    @media (max-width: $screen-xxs-max) {
      padding-left: 0;
    }

    @media (min-width: $screen-sm-min) {
      padding-left: 30px;
      margin-left: -30px;
    }

    @media (min-width: $screen-md-min) {
      margin-left: -40px;
    }

    [class^="icon-"] {
      display: none;

      @media (min-width: $screen-xs-min) {
        display: inline;
        @include font-size(2.5);
        margin-left: 5px;
      }
    }

    span {
      @include font-size(1.6);
      margin-left: -15px !important;
      word-break: normal;
      line-height: 1.3;

      @media (min-width: $screen-xs-min) {
        margin-left: -5px !important;
      }
    }
  }

  &__result {
    @extend .details-pane__result;
    word-break: break-all;
    padding-left: 10px;

    @media (min-width: $screen-md-min) {
      margin-top: 8px;
      padding-left: 15px;
    }
  }

  &__info-title {
    @extend .details-pane__result-title;
    text-transform: none;
  }

  &__info-value {
    @extend .details-pane__result-value;
    max-width: 320px;
    text-transform: none;
    clear: left;
    margin-top: 5px;

    @media (min-width: $screen-sm-min) {
      max-width: 700px;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 0;
    }

    @media (min-width: $screen-lg-min) {
      clear: none;
    }
  }
}

//Academic profile FULL
.masthead-profile {
  @extend .masthead-course;
  padding-bottom: 0;

  &__container {
    @extend .masthead-index__header;
    text-transform: none;
    padding-top: 85px;
    padding-left: 15px;

    @media (min-width: $screen-md-min) {
      padding-top: 185px;
    }

    h1 {
      @include font-size(2.3);
      @include make-xs-column(12);
      text-transform: none;
      padding-left: 0 !important;
      margin-left: 0 !important;

      @media (min-width: $screen-xs-min) {
        @include font-size(3);
      }

      @media (min-width: $screen-sm-min) {
        margin-bottom: 15px;
      }

      @media (min-width: $screen-md-min) {
        @include font-size(6);
      }
    }
  }

  &__details {
    @include make-xs-column(12);
    @include make-sm-column(7);
    @include make-md-column(8);
    float: left;
    padding-left: 0 !important;

    @media (min-width: $screen-xs-min) {
      //  padding-left: 15px !important;
    }

    @media (min-width: $screen-md-min) {
      padding-right: 0;
    }

    h3 {
      width: 100%;
      text-transform: none;
      color: $white;
      margin-bottom: 15px;
      margin-top: 0;
      padding-left: 0 !important;
      margin-left: 0 !important;

      @media (min-width: $screen-sm-min) {
        margin-bottom: 25px;
      }

      @media (min-width: $screen-lg-min) {
        margin-bottom: 45px;
      }
    }
  }

  &__dept {
    margin-bottom: 15px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 20px;
    }

    @media (min-width: $screen-md-min) {
      margin-bottom: 30px;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  &__results {
    @include clearfix();
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__result-set {
    @extend .details-pane__result-set;
    @include make-xs-column(12);
    float: left;
    margin-bottom: 15px;
    padding-left: 25px;

    @media (min-width: $screen-sm-min) {
      margin-left: -25px;
    }

    @media (min-width: $screen-md-min) {
      @include make-sm-column(6);
      padding-right: 0;
    }

    [class^="icon-"] {
      color: $brand-secondary;
      top: -2px;
    }
  }

  &__result-value {
    display: inline-block;
    padding-left: 15px;
    max-width: 250px;

    @media (min-width: $screen-md-min) {
      padding-left: 5px;
    }

    &--email {
      display: inline-block;
      padding-left: 15px;
      max-width: 250px;
      margin-top: 5px;

      @media (min-width: $screen-md-min) {
        padding-left: 5px;
      }
    }
  }

  &__photo {
    @include make-xs-column(12);
    @include make-sm-column(5);
    @include make-md-column(4);
    @extend .pull-right;
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      padding-top: 90px;
      padding-left: 15px;
      padding-right: 30px;
      margin-bottom: 0;
    }

    @media (min-width: $screen-md-min) {
      padding-top: 30px;
    }

    @media (min-width: $screen-lg-min) {
      padding-top: 15px;
    }

    img {
      border-radius: 50%;
      width: 100%;
      height: auto;
      display: inline-block;

      @media (min-width: $screen-xs-min) {
        width: 60%;
      }

      @media (min-width: $screen-sm-min) {
        width: 100%;
      }

      @media (min-width: $screen-lg-min) {
        width: 90%;
      }
    }
  }
}

// Publications listing for Academic PROFILEs
.publications {
  h2 {
    @extend .h2--underline;
    margin-top: 0;
  }

  ul {
    @include font-size(1.8);
    line-height: 125%;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;

    @media (min-width: $screen-lg-min) {
      @include font-size(2);
    }

    li {
      margin-bottom: 5px;
      margin-top: 5px;

      a {
        @include font-karla-bold();
        border-bottom: none;

        &:hover,
        &:focus {
          border-bottom: 1px solid $text-color;
          color: $text-color;
        }
      }
    }
  }

  p {
    margin-top: 5px;
  }
}

//Senior management profile
.masthead-profile-gen {
  &__container {
    @extend .masthead-profile__container;
    @extend .container;
    color: $text-color;
    @include make-xs-column(12);

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(12);
    }

    p {
      padding-left: 0 !important;
    }
  }

  &__header {
    @include make-xs-column(12);
    color: $text-color;

    @media (min-width: $screen-xs-min) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(8);
    }

    @media (min-width: $screen-md-min) {
      @include make-md-column(9);
    }

    a {
      color: $text-color;
      border-bottom: 1px solid $text-color;

      &:hover,
      &:focus {
        border-bottom: none;
      }
    }

    h3 {
      text-transform: none;
      padding-left: 0 !important;
    }
  }

  &__photo {
    @include make-xs-column(12);
    padding-left: 30px !important;
    margin-top: 0;
    padding-bottom: 15px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(4);
      padding-left: 0 !important;
      padding-right: 0;
    }

    @media (min-width: $screen-md-min) {
      @include make-md-column(3);
      padding-right: 0;
    }

    img {
      border-radius: 100%;
      width: 65%;
      display: inline-block;

      @media (min-width: $screen-md-min) {
        width: 100%;
      }
    }
  }
}