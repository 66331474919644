//
// Breadcrumbs
// --------------------------------------------------
.breadcrumb {
    @include font-size(1.2);
    @extend .hidden-xs;
    background-color: $breadcrumb-bg;
    border-radius: 0 !important;
    display: inline-block;
    line-height: 100%;
    margin: 0;

    > li {
        display: inline-block;
        // Reset default styling
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;

        &:before {
            border-left: none;
            display: inline;
            left: auto;
            position: relative;
            top: auto !important;
        }

        + li:before {
            content: ">"; // Unicode space added since inline-block means non-collapsing white-space
            padding-left: 6px;
            padding-right: 10px;
        }

        a {
            border-bottom: none;
            color: $breadcrumb-link-color;
            text-decoration: underline;

            &:hover,
            &:focus,
            &.focus {
                color: $breadcrumb-link-hover-color;
                text-decoration: none;
            }
        }
    }

    > .active {
        color: $breadcrumb-active-color;
    }
}
