//
// Tables
// --------------------------------------------------
.table {

    // Cells
    >thead,
    >tbody,
    >tfoot {
        >tr {
            >th {
                @include font-karla-bold();
                @extend .text-uppercase;
                background-color: $table-head-bg;
                border-width: 2px;
                color: $table-head-color;
                font-weight: normal;
            }

            >td {
                @include font-size(1.5);
                border-width: 2px;
                line-height: 100%;
                white-space: normal;
            }
        }
    }
}


//Fix transparent background on tables on iPhone
.table-responsive {
    background-color: $white;

    @media (min-width: $screen-sm-min) {
        background-color: transparent;
    }
}

//Use this for specific rows or columns where yellow text on dark background.
th.dark,
td.dark {
    @include font-karla-bold();
    background-color: $table-dark-head-bg;
    color: $table-dark-head-color;
    line-height: 150% !important;
}

// Dark version of table
.table-dark {

    // Cells
    >thead,
    >tbody,
    >tfoot {
        >tr {
            @include font-size(1.5);

            >th {
                @extend .text-capitalize;
                color: $table-dark-head-color;
                background-color: $table-dark-head-bg;
                padding: 15px;
                vertical-align: text-top;
            }

            >td {
                padding: 15px;
            }
        }
    }
}


.table-narrow {
    @media (min-width: $screen-md-min) {
        width: 600px;
        margin: 0 auto;
    }
}

.table-centered {
    text-align: center;
}

.table-col-sm {
    text-align: left;

    @media (min-width: $screen-md-min) {
        width: 150px;
    }
}

// Table for Open Day listings
.table-open-days {
    >thead {
        >tr {
            >th {
                &:nth-child(1) {
                    width: 1px;
                    padding: 0;
                }

                &:nth-child(2) {
                    text-align: center;
                }
            }
        }
    }

    >tbody {
        >tr {
            cursor: pointer;

            >td {
                vertical-align: middle;
                padding: 0;

                &:nth-child(1) {
                    width: 1px;
                    padding: 0;
                }

                &:nth-child(2) {
                    text-align: center;
                }

                &:nth-child(3) {
                    @include font-karla-bold();
                }

                &:nth-child(5) {
                    white-space: normal;
                }

                &:nth-child(6) a {
                    text-align: center;
                    width: 90%;
                    padding: 10px;

                    &::after {
                        display: none;

                        @media (min-width: $screen-sm-min) {
                            display: block;
                        }
                    }
                }

                a {
                    display: block;
                    color: $brand-primary;
                    padding: 20px 15px;
                }

                ul {
                    padding-left: 0;

                    li {
                        &::before {
                            left: -10px;
                        }
                    }
                }
            }
        }
    }
}