//
// Responsive table type layouts using divs
// --------------------------------------------------
// Open Days Table style listing-item
// -----------------------------------
// Open Days "Table" Buttons to Full Display on Tablet and Desktop //
.tabled-titles {
  display: none;

  @media (min-width: $screen-sm-min) {
    @include make-md-column(12);
    @include no-padding();
    @include font-karla-bold();
    text-transform: uppercase;
    background-color: $grey-light;
    display: block;
    overflow: hidden;
    clear: both;
    padding-top: 15px;
    padding-bottom: 15px;

    .dates {
      @include make-xs-column(1);
      padding-left: 15px;
    }

    .type {
      @include make-xs-column(5);
      padding-left: 80px;
    }

    .time {
      @include make-xs-column(4);
      padding-left: 45px;
    }
  }
}

.tabled-heading {
  background-color: $brand-primary;
  margin: 0;
  position: relative;
  cursor: pointer;
  outline: 0;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 3px solid $grey-light;

  &:after {
    //arrow for small screen
    @include after-arrow($brand-secondary, 15px, 15px, 4px, rotate(45deg), 20px, 70px);
    //Arguments - $color, $heightwidth, $heightwidth, $border-width, $transform, $left, $top
  }

  // .tabled-heading @768px wide
  @media (min-width: $screen-sm-min) {
    @include make-sm-column(6);
    @include no-padding();
    background-color: $white;
    display: inline-block;
    float: left;
    cursor: default;
    border-bottom: none;

    &:after {
      //don't display the after-arrow
      display: none;
      content: "";
    }
  }

  &:hover,
  &:focus {
    background-color: $grey-dark;

    p {
      a {
        color: $brand-primary;
        max-width: 680px;
      }
    }

    // .tabled-heading &:hover, &:focus
    @media (min-width: $screen-sm-min) {
      background-color: $white;
    }
  }

  //Classes specifically for Open Days table listing
  .dates {
    text-align: center;
    line-height: 110%;
    width: 100%;
    display: block;

    &:hover {
      background-color: $grey-dark;
    }

    @media (min-width: $screen-sm-min) {
      @include make-sm-column(3);
      padding-left: 15px;
      //  padding-top: 15px;
      //  padding-bottom: 30px;
      float: left;
      display: inline-block;
      background-color: $white;
      vertical-align: middle;

      &:hover {
        background-color: transparent;
      }
    }

    p {
      &:hover {
        //  color: $brand-secondary;
        background-color: $grey-dark;
      }

      @media (min-width: $screen-sm-min) {
        @include font-karla-bold();
        @include font-size(3);
        text-align: center;
        position: relative;
        display: block;
        margin-top: 0;

        &:hover {
          background-color: transparent;
        }
      }
    }

    // Ellipses with dates to be shown on @992px wide and hidden on mobile
    span {
      display: none;

      @media (min-width: $screen-sm-min) {
        display: block;
        position: relative;
        left: auto;
        margin-top: 15px
      }

      .ellipse,
      .ellipse__small,
      .ellipse__colour--secondary,
      .ellipse__text {
        display: none;

        @media (min-width: $screen-sm-min) {
          display: block;
        }
      }

      .dates-txt {
        line-height: 30%;
        padding-top: 30px;
      }
    }

    //Show this class on mobile and hide on desktop
    .mobiledate {
      @include font-karla-bold();
      @include font-size(3);
      padding-top: 10px;
      color: $brand-secondary;

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }

    .mobiletype {
      @include font-karla-bold();
      @include font-size(1.6);
      padding-bottom: 5px;
      color: $white;
      border-bottom: none;
      padding-left: 30px;
      padding-right: 40px;

      a {
        color: $white;
        border-bottom: none;
      }

      @media (min-width: $screen-xs-min) {
        padding-left: 60px;
        padding-right: 60px;
      }

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }

    .mobiletime {
      @include font-karla-regular();
      @include font-size(1.6);
      padding-bottom: 5px;
      color: $white;
      border-bottom: none;
      padding-left: 30px;
      padding-right: 30px;

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
  }

  //Show this class on desktop and hide on mobile
  .type {
    display: none;

    @media (min-width: $screen-sm-min) {
      @include font-karla-bold();
      @include make-sm-column(9);
      padding-left: 40px;
      padding-right: 5px;
      padding-top: 45px;
      padding-bottom: 30px;
      float: left;
      display: inline-block;
    }
  }
}

//
.tabled-body {
  display: none !important;

  .time,
  .info {
    display: none;
  }

  @media (min-width: $screen-sm-min) {
    @include make-sm-column(6);
    display: inline-block !important;
    background-color: $white;
    padding-left: 0;
    padding-right: 0;
    float: left;
    text-align: center;

    .time {
      @include make-xs-column(5);
      @include no-padding();
      display: block;
    }

    .info {
      @include make-xs-column(7);
      @include no-padding();
      display: block;
    }
  }

  p {
    &:last-child {
      margin: 0;
    }
  }
}

// "Table-cell" type styling for each category
.tabled-stacked {
  display: none;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    float: left;
    border: none;
    padding-top: 45px;
    padding-bottom: 30px;
    background-color: $white;
  }

  .stacked-label {
    @include font-karla-bold();
    text-transform: uppercase;
    color: $grey-dark;

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
}

//Turquoise More Info with primary button on desktop view
.panel-more-info {
  background-color: $brand-primary;
  padding: 30px;
  display: block;
  color: $white;

  &:hover {
    background-color: $grey-dark;
  }

  @media (min-width: $screen-sm-min) {
    background-color: $white;
    color: $white;

    &:hover {
      background-color: $white;
    }
  }

  a.txt-link {
    @include font-karla-bold;
    @include font-size(2);
    display: block;
    padding: 15px 30px;
    width: 100%;
    text-align: center;
    color: $white;
    text-transform: uppercase;

    &:hover {
      background-color: $grey-dark;
      color: $brand-secondary;
    }

    @media (min-width: $screen-sm-min) {
      background-color: $white;
      display: none;
    }
  }

  .btn,
  .btn-primary,
  .btn-sm {
    display: none;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }

  &__disabled {
    pointer-events: none;
    background-color: $grey-light;
    color: $grey;
    opacity: 0.65;
  }
}

//End Buttons to Full Width for Booking Table
/// Country Visits mock table based on Open Days table with one extra column
//Headers for Country Visit listing
.country-visit-titles {
  @extend .tabled-titles;

  @media (min-width: $screen-sm-min) {
    .dates {
      @include make-xs-column(1);
      padding-left: 15px;
    }

    .type {
      @include make-xs-column(4);
      padding-left: 80px;
    }

    .time {
      @include make-xs-column(2);
      padding-left: 45px;
    }

    .city {
      @include make-xs-column(2);
      padding-left: 60px;
    }
  }
}

//Country Visit heading
.country-visit-heading {
  @extend .tabled-heading;

  @media (min-width: $screen-sm-min) {
    @include make-sm-column(5);
    padding-left: 15px;
    padding-right: 15px;

    .dates {
      padding-left: 0;
      padding-top: 15px;
    }

    .type {
      padding-left: 45px;
      padding-right: 0;
    }
  }

  @media (min-width: $screen-md-min) {
    padding-left: 30px;
  }

  .mobilecity {
    @extend .mobiletime;
  }
}

//Country Visit body
.country-visit-body {
  @extend .tabled-body;

  .city {
    display: none;
  }

  @media (min-width: $screen-sm-min) {
    @include make-sm-column(7);
    padding-left: 0;
    padding-right: 15px;

    .time {
      @include make-xs-column(4);
      @include no-padding();
      display: block;
    }

    .city {
      @include make-xs-column(4);
      padding-left: 30px;
      padding-right: 0;
      display: block;
      text-align: left;
    }

    .info {
      @include make-xs-column(4);
      @include no-padding();
      display: block;
    }
  }
}

// Country Visit stacked
.country-visit-stacked {
  @extend .tabled-stacked;
}

/// Table type layout
// Use in conjunction with Multicolumn Modifiers in _utilities.scss
.container-flex {
  //Not always suitable as too prescriptive over cell widths etc
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cell-titles {
  // Uppercase labels used on desktop display
  @include font-karla-bold;
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    @include font-size(1.1);
  }

  @media (min-width: $screen-md-min) {
    @include font-size(1.2);
  }

  @media (min-width: $screen-lg-min) {
    @include font-size(1.4);
  }
}

.cell-label {
  // Uppercase labels that appear only on smaller widths
  @include font-karla-bold();
  //  position: absolute;
  //  top: 3px;
  //  left: 10px;
  text-transform: uppercase;

  //  line-height: 25px;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.cell-clear {
  // Transparent "cell"
  display: none;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}

.no-cell {
  //transparent cell
  @include no-padding;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 10px;
  color: transparent;
}

.cell-head {
  @include font-karla-bold();
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $table-head-bg;
  border-right: 2px solid $body-bg;
  text-transform: uppercase;
  display: none;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }

  @media (min-width: $screen-md-min) {
    display: inline-block;
    text-align: left;
  }
}

.cell-dark {
  //dark cell with yellow text
  background-color: $grey-dark;
  color: $brand-secondary;
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 2px solid $body-bg;
  float: left;
  display: inline-block;

  @media (min-width: $screen-sm-min) {
    padding-top: 15px;
  }
}

.cell-light {
  //light cell with dark grey text
  @include font-size(1.4);
  position: relative;
  background-color: $white;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 2px solid $body-bg;
  border-right: 2px solid $body-bg;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    float: left;
    padding-top: 15px;
  }
}

.first-col {
  // First column in "table"
  @include font-karla-bold();
  @extend .cell-light;
  text-transform: uppercase;
  background-color: $table-head-bg;

  @media (min-width: $screen-sm-min) {
    text-transform: none;
    background-color: $white;
  }
}