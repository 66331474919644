//::::::::::::::::::::::::::::::
//:: Icon Fonts
//::::::::::::::::::::::::::::::
//:: Icons must be defined within a SASS list variable named '$icon-set'. Example below:
//:: $icon-set:  facebook e600, twitter e601;
//::::::::::::::::::::::::::::::

//::::::::::::::::::::::::::::::
//:: Default Variables
/*   The library definition. This is set in the iconmoon prefeences. Default is 'icomoon' */
$icon-font-library:             'icomoon' !default;

/*   The SASS list that powers everything. Derived from the css files provided by icomoon.  */
$icon-set:                      facebook e600, twitter e601 !default;

/*   The directory that the specific font files reside in.  */
$fonts-directory:               "../fonts/"; //'fonts' !default;

/*   Determine if CSS psuedo-elements will be using ::before, ::after, or both */
$iconfont-before-support:       true !default;
$iconfont-after-support:        false !default;

/*   Iconfonts are often links. Should these links underline by default or on hover. */
$icon-link-underline-static:    false !default;
$icon-link-underline-on-hover:  false !default;


//::::::::::::::::::::::::::::::
//:: Fontface
//:: Defining the @font-face decalartion. $icon-font-library and $fonts-directory are substituted in where necessary
@font-face {
  font-family: '#{$icon-font-library}';
  src:url('#{$fonts-directory}/#{$icon-font-library}.eot');
  src:url('#{$fonts-directory}/#{$icon-font-library}.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-directory}/#{$icon-font-library}.woff') format('woff'),
    url('#{$fonts-directory}/#{$icon-font-library}.ttf') format('truetype'),
    url('#{$fonts-directory}/#{$icon-font-library}.svg##{$icon-font-library}') format('svg');
  font-weight: normal;
  font-style: normal;
}


//::::::::::::::::::::::::::::::
//:: Icon Base
[class^="icon-"],
[class*=" icon-"],
%icon-font-base {  // Silent class that can be used to exend any element that does not have the class of 'icon-'

  &:before,
  &:after,
  .pseudo-after,
  .pseudo-before {
    font-family: '#{$icon-font-library}';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;

    @if $icon-link-underline-static {
      text-decoration: underline;
    }
    @else {
      text-decoration: none;
    }

    &:hover {
      @if $icon-link-underline-on-hover {
        text-decoration: underline !important;
      }
      @else {
        text-decoration: none !important;
      }
    }
  }
}

//::::::::::::::::::::::::::::::
//:: Definitions
@each $icon in $icon-set {
  $icon-name: nth($icon, 1);
  $icon-code: nth($icon, 2);

  @if $iconfont-before-support { // Does this implementation include '::before' support
    .icon-#{$icon-name},
    %icon-#{$icon-name} {
      &:before {
        content: "\#{$icon-code}";
      }
    }
  }
  @if $iconfont-after-support { // Does this implementation include '::after' support
    .icon-#{$icon-name}-r,
    %icon-#{$icon-name}-r {
      &:after {
        content: "\#{$icon-code}";
      }
    }
  }
}

// Icomoon icons
// -------------------------

.icon-tiktok:before {
  content: "\e900";
}
.icon-search:before {
  content: "\e600";
}
.icon-portcullis:before {
  content: "\e601";
}
.icon-burger:before {
  content: "\e602";
}
.icon-location:before {
  content: "\e603";
}
.icon-clock:before {
  content: "\e604";
}
.icon-close:before {
  content: "\e605";
}
.icon-calendar:before {
  content: "\e606";
}
.icon-quote-left:before {
  content: "\e607";
}
.icon-download:before {
  content: "\e608";
}
.icon-share:before {
  content: "\e609";
}
.icon-eye:before {
  content: "\e60a";
}
.icon-quote-right:before {
  content: "\e60b";
}
.icon-pound:before {
  content: "\e60c";
}
.icon-academic-cap:before {
  content: "\e901";
}
.icon-campus:before {
  content: "\e902";
}
.icon-idea-bulb:before {
  content: "\e903";
}
.icon-replay:before {
  content: "\e904";
}
.icon-disabled-access:before {
  content: "\e905";
}
.icon-capacity:before {
  content: "\e906";
}
.icon-compass:before {
  content: "\e907";
}
.icon-ruler-pencil:before {
  content: "\e908";
}
.icon-french:before {
  content: "\e909";
}
.icon-apple:before {
  content: "\e90a";
}
.icon-calculator:before {
  content: "\e90b";
}
.icon-cogs:before {
  content: "\e90c";
}
.icon-chat:before {
  content: "\e90d";
}
.icon-film:before {
  content: "\e90e";
}
.icon-english:before {
  content: "\e90f";
}
.icon-puzzle:before {
  content: "\e910";
}
.icon-briefcase:before {
  content: "\e911";
}
.icon-atom:before {
  content: "\e912";
}
.icon-hourglass:before {
  content: "\e913";
}
.icon-checkmark:before {
  content: "\e914";
}
.icon-airplane:before {
  content: "\e915";
}
.icon-books:before {
  content: "\e916";
}
.icon-russian:before {
  content: "\e917";
}
.icon-romanian:before {
  content: "\e918";
}
.icon-spanish:before {
  content: "\e919";
}
.icon-swedish:before {
  content: "\e91a";
}
.icon-turkish:before {
  content: "\e91b";
}
.icon-portuguese:before {
  content: "\e91c";
}
.icon-norwegian:before {
  content: "\e91d";
}
.icon-japanese:before {
  content: "\e91e";
}
.icon-italian:before {
  content: "\e91f";
}
.icon-hungarian:before {
  content: "\e920";
}
.icon-german:before {
  content: "\e921";
}
.icon-finnish:before {
  content: "\e922";
}
.icon-farsi:before {
  content: "\e923";
}
.icon-dutch:before {
  content: "\e924";
}
.icon-danish:before {
  content: "\e925";
}
.icon-croatian:before {
  content: "\e926";
}
.icon-chinese:before {
  content: "\e927";
}
.icon-cantonese:before {
  content: "\e928";
}
.icon-bulgarian:before {
  content: "\e929";
}
.icon-scales:before {
  content: "\e92a";
}
.icon-arabic:before {
  content: "\e92b";
}
.icon-accommodation_location:before {
  content: "\e92c";
}
.icon-google-plus:before {
  content: "\e92d";
}
.icon-facebook:before {
  content: "\e92e";
}
.icon-instagram:before {
  content: "\e92f";
}
.icon-twitter:before {
  content: "\e930";
}
.icon-weibo:before {
  content: "\e931";
}
.icon-youtube:before {
  content: "\e932";
}
.icon-linkedin:before {
  content: "\e933";
}
.icon-portcullis_arrow:before {
  content: "\e934";
}
.icon-home:before {
  content: "\e935";
}
.icon-pencil:before {
  content: "\e936";
}
.icon-image:before {
  content: "\e937";
}
.icon-shopping-basket:before {
  content: "\e938";
}
.icon-book:before {
  content: "\e939";
}
.icon-minus:before {
  content: "\e93a";
}
.icon-library:before {
  content: "\e93b";
}
.icon-file-text:before {
  content: "\e93c";
}
.icon-plus:before {
  content: "\e93d";
}
.icon-wrench:before {
  content: "\e93e";
}
.icon-icon-toolbox:before {
  content: "\e93f";
}
.icon-elements:before {
  content: "\e940";
}
.icon-lifebuoy:before {
  content: "\e941";
}
.icon-phone:before {
  content: "\e942";
}
.icon-map:before {
  content: "\e94b";
}
.icon-database:before {
  content: "\e964";
}
.icon-credit-card:before {
  content: "\e97b";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-cog:before {
  content: "\e994";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-toolbox:before {
  content: "\e9ae";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-smile:before {
  content: "\e9e2";
}
.icon-sad:before {
  content: "\e9e6";
}
.icon-neutral:before {
  content: "\e9f8";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-info-enclosed:before {
  content: "\ea0c";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-stop:before {
  content: "\ea1e";
}
.icon-backward:before {
  content: "\ea1f";
}
.icon-forward:before {
  content: "\ea20";
}
.icon-previous:before {
  content: "\ea23";
}
.icon-next:before {
  content: "\ea24";
}
.icon-google-drive:before {
  content: "\ea8f";
}
.icon-wordpress:before {
  content: "\eab4";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows8:before {
  content: "\eac2";
}
.icon-soundcloud:before {
  content: "\eac3";
}
.icon-skype:before {
  content: "\eac5";
}
.icon-paypal:before {
  content: "\ead8";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-info:before {
  content: "\f129";
}
.icon-exclamation:before {
  content: "\f12a";
}
.icon-calendar-plus:before {
  content: "\f271";
}
.icon-map-signs:before {
  content: "\f277";
}
.icon-id-card:before {
  content: "\f2c2";
}
