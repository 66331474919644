// Course overview
.course-overview,
.details-centered {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    &__filter-wrapper {
        @media (min-width: $screen-sm-min) {
            text-align: center;
        }
    }

    &__filter {
        display: inline-block;
        margin-left: 17px;
        margin-right: 15px;

        @media (min-width: $screen-sm-min) {
            margin-left: 10px;
            margin-right: 10px;
        }

        .selectboxit-container {
            @extend .pull-left;
            clear: both;
        }
    }

    &__filter-title {
        @extend .pull-left;
        @extend .text-left;
        @extend .text-uppercase;
        clear: both;
        color: $grey-dark;
        margin-bottom: 5px;
    }

    &__results {
        background-color: white;
        display: inline-block;
        margin-top: 10px;
        padding-bottom: 5px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 25px;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            text-align: center;
            margin-top: 15px;
            padding-bottom: 15px;
            padding-top: 20px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 20px;
        }
    }

    &__result-set {
        display: inline-block;
        margin-bottom: 5px;
        padding-left: 30px;
        position: relative;
        vertical-align: top;

        @media (min-width: $screen-sm-min) {
            padding-left: 0;
        }

        @media (min-width: $screen-md-min) {
            margin-left: 5px;
            margin-right: 5px;
        }

        @media (min-width: $screen-lg-min) {
            margin-left: 10px;
            margin-right: 10px;
        }

        [class^="icon-"] {
            @include font-size(2.5);
            color: $course-overview-icon-color;
            display: inline-block;
            left: 0;
            position: absolute;
            top: 0;
            vertical-align: top;

            @media (min-width: $screen-sm-min) {
                @include font-size(2.0);
                left: auto;
                position: relative;
                top: auto;
            }

            @media (min-width: $screen-md-min) {
                @include font-size(2.8);
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(3.0);
            }
        }
    }

    &__result {
        @extend .text-uppercase;
        display: inline-block;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 0;
            padding-left: 5px;
            padding-right: 5px;
        }

        @media (min-width: $screen-md-min) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @media (min-width: $screen-lg-min) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__result-title {
        @include font-size(1.2);
        @extend .pull-left;
        clear: both;
        color: $grey-dark;
        line-height: 100%;

        @media (min-width: $screen-sm-min) {
            @include font-size(1.0);
        }

        @media (min-width: $screen-md-min) {
            @include font-size(1.2);
        }
    }

    &__result-value {
        @include font-karla-bold();
        @include font-size(1.2);
        @extend .pull-left;
        clear: both;
        line-height: 100%;
        margin-top: 5px;
        text-align: left;

        @media (min-width: $screen-sm-min) {
            @include font-size(1.1);
        }

        @media (min-width: $screen-md-min) {
            @include font-size(1.3);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(1.6);
        }
    }

    &__result-sm {
        @extend .details-pane__result-value;
        @include font-karla-bold();
        text-transform: none !important;
        max-width: 300px;
        white-space: normal;
        max-width: 230px;
    }

    &__footnote {
        @extend .text-center;
        width: 100%;
    }
}

// Course listing buttons
.course-listing-buttons {
    margin-bottom: 30px;

    .btn {
        margin-bottom: 5px;
        margin-right: 5px;
    }
}
