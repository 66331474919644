//
// Listings
// --------------------------------------------------


// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  // No need to set list-style: none; since .list-group-item is block level
  margin-bottom: 20px;
  padding-left: 0; // reset padding because ul and ol
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.listing-item {
  position: relative;
  display: block;
  padding: 10px 0;
  // Place the border on the list items and negative margin up for better styling
  margin-bottom: -1px;
  border-bottom: 1px solid $list-group-border;
}


// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive items.
// Includes an extra `.active` modifier class for showing selected items.

a.listing-item,
button.listing-item {
  color: $text-color;

  .listing-item-heading {
    color: $link-color;
  }

  // Hover state
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $white;
  }
}

.listing-item {

  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $list-group-disabled-bg;
    color: $list-group-disabled-color;
    cursor: $cursor-disabled;

    // Force color to inherit for custom content
    .listing-item-heading {
      color: inherit;
    }

    .listing-item-text {
      color: $list-group-disabled-text-color;
    }
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: $link-hover-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border;

    // Force color to inherit for custom content
    .listing-item-heading,
    .listing-item-heading>small,
    .listing-item-heading>.small {
      color: inherit;
    }

    .listing-item-text {
      color: $list-group-active-text-color;
    }
  }
}


// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@include list-group-item-variant(success, $state-success-bg, $state-success-text);
@include list-group-item-variant(info, $state-info-bg, $state-info-text);
@include list-group-item-variant(warning, $state-warning-bg, $state-warning-text);
@include list-group-item-variant(danger, $state-danger-bg, $state-danger-text);


// Custom content options
//
// Extra classes for creating well-formatted content within `.list-group-item`s.

.listing-item-heading {
  margin-top: 0;
  margin-bottom: 5px;

  @include font-karla-bold();

  span {
    @extend .pull-right;
  }
}

.listing-item-text {
  margin-bottom: 0;
  line-height: 1.5;
}

.stacked-listing {

  padding-left: 0;
  clear: both;


  li {
    @include font-size(2);
    border-bottom: 1px solid $grey-light;
    vertical-align: bottom;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0;
    line-height: 2em;
    clear: both;
    min-height: 65px;

    &:hover,
    &:focus {
      background-color: $brand-secondary;
      color: $grey-dark;
    }

    &:before {
      display: none;
    }

    a {
      border-bottom: none;
    }

    span {
      margin-left: 10px;
      float: left;

      img {
        max-width: 75px;
        margin-right: 30px;
      }

    }
  }
}

// Pseudo listings in grid display using rows and divs
// -----------------------------------------------------

.pseudo-list {
  @extend ul;

  &__item {
    @extend li;
    margin-bottom: 15px !important;
  }
}
