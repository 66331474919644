﻿//
// Miscellaneous styles
// --------------------------------------------------
.paragraph--type--useful-website-links {
  @include clearfix();
  margin-bottom: 45px;

  .section-header {
    margin-top: 10px;
  }
}

// IN PAGE NOTIFICATIONS (USED FOR COURSE APPROVAL MSG)
.notification {
  @include font-size(1.6);
  text-align: center;
  @extend .col-xs-12,
  .col-md-offset-3,
  .col-md-6;
  padding: 10px;
  margin-bottom: 30px;

  h4 {
    margin-top: 10px;
  }

  a {
    color: $grey-dark;
    border-bottom: solid 1px $grey-dark;

    &:hover {
      border-bottom: 0px;
    }
  }

  &__yellow {
    background-color: $brand-secondary;
    padding: 10px;
  }

  &__turquoise {
    background-color: $brand-primary;
    color: $white;

    a {
      color: $white;
      border-bottom: solid 1px $white;
    }
  }
}

// Notification block for main content area
.main-content-notification {
  background-color: $white;
  margin-top: 120px;
  padding: 30px;
  margin-bottom: 30px;
}