//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed;
  //Override Bootstrap container padding for mobile screens
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
    //Set padding back to Bootstrap inherant padding for larger screens
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include container-fixed;
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row;
  //Override Bootstrap .row margin for mobile screens
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: $screen-sm-min) {
    @include make-row;
  }
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

[class='col-xs-'] {
  @include make-grid(xs);
  padding-right: 15px;
  padding-left: 15px;
}

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
  // The following lines come up as an error in gulp sass task
  // padding-right: 30px;
  // padding-left: 30px;
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}


//Override Bootstrap Col grid classes for smaller padding on mobile width
[class^="col-"], .notification {
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: $screen-xs-min) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

// Generate the extra small columns
//Override Bootstrap Col grid classes for smaller padding on mobile width
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  15px;
  padding-right: 15px;
}
@mixin make-xs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}
