﻿/* -----------------------------------
 * Accordions used in the site
 */

// Bootstrap Collapsable panels (aka, accordion)
.panel-group {
    margin-bottom: 30px;
    margin-top: 30px;
    p {
        margin-top: 15px;
    }
    .panel {
        background-color: $panel-group-bg;
        border-radius: 0;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 5px;
        +.panel {
            margin-top: 2px;
            margin-bottom: 5px;
        }
    }
    .panel-heading {
        background-color: $panel-group-header-bg;
        padding: 0;
        &:hover {
            background-color: $panel-group-header-hover-bg;
        }
        +.panel-collapse>.panel-body,
        +.panel-collapse>.list-group {
            border-top: none;
        }
        .panel-title {
            @include font-karla-bold();
            @include font-size(1.4);
            line-height: 125%;
            @media (min-width: $screen-sm-min) {
                @include font-size(1.5);
            }
            @media (min-width: $screen-md-min) {
                @include font-size(1.6);
            }
            a,
            button {
                color: $panel-group-header-color;
                display: inline-block;
                border: none;
                background: transparent;
                padding-bottom: 15px;
                padding-left: 15px;
                text-align: left;
                padding-right: 40px;
                padding-top: 15px;
                position: relative;
                text-decoration: none;
                width: 100%;
                &:after {
                    @include transform(translateY(-50%));
                    @include triangle-bottom($panel-group-header-color, 5px, 10px);
                    content: "";
                    display: inline-block;
                    color: $brand-secondary;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                }
            }
        }
        &.active-panel {
            background-color: $panel-group-header-active-bg;
            a,
            button {
                color: $panel-group-header-active-color;
                &:after {
                    @include triangle-top($panel-group-header-active-color, 5px, 10px);
                }
            }
        }
    }
    .panel-body {
        padding-bottom: 30px;
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        @media (min-width: $screen-sm-min) {
            padding-left: 30px;
            padding-right: 30px;
        }
        p {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .panel-footer {
        +.panel-collapse .panel-body {
            border-bottom: none;
        }
    }
}

// Accordion to Full Display on Tablet and Desktop //
.strip-titles {
    display: none;
    @media (min-width: $screen-md-min) {
        @include make-md-column(11);
        @include no-padding();
        @include font-karla-bold();
        text-transform: uppercase;
        display: block;
        overflow: hidden;
        clear: both;
        margin-bottom: 15px;
        text-align: center;
        .dates {
            @include make-xs-column(2);
            width: 18%;
            padding-left: 65px;
        }
        .level {
            @include make-xs-column(2);
            width: 15%;
            padding-left: 50px;
        }
        .time {
            @include make-xs-column(2);
            padding-right: 0;
        }
        .day-time-lang {
            @include make-xs-column(3);
            //    padding-right: 0;
            width: 24%;
        }
        .duration {
            @include make-xs-column(1.5);
            padding-left: 5px;
            padding-right: 0;
        }
        .cpd {
            @include make-xs-column(1);
            @include no-padding();
            padding-left: 10px;
        }
        .price {
            @include make-xs-column(1);
            padding-left: 0px;
            padding-right: 15px;
            letter-spacing: 0.0001em;
        }
        .price-lang {
            @include make-xs-column(2);
            padding-left: 5px;
            padding-right: 5px;
            letter-spacing: 0.0001em;
        }
        .early-bird {
            @include make-xs-column(1);
            //  @include no-padding();
            padding-left: 10px;
        }
        .book {
            @include make-xs-column(1);
        }
    }
}

.strip-heading {
    padding: 1em;
    margin: 0;
    position: relative;
    cursor: pointer;
    outline: 0;
    color: $white;
    padding: 15px;
    &:after {
        //arrow for small screen
        @include after-arrow($brand-secondary, 15px, 15px, 4px, rotate(135deg), 20px, 50px);
        //Arguments - $color, $heightwidth, $heightwidth, $border-width, $transform, $left, $top
        @media (min-width: $screen-md-min) {
            display: none;
            content: "";
        }
    }
    @media (min-width: $screen-md-min) {
        @include make-md-column(2);
        display: inline-block;
        float: left;
        cursor: default;
    }
    &:hover {
        background-color: $grey-dark;
    }
    &.active {
        background-color: $brand-primary;
        color: $panel-group-header-active-color;
        &:after {
            //arrow for small screen
            @include after-arrow($brand-secondary, 15px, 15px, 4px, rotate(-45deg), 18px, 40px);
            //Arguments - $color, $heightwidth, $heightwidth, $border-width, $transform, $left, $top
            @media (min-width: $screen-md-min) {
                display: none;
                content: "";
            }
        }
    }
}

.strip-body {
    overflow: hidden;
    @media (min-width: $screen-md-min) {
        @include make-md-column(10);
        padding-left: 0;
        padding-right: 0;
        display: inline-block;
        float: left;
        .level {
            @include make-sm-column(2);
            @include no-padding();
            padding-top: 35px;
            a {
                @include font-karla-regular();
            }
        }
        .time {
            @include make-sm-column(2);
            @include no-padding();
        }
        .day-time-lang {
            @include make-sm-column(3);
            @include no-padding();
            padding-top: 20px;
            span a {
                @include font-size(1.2);
                text-transform: uppercase !important;
            }
        }
        .duration {
            @include make-sm-column(2);
            @include no-padding();
            padding-top: 35px;
        }
        .cpd {
            @include make-sm-column(1);
            @include no-padding();
        }
        .price {
            @include make-sm-column(1);
            @include no-padding();
            padding-top: 35px;
        }
        .price-lang {
            @include make-sm-column(2);
            @include no-padding();
            padding-top: 35px;
        }
        .early-bird {
            @include make-sm-column(1);
            @include no-padding();
        }
        .book {
            @include make-md-column(3);
            @include no-padding();
            padding-top: 35px;
        }
        //    .book-lang{
        //      @include make-md-column(4);
        //      @include no-padding();
        //    }
    }
    p {
        &:last-child {
            margin: 0;
        }
    }
}

//When .js class is added via JQuery
.js .strip-body {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    //  border-top: 4px $grey-light;
    //  &:last-child {
    //    border-top: none;
    //  }
    &.active {
        max-height: 60em;
    }
    @media (min-width: $screen-md-min) {
        max-height: none;
        &.active {
            max-height: none;
        }
    }
}

//Classes specifically for Short course booking table
.date-heading {
    @include font-karla-bold();
    @include font-size(1.8);
    background-color: $brand-primary;
    text-align: center;
    line-height: 120%;
    vertical-align: middle;
    border-bottom: 1px solid $grey-lighter;
    span {
        @include font-size(3);
        color: $brand-secondary;
    }
}

// "Table-cell" type styling for each category
.strip-stacked {
    background-color: $white;
    text-align: center;
    border-bottom: 1px solid $grey-lighter;
    padding: 20px;
    @media (min-width: $screen-md-min) {
        float: left;
        border-left: 1px solid $grey-lighter;
        padding: 15px;
    }
    p {
        padding-top: 5px;
        margin-bottom: 5px !important;
    }
}

//Yellow Call to Action panel with button
.panel-cta {
    //    background-color: $brand-secondary;
    padding-top: 15px;

    .btn-secondary {
        a {
            margin-top: -10px;
            color: inherit;
            &:hover {
                color: $brand-secondary !important;
            }
        }
    }
    &__disabled {
        pointer-events: none;
        background-color: $grey-light;
        color: $grey;
        opacity: 0.65;
    }
}

//End Accordion to Full Width for Booking Table