

// Web fonts
// -------------------------
@font-face{
    font-family:"Futura LT W01 Book";
    src:url("/resources/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
    src:url("/resources/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),
    url("/resources/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),
    url("/resources/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),
    url("/resources/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"),
    url("/resources/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
}

@font-face{
    font-family:"Futura W01 Bold";
    src:url("/resources/fonts/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix");
    src:url("/resources/fonts/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix") format("eot"),
    url("/resources/fonts/04a4ca93-1306-487e-a524-e798404c93a7.woff2") format("woff2"),
    url("/resources/fonts/2a65cbfc-5071-4b4d-bfc2-e052bb091ee2.woff") format("woff"),
    url("/resources/fonts/812f781d-aa66-47a1-b6a2-7941effc1527.ttf") format("truetype"),
    url("/resources/fonts/2a371030-ff67-40ac-8307-082c1fccf151.svg#2a371030-ff67-40ac-8307-082c1fccf151") format("svg");
}

@font-face{
    font-family:"FuturaW01-HeavyOblique";
    src:url("/resources/fonts/77b66835-3b3a-4573-9896-28f9c45065e0.eot?#iefix");
    src:url("/resources/fonts/77b66835-3b3a-4573-9896-28f9c45065e0.eot?#iefix") format("embedded-opentype"),
    url("/resources/fonts/8d55173a-596a-4af0-89f6-13aeccc0be76.woff2") format("woff2"),
    url("/resources/fonts/f66d3dd0-7261-4072-82e0-b027fbc3e052.woff") format("woff"),
    url("/resources/fonts/26b870c6-9a3b-42f7-aa44-4e6541ccae9d.ttf") format("truetype"),
    url("/resources/fonts/e96bb300-507f-43df-b8f4-3e5dedf1a21b.svg#e96bb300-507f-43df-b8f4-3e5dedf1a21b") format("svg");
}

@font-face {
    font-family: "KarlaRegular";
    src: url("/resources/fonts/KarlaRegular.eot");
    src: url("/resources/fonts/KarlaRegular.eot") format("embedded-opentype"),
    url("/resources/fonts/KarlaRegular.woff2") format("woff2"),
    url("/resources/fonts/KarlaRegular.woff") format("woff"),
    url("/resources/fonts/KarlaRegular.ttf") format("truetype"),
    url("/resources/fonts/KarlaRegular.svg#KarlaRegular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KarlaBold";
    src: url("/resources/fonts/KarlaBold.eot");
    src: url("/resources/fonts/KarlaBold.eot") format("embedded-opentype"),
    url("/resources/fonts/KarlaBold.woff2") format("woff2"),
    url("/resources/fonts/KarlaBold.woff") format("woff"),
    url("/resources/fonts/KarlaBold.ttf") format("truetype"),
    url("/resources/fonts/KarlaBold.svg#KarlaBold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src:url('/resources/fonts/icomoon.eot?-kql2lu');
    src:url('/resources/fonts/icomoon.eot?#iefix-kql2lu') format('embedded-opentype'),
    url('/resources/fonts/icomoon.ttf?-kql2lu') format('truetype'),
    url('/resources/fonts/icomoon.woff?-kql2lu') format('woff'),
    url('/resources/fonts/icomoon.svg?-kql2lu#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
