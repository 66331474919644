//White horizontal Details Pane for
//course overview results and open days details
// Details pane
.details-pane {
  margin-bottom: 20px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-xs-min) {
    margin-bottom: 30px;
  }

  @media (min-width: $screen-sm-min) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &__staff {
    margin-top: 60px;
    margin-bottom: 10px;
  }

  &__filter-wrapper {
    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      text-align: center;
    }
  }

  &__filter {
    display: inline-block;
    margin-left: 10px;
    margin-right: 30px;

    @media (min-width: $screen-sm-min) {
      margin-left: 10px;
      margin-right: 10px;
    }

    .selectboxit-container {
      @extend .pull-left;
      clear: both;
    }
  }

  &__filter-title {
    @extend .pull-left;
    @extend .text-left;
    @extend .text-uppercase;
    clear: both;
    color: $grey-dark;
    margin-bottom: 5px;
    padding-left: 15px;
  }

  &__results {
    margin-top: 30px;
    background-color: white;
    display: inline-block;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 15px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__results-search {
    @extend .details-pane__results;
    margin-top: 0;
  }

  &__results-header {
    @include font-size(1.6);
    @include clearfix();
    border-bottom: 5px solid $brand-secondary;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: $white;
    padding-top: 30px;
    position: relative;

    @media (min-width: $screen-lg-min) {
      @include font-size(1.8);
      padding-left: 30px;
    }
  }

  &__results-header-cs {
    @extend .details-pane__results-header;
    border-bottom: 5px solid $uow-magenta;
  }

  &__results-level {
    @include font-size(1.4);
    text-transform: uppercase;
    background-color: $brand-secondary;
    line-height: 100%;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    position: absolute;
    right: -10px;
    top: -15px;
  }

  &__results-title {
    @extend .pull-left;
    @include font-karla-bold();
    clear: both;
    padding-bottom: 5px;
  }

  &__results-type {
    @extend .pull-left;
    @include font-karla-regular();
    clear: both;
  }

  &__schol-search {
    padding-left: 10px;
    padding-right: 10px;
    background-color: $grey-light;
  }

  &__result-set {
    margin-bottom: 5px;
    padding-left: 30px;
    position: relative;
    vertical-align: top;
    margin-left: 5px;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      padding-left: 0;
    }

    @media (min-width: $screen-md-min) {
      margin-left: 5px;
      margin-right: 5px;
    }

    @media (min-width: $screen-lg-min) {
      margin-left: 11px;
      margin-right: 15px;
    }

    //Colours change for icons in panel
    [class^="icon-"] {
      @include font-size(2.5);
      color: $course-overview-icon-color;
      display: inline-block;
      left: 5px;
      position: absolute;
      top: 0;
      vertical-align: top;

      @media (min-width: $screen-sm-min) {
        @include font-size(2.0);
        left: auto;
        position: relative;
        top: auto;
      }

      @media (min-width: $screen-md-min) {
        @include font-size(2.8);
      }

      @media (min-width: $screen-lg-min) {
        @include font-size(3.0);
      }
    }

    p {
      overflow: hidden;

      @media (min-width: $screen-sm-min) {
        height: 100px;
        width: auto;
        height: auto;
      }
    }
  }

  &__result {
    @extend .text-uppercase;
    display: inline-block;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 0;
      padding-left: 5px;
      padding-right: 5px;
    }

    @media (min-width: $screen-md-min) {
      padding-left: 5px;
      padding-right: 10px;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 5px;
      padding-right: 15px;
    }

    p {
      clear: both;
      padding-top: 10px;
      margin-bottom: 5px !important;
    }
  }

  &__result-title {
    @include font-size(1.2);
    float: left !important;
    clear: both;
    color: $grey-dark;
    line-height: 100%;

    @media (min-width: $screen-sm-min) {
      @include font-size(1.0);
    }

    @media (min-width: $screen-md-min) {
      @include font-size(1.2);
    }

    &--lg {
      @include make-xs-column(12);
      @include font-karla-bold();
      @include font-size(1.8);
      @extend .text-uppercase;
      color: $grey-dark;
      margin-left: 30px;
      display: inline-block;

      @media (min-width: $screen-sm-min) {
        margin-left: 30px;
      }

      @media (min-width: $screen-md-min) {
        @include make-sm-column(3.5);
      }
    }
  }

  &__result-external-site {
    @include font-karla-bold;
    @extend .details-centered__result-title;
    text-transform: uppercase;
    clear: none;
    float: none !important;
    margin-left: 15px;
  }

  &__result-value {
    @include font-karla-bold();
    @include font-size(1.2);
    @extend .pull-left;
    clear: both;
    line-height: 100%;
    margin-top: 5px;
    max-width: 300px;
    text-align: left;

    a {
      @extend .pull-left;
      width: 100%;

      &:hover {
        text-decoration: underline;
        color: $grey-dark;
      }
    }

    @media (min-width: $screen-sm-min) {
      @include font-size(1.1);
    }

    @media (min-width: $screen-md-min) {
      @include font-size(1.3);
    }

    @media (min-width: $screen-lg-min) {
      @include font-size(1.6);
    }
  }

  &__result-sm {
    @extend .details-pane__result-value;
    @include font-karla-bold();
    text-transform: none !important;
    max-width: 300px;
  }

  &__result-weblinks {
    @include make-xs-column(12);
    @extend .details-pane__result-set;
    text-transform: none;

    @media (min-width: $screen-sm-min) {
      margin-left: 30px;
    }

    @media (min-width: $screen-md-min) {
      @include make-sm-column(7);
    }

    //Colours change on hover for icons in panel
    [class^="icon-"] {
      @include font-size(5);
      color: $grey-dark;
      position: relative;
      margin-right: 30px;

      &:hover {
        color: $brand-primary;
      }
    }

    span {
      display: inline-block;
      float: left;
    }

    ul {
      padding-left: 20px;
    }
  }

  &__sites {
    @include font-karla-bold();
    @include font-size(1.4);
    margin-top: 10px;
    margin-right: 30px;

    @media (min-width: $screen-xs-min) {
      margin-right: 15px;
    }

    @media (min-width: $screen-sm-min) {
      @include font-size(1.6);
      display: inline-block;
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      @include font-karla-bold();
      border-bottom: 1px solid $text-color;

      &:hover,
      &:focus {
        border-bottom: none;
      }
    }
  }

  &__cta {
    position: absolute;
    right: 0;
    padding: 10px;
    background-color: $white;
  }

  &__footnote {
    @extend .text-center;
    width: 100%;
  }
}