// Ellipses used as backgrounds for text

.ellipse {
    text-align: center;
    border-radius: 50%;
    display: inline-block;

    &__large {
      @include font-size(3);
      height: 170px;
      width: 170px;

      p {
        padding: 55px;
      }
    }

    &__medium {
      height: 100px;
      width: 100px;
    }

    &__small {
      @include font-size(1.5);
      height: 60px;
      width: 60px;
      margin-bottom: 10px;
      margin-top: -20px;
    }

    &__colour {

      &--primary {
          background-color: $brand-primary;
      }

      &--primary-dark {
          background-color: $brand-primary-dark;
      }

      &--primary-light {
          background-color: $brand-primary-light;
      }

      &--secondary {
          background-color: $brand-secondary;
          color: $grey-dark;
      }

      &--secondary-dark {
          background-color: $brand-secondary-dark;
      }

      &--grey {
          background-color: $grey;
      }

      &--grey-dark {
          background-color: $grey-dark;
      }

      &--grey-light {
          background-color: $grey-light;
      }

      &--grey-lighter {
          background-color: $grey-lighter;
      }

      &--background {
          background-color: $body-bg;
          border: 1px solid $grey;
      }
    }

    &__text {

      text-align: center;

        p {
          @include font-karla-bold();
          margin-top: -5px;
          font-size: 160%;
          line-height: 60%;
          text-align: center;

          span {
            font-size: 60%;
          }
        }
    }
}
