// Subject listing
.subject-listing {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
    @include make-xs-column(12);
    @include make-sm-column(4);
    @include make-md-column(3);

    //arrow for small screen
    @media (max-width: $screen-xs-max) {
        &:after {
            @include after-arrow($grey-dark, 10px, 10px, 3px, rotate(45deg), 55px, 45%);
        }

        //Arguments - $color, $heightwidth, $border-width, $rotation, $right, $top
    }

    @media (min-width: $screen-sm-min) {
        margin-top: 30px;
    }

    &__shortcourse-level {
        @media (min-width: $screen-md-min) {
            @include make-md-column(4);
        }
    }

    &__subject {

        //Offset every other row
        &:nth-of-type(7n+5) {
            @include make-md-column-offset(1.5);
        }

        //    &:last-child {
        //        @include make-md-column-offset(4.5);
        //    }
        a {
            background-color: $subject-listing-bg;
            display: inline-block;
            width: 100%;
            color: $text-color;

            &:hover .subject-listing__subject-name {
                @include transition(background-color 0.7s, color 0.7s);
                background-color: $subject-listing-hover-bg;
                color: $text-color;
            }

            &:hover .subject-listing__subject-icon {
                @include transition(background-color 0.7s, color 0.7s);
                background-color: $grey;
                color: $brand-secondary;

                i {
                    @include transition(background-color 0.7s, color 0.7s);
                    color: $brand-secondary;
                }
            }

            &:hover .subject-listing__subject-name__shortcourse,
            &:hover .subject-listing__subject-name__language-level,
            &:hover .subject-listing__subject-name__level-details {
                background-color: $white;

                h3 {
                    border-bottom: 1px solid $grey-dark;
                }
            }
        }

        &-image {
            @extend .hidden-xs;
        }

        &-icon {
            @extend .hidden-xs;
            display: inline-block;
            background-color: $brand-secondary;
            text-align: center;
            vertical-align: middle;
            width: 100%;
            padding: 20px;

            i {
                @include font-size(7);
                z-index: 10;
                color: $grey;
            }
        }

        &-name {
            @include font-size(1.6);
            @include font-karla-bold;
            @extend .text-center;
            padding-left: 15px;
            padding-right: 35px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            text-align: left;
            background-color: $white;

            @media (min-width: $screen-sm-min) {
                height: 100px;
                padding: 15px 10px;
            }

            &__level-details {
                @extend .subject-listing__subject-name;
                height: auto;

                ul {
                    @include font-karla-regular();
                }

                @media (max-width: $screen-sm-max) {
                    ul {
                        display: none;
                    }
                }

                @media (min-width: $screen-lg-min) {
                    height: 280px;
                }
            }

            &__shortcourse {
                @extend .subject-listing__subject-name;
                height: auto;

                @media (min-width: $screen-md-min) {
                    height: 180px;
                }

                &--title {
                    h3 {
                        @include font-size(1.8);
                        margin-top: 0px;
                        margin-bottom: 0px;

                        @media (min-width: $screen-md-min) {
                            @include font-size(2);
                        }

                        @media (min-width: $screen-lg-min) {
                            @include font-size(2.4);
                        }
                    }
                }

                &--details {
                    @extend .hidden-xs;
                    @extend .hidden-sm;
                    @include font-size(1.4);
                    padding-top: 5px;
                    vertical-align: middle;
                }
            }
        }
    }
}

//Earlier version of shortcourse styles - may be used on SCdev platform theme in drupal
.shortcourse {
    @extend .subject-listing__subject-name;
    @include font-karla-regular();
    background-color: $white;

    @media (min-width: $screen-sm-min) {
        min-height: 290px;
    }

    h3 {
        @include font-size(1.8);
        margin-top: 10px;
        vertical-align: middle;

        @media (min-width: $screen-sm-min) {
            padding-top: 0;
        }

        @media (min-width: $screen-md-min) {
            @include font-size(2);
        }

        @media (min-width: $screen-lg-min) {
            @include font-size(2.4);
        }
    }

    &__listing {
        @extend .shortcourse;
        position: relative;
        padding-bottom: 30px;

        @media (min-width: $screen-sm-min) {
            background-color: transparent;
            padding-bottom: 30px;
        }

        @media (min-width: $screen-md-min) {
            margin-bottom: 60px;
        }
    }

    &__languages {
        background-color: $white;
        padding-left: 15px;
        padding-bottom: 8px;

        @media (max-width: $screen-xs-max) {
            &:after {
                @include after-arrow($grey-dark, 10px, 10px, 3px, rotate(45deg), 40px, 60%);
            }

            //Arguments - $color, $heightwidth, $border-width, $rotation, $right, $top
        }

        @media (min-width: $screen-sm-min) {
            background-color: transparent;
            padding-left: 0;
        }

        h3 {
            @include font-size(1.8);
            margin-top: 25px;
            vertical-align: middle;

            @media (min-width: $screen-sm-min) {
                padding-top: 0;
            }

            @media (min-width: $screen-md-min) {
                @include font-size(2);
            }

            @media (min-width: $screen-lg-min) {
                @include font-size(2.4);
            }
        }
    }

    &__level {
        position: relative;
        margin-top: 30px;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 45px;
            height: 350px;
        }

        @media (min-width: $screen-md-min) {
            height: 380px;
        }

        //  @media (min-width: $screen-lg-min) {
        //      height: 300px;
        //  }
        a {
            border-bottom: 2px solid $grey-dark;
            outline-offset: 2px;

            &:hover,
            &:focus {
                border-bottom: none;
            }
        }

        img {
            @extend .hidden-xs;
        }

        &--btn {
            @extend .hidden-xs;

            @media (min-width: $screen-sm-min) {
                position: absolute;
                bottom: -20px;
                right: 0;
            }
        }

        &--btn-list {
            @extend .hidden-xs;

            @media (min-width: $screen-sm-min) {
                position: absolute;
                bottom: -44px;
                right: 0;
            }

            @media (min-width: $screen-md-min) {
                bottom: -30px;
            }
        }
    }

    &__secondary-listing--button {
        @extend .hidden-xs;

        @media (min-width: $screen-sm-min) {
            //   bottom: -45px;
            //    position: absolute;
            right: 0;
            margin-top: 10px;
            float: right;
        }
    }

    &__level-details {
        @extend .hidden-xs;

        @media (min-width: $screen-sm-min) {
            position: relative;
            height: 120px;
            overflow: hidden;
            margin-top: 5px;

            ul {
                @include font-karla-regular();
                padding-left: 20px;
            }
        }

        @media (min-width: $screen-md-min) {
            height: 140px;
        }

        @media (min-width: $screen-lg-min) {
            height: 100px;
        }
    }

    &--summary {
        @extend .hidden-xs;
        @include font-karla-regular();
        @include font-size(1.6);

        a {
            border-bottom: 1px solid $grey-dark;
        }

        @media (min-width: $screen-sm-min) {
            //    position: absolute;
            top: 75px;
            padding-right: 15px;
        }
    }

    ul {
        padding-left: 10px;

        li {
            &:before {
                content: "";
                @include triangle-right($ul-bullet-color, 7px, 4px);
                display: inline-block;
                left: -10px;
                position: absolute;
                top: 4px;

                @media (min-width: $screen-sm-min) {
                    top: 5px;
                }

                @media (min-width: $screen-lg-min) {
                    top: 6px;
                }
            }
        }
    }
}