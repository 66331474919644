//
// Modals
// --------------------------------------------------

// Actual modal
.modal-content {
    border-radius: 0;
    box-shadow: none !important;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    border-bottom: 5px solid $modal-header-border-color;
    margin: 30px 30px 0 30px;
    min-height: 0;
    padding: 0 0 20px 0;
}
// Close icon
.modal-header .close {
    @include font-size(3.0);
    color: $modal-header-close-color;
    margin-top: -5px;
    opacity: 1;
}

// Title text within header
.modal-title {
    line-height: 100%;
    margin: 0;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    display: inline-block;
    margin: 10px 30px 30px 30px;
    padding: 0;

    // Remove bottom margin of last element
    > :last-child {
        margin-bottom: 0;
    }
}

// Footer (for actions)
.modal-footer {
    border-top: 0;
    margin: 0 30px 30px 30px;
    padding: 0;
}